import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import check from "../../assets/icons/check.png";
// import { plans } from "../../assets/static/plans";

import {
  Button,
  Card,
  CardBody,
  Input,
  Typography,
} from "@material-tailwind/react";
import logo from "../../assets/images/logo/logo_updated.png";
import logo2 from "../../assets/images/logo/logo_updated_2.png";
import { fetcher } from "../../utils/authFetch";
import { useDarkMode } from "../shared/moon/DarkModeContext";
import { usePlans } from "../../hooks/usePlans";

const CheckoutForm = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [planId, setPlanId] = useState(params.get("plan") || "");
  const [plan, setPlan] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { darkMode } = useDarkMode();

  const { data: plans, isLoading: planLoading } = usePlans({});

  useEffect(() => {
    if (user) {
      setName(user?.fields?.Name);
      setEmail(user?.fields?.Email);
    }

    if (params.get("plan") && !planLoading) {
      const selectedPlan = plans?.data?.find(
        (plan) => plan?.fields?.planId === params?.get("plan")
      );
      setPlan(selectedPlan);
      if (!selectedPlan) {
        toast.error("Invalid plan");
        navigate("/");
      }
      setPlanId(params.get("plan"));
    }
  }, [user, params, navigate, planLoading, plans]);

  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  // main function
  const createSubscription = async () => {
    try {
      setIsLoading(true);
      // create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement),
        billing_details: {
          name,
          email,
        },
      });

      if (paymentMethod?.error) {
        toast.error("Invalid card details");
        return;
      }

      // call the backend to create subscription
      const { data } = await fetcher.post(`/api/payment/subscription`, {
        paymentMethod: paymentMethod?.paymentMethod?.id,
        name,
        email,
        planId,
        planPrice: plan?.fields?.price,
      });

      const { status, clientSecret } = data?.data || {};

      if (status === "trialing") {
        toast.success(
          "Success! You are in the trial period. Payment will be processed after the trial period ends."
        );
        navigate("/payment?success=true");
      } else if (clientSecret) {
        const confirmPayment = await stripe?.confirmCardPayment(clientSecret);
        if (confirmPayment?.error) {
          toast.error(confirmPayment.error.message || "Something went wrong");
          navigate("/payment?success=false");
        } else {
          toast.success("Success! Check your email for the invoice.");
          navigate("/payment?success=true");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const createSubscription = async () => {
  //   try {
  //     setIsLoading(true);
  //     // create a payment method
  //     const paymentMethod = await stripe?.createPaymentMethod({
  //       type: "card",
  //       card: elements?.getElement(CardElement),
  //       billing_details: {
  //         name,
  //         email,
  //       },
  //     });

  //     if (paymentMethod?.error) {
  //       toast.error("Invalid card details");
  //       setIsLoading(false);
  //       return;
  //     }

  //     // call the backend to create subscription
  //     const { data } = await fetcher.post(`/api/payment/subscription`, {
  //       paymentMethod: paymentMethod?.paymentMethod?.id,
  //       name,
  //       email,
  //       planId,
  //     });

  //     const { status, clientSecret } = data?.data || {};

  //     if (status === "trialing") {
  //       toast.success(
  //         "Success! You are in the trial period. Payment will be processed after the trial period ends."
  //       );
  //       navigate("/payment?success=true");
  //     } else if (clientSecret) {
  //       const { error: authError } = await stripe?.handleCardAction(clientSecret);
  //       if (authError) {
  //         toast.error(authError.message || "Something went wrong during authentication");
  //         navigate("/payment?success=false");
  //       } else {
  //         toast.success("Success! Check your email for the invoice.");
  //         navigate("/payment?success=true");
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error creating subscription:', error);
  //     toast.error("Something went wrong while creating subscription");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  return (
    <div className="min-h-screen bg-secondary">
      <div className="mx-auto pt-8">
        <Link to="/" className="mx-auto">
          <img
            onClick={() => navigate("/")}
            className={`w-44 mx-auto`}
            src={darkMode ? logo : logo2}
            alt="logo"
          />
        </Link>
      </div>

      <div className="min-h-[calc(100vh-75px)] w-full max-w-screen-xl grid place-items-center mx-auto">
        <div className="grid md:grid-cols-2 items-center w-full gap-8 px-4 pt-20 pb-8 md:pt-12">
          {/* Left Side Card */}
          <div className="flex-1 w-full h-full">
            <Card className="h-full rounded-3xl drop-shadow-xl border border-blue-800 relative bg-gray-50 dark:bg-primary dark:text-white">
              <img
                className="absolute left-1/3 top-[40%] opacity-20"
                src="/logo_updated.png"
                alt=""
              />
              <CardBody className="p-8">
                <p className="text-6xl font-bold">€{plan?.fields?.price}</p>
                <div className="absolute top-8 right-0 z-10 bg-blue-900 rounded-l-full px-12 py-4 text-4xl text-white font-medium">
                  {plan?.fields?.name}
                </div>

                <ul className="flex flex-col gap-1 mt-8">
                  {plan?.fields?.features?.map((d, index) => {
                    return (
                      <div key={index}>
                        <li className="flex items-center gap-2 h-7">
                          <img className="w-5" src={check} alt="" />
                          <Typography className=" text-sm text-[#555a66] dark:text-white font-semibold group-hover:text-white delay-0">
                            {d}
                          </Typography>
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </CardBody>
            </Card>
          </div>

          {/* Right Side Checkout Form */}
          <div className="flex-1 w-full h-full">
            <Card className="rounded-3xl drop-shadow-xl border border-blue-800 bg-gray-50 dark:bg-primary dark:text-white">
              <CardBody>
                <p className="text-center my-4">
                  Sign up for a 7 days{" "}
                  <span className="font-bold text-green-600">free</span> trial.{" "}
                  <br /> Then you will be{" "}
                  <span className="text-red-600 font-bold">charged</span> for
                  the package.
                </p>
                <p className="text-sm text-gray-600 mb-2">Full Name</p>
                <Input
                  placeholder="Name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="!border !border-gray-300 bg-white text-gray-600 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500"
                  readOnly
                  labelProps={{
                    className: "hidden",
                  }}
                />
                <p className="text-sm text-gray-600 mb-2 mt-4">Email</p>

                <Input
                  placeholder="Email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="!border !border-gray-300 bg-white text-gray-600 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 "
                  labelProps={{
                    className: "hidden",
                  }}
                  readOnly
                />

                <CardElement
                  className="mt-8 bg-white py-2 px-3 rounded-lg border border-gray-300 shadow-gray-900/50"
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
                <Button
                  onClick={createSubscription}
                  disabled={!stripe || !elements || isLoading}
                  fullWidth
                  size="lg"
                  className="mt-8 bg-[#0d47a1] hover:bg-[#3171d1] tracking-widest"
                >
                  {isLoading ? "Loading..." : "Subscribe"}
                </Button>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
