import React from "react";
import { Link } from "react-router-dom";
import checklist from "../../assets/icons/auth/checklist.png";
import flash from "../../assets/icons/auth/flash.png";
import shield from "../../assets/icons/auth/shield.png";
import logo from "../../assets/images/logo/newLogo.png";

const AuthContainer = ({ children }) => {
  return (
    <div className="flex min-h-screen items-center justify-center relative overflow-hidden py-8 bg-white">
      {/* Card */}
      <div className="w-96 shadow-xl  lg:-mr-16 bg-[#c5cfe2] backdrop-blur-xl bg-opacity-40 rounded-3xl p-8 ">
        {children}
      </div>

      {/* Login Image */}
      <div
        className="w-[50vw] h-[85vh] hidden lg:flex items-center justify-center  bg-cover rounded-3xl "
        style={{
          backgroundImage:
            "linear-gradient(rgba(0,0,0,.8),rgba(0,0,0,.8)), url(https://images.unsplash.com/photo-1606925797300-0b35e9d1794e?auto=format&fit=crop&q=80&w=2081&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        }}
      >
        <div className="text-center text-white">
          <Link to="/">
            <img className="w-36 lg:w-44 mx-auto" src={logo} alt="logo" />
          </Link>

          <div className="flex items-center justify-evenly gap-8 mt-12">
            <div className="flex items-center gap-1">
              <img src={flash} alt="easy" className="w-5 h-5 block" />
              <p>Easy</p>
            </div>
            <div className="flex items-center gap-1">
              <img src={shield} alt="easy" className="w-5 h-5 block" />
              <p>Secure</p>
            </div>
            <div className="flex items-center gap-1">
              <img src={checklist} alt="easy" className="w-5 h-5 block" />
              <p>Reliable</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
