import { Button, Card, CardBody, Chip } from "@material-tailwind/react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import successImg from "../../assets/images/payment/1.gif";
import failedImg from "../../assets/images/payment/2.gif";

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isSuccess = params.get("success");

  return (
    <div className="bg-blue-50">
      <div className="h-screen max-w-screen-2xl mx-auto flex items-center justify-center w-full">
        {isSuccess === "true" ? (
          <div>
            <img className="w-60 mx-auto mb-6" src={logo} alt="" />
            <Chip
              variant="ghost"
              color="green"
              size="lg"
              value="Status : Success"
              className="max-w-min mx-auto"
              icon={
                <span className="mx-auto mt-2 block h-2 w-2 rounded-full bg-green-900 content-['']" />
              }
            />
            <div className="flex justify-center items-center h-full w-full mt-8">
              <Card className="rounded-3xl ">
                <CardBody>
                  <img className="w-60 mx-auto" src={successImg} alt="" />
                  <p className="text-center my-3 font-bold text-2xl">
                    Payment Successful
                  </p>
                  <p className="text-center mb-6">
                    We have received your membership request
                  </p>
                  <Link to="/">
                    {" "}
                    <Button
                      fullWidth
                      className="bg-primary hover:bg-secondary tracking-widest"
                    >
                      back to home
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </div>
          </div>
        ) : isSuccess === "false" ? (
          <div>
            <img className="w-60 mx-auto mb-6" src={logo} alt="" />
            <Chip
              variant="ghost"
              color="red"
              size="lg"
              value="Status : Fail"
              className="max-w-min mx-auto"
              icon={
                <span className="mx-auto mt-2 block h-2 w-2 rounded-full bg-red-900 content-['']" />
              }
            />
            <div className="flex justify-center items-center h-full w-full mt-8">
              <Card className="rounded-3xl ">
                <CardBody>
                  <img className="w-60 mx-auto" src={failedImg} alt="" />
                  <p className="text-center my-3 font-bold text-2xl">
                    Payment Failed
                  </p>
                  <p className="text-center">
                    Your transaction has failed due to some technical issues.
                  </p>
                  <Link
                    to="/pricing"
                    className="hover:underline text-primary hover:text-secondary font-semibold text-center"
                  >
                    <p className="mt-3 mb-6">Please try again</p>
                  </Link>
                  <Link to="/">
                    {" "}
                    <Button
                      fullWidth
                      className="bg-primary hover:bg-secondary tracking-widest"
                    >
                      back to home
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </div>
          </div>
        ) : (
          <Navigate to="/" />
        )}
      </div>
    </div>
  );
};

export default Payment;
