import { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { useAccuracyChart } from '../../hooks/useAccuracy';
import { Card, CardBody, Tab, Tabs, TabsHeader } from '@material-tailwind/react';
import Loading from '../shared/Loading/Loading';


const TABS = [
    {
        id: 1,
        label: "Yesterday",
        value: 2,
    },
    {
        id: 1,
        label: "7 Days",
        value: 7,
    },
    {
        id: 2,
        label: "15 Days",
        value: 15,
    },
    {
        id: 3,
        label: "30 Days",
        value: 30,
    },
    {
        id: 3,
        label: "90 Days",
        value: 90,
    },
];

const AccuracyChart = ({ selected, selectedTab, setSelectedTab }) => {

    const [responsiveWidth, setResponsiveWidth] = useState(200);
    const [responsiveHeight, setResponsiveHeight] = useState(120);

    const handleResize = () => {

        const width = Math.min(window.innerWidth * 0.4, 300);
        const height = width * 0.4;

        setResponsiveWidth(width);
        setResponsiveHeight(height);
    };


    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize);
    }, [window.innerWidth]);



    const { data, isLoading } = useAccuracyChart({ days: selectedTab });

    const cx = responsiveWidth / 2.15;
    const cy = responsiveHeight;
    const iR = responsiveHeight / 1.5;
    const oR = Math.min(responsiveWidth, responsiveHeight) / 1.3;
    const soccerValue = Number(data?.data?.soccerData?.winParc);
    const handicapValue = Number((data?.data?.handicapData?.handicapWin / data?.data?.soccerData?.total) * 100).toFixed(2);
    const soccerOddsValue = Number(data?.data?.soccerData?.soccerAverageOdds);
    const handicapOddsValue = Number(data?.data?.handicapData?.handicapAverageOdds);

    const soccerData = [
        { name: 'Lost', value: data?.data?.soccerData?.soccerLost, color: '#a80505' },
        { name: 'Win', value: data?.data?.soccerData?.soccerWin, color: '#069606' },
    ];

    const soccerOddsData = [
        { name: '', value: 1, color: '#a80505' },
        { name: 'Average Odds', value: Number(data?.data?.soccerData?.soccerAverageOdds), color: '#069606' },
    ];
    const handicapData = [
        { name: 'Lost', value: data?.data?.handicapData?.handicapLost, color: '#a80505' },
        { name: 'Win', value: data?.data?.handicapData?.handicapWin, color: '#069606' },
    ];
    const handicapOddsData = [
        { name: '', value: 1, color: '#a80505' },
        { name: 'Average Odds', value: Number(data?.data?.handicapData?.handicapAverageOdds), color: '#069606' },
    ];

    const RADIAN = Math.PI / 180;
    const needle = (value, data, cx, cy, iR, oR, color) => {
        let total = 105;
        if (data) {
            total = 0;
            data.forEach((v) => {
                total += v.value;
            });
        }

        const ang = 180.0 * (1 - value / total);
        const length = (iR + 2 * oR) / 3;
        const sin = Math.sin(-RADIAN * ang);
        const cos = Math.cos(-RADIAN * ang);
        const r = 5;
        const x0 = cx + 5;
        const y0 = cy + 5;
        const xba = x0 + r * sin;
        const yba = y0 - r * cos;
        const xbb = x0 - r * sin;
        const ybb = y0 + r * cos;
        const xp = x0 + length * cos;
        const yp = y0 + length * sin;

        return [
            <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
            <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
        ];
    };

    const handleTabClick = (value) => {
        setSelectedTab(value);
    };


    return (
        <div className='flex pt-2'>
            {
                selected === 'soccer'
                    ? <div className='flex flex-col md:flex-row gap-4 w-full'>
                        <div className='flex px-4 mx-1 py-6 shadow bg-lightSecondary dark:bg-secondary rounded-xl'>
                            <div>
                                <PieChart width={responsiveWidth} height={responsiveHeight}>
                                    <Tooltip />
                                    <Pie
                                        dataKey="value"
                                        startAngle={180}
                                        endAngle={0}
                                        data={soccerData}
                                        cx={cx}
                                        cy={cy}
                                        innerRadius={iR}
                                        outerRadius={oR}
                                        fill="#8884d8"
                                        stroke="none"
                                    >
                                        {soccerData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>

                                    {needle(soccerValue, null, cx, cy, iR, oR, '#d0d000')}
                                </PieChart>
                                <p className="dark:text-white text-xs md:text-base text-center mt-4"> Accuracy</p>
                            </div>
                            <div>
                                <PieChart width={responsiveWidth} height={responsiveHeight}>
                                    <Tooltip />
                                    <Pie
                                        dataKey="value"
                                        startAngle={180}
                                        endAngle={0}
                                        data={soccerOddsData}
                                        cx={cx}
                                        cy={cy}
                                        innerRadius={iR}
                                        outerRadius={oR}
                                        fill="#8884d8"
                                        stroke="none"
                                    >
                                        {soccerOddsData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>

                                    {needle(soccerOddsValue, soccerOddsData, cx, cy, iR, oR, '#d0d000')}
                                </PieChart>
                                <p className="dark:text-white text-xs md:text-base text-center mt-4">Average Odds</p>
                            </div>
                        </div>
                        <div className='bg-lightSecondary dark:bg-secondary shadow-lg md:w-max  h-full rounded-lg'>
                            <Tabs
                                value={selectedTab}
                                className=" md:w-max scroll-hidden text-black overflow-x-scroll flex flex-col"
                            >
                                <TabsHeader className=" flex flex-row md:flex-col items-center justify-between gap-4 dark:bg-secondary  bg-lightSecondary text-black">
                                    {TABS.map(({ label, value }) => (
                                        <Tab
                                            onClick={() => handleTabClick(value)}
                                            key={value}
                                            value={value}
                                            className="text-gray-600 text-xs md:text-base"
                                        >
                                            &nbsp;&nbsp;{label}&nbsp;&nbsp;
                                        </Tab>
                                    ))}
                                </TabsHeader>
                            </Tabs>
                        </div>
                        <Card className="w-full bg-lightSecondary dark:bg-secondary shadow-lg mr-1">
                            {isLoading ? <Loading className=' h-full' /> : <CardBody className="p-4 md:p-6 text-black dark:text-white">
                                <p className="mb-2 text-xs md:text-base">Total matches : {" "}
                                    <span className='text-green-700 dark:text-green-500 font-semibold'>
                                        {data?.data?.soccerData?.total}
                                    </span>
                                </p>
                                <p className="mb-2 text-xs md:text-base">Win : {" "}
                                    <span className='text-green-700 dark:text-green-500 font-semibold'>
                                        {data?.data?.soccerData?.soccerWin}
                                    </span>
                                </p>
                                <p className="mb-2 text-xs md:text-base">Lost : {" "}
                                    <span className='text-green-700 dark:text-green-500 font-semibold'>
                                        {data?.data?.soccerData?.soccerLost}
                                    </span>
                                </p>
                                <p className="mb-2 text-xs md:text-base">Average Odds: {" "}
                                    <span className='text-green-700 dark:text-green-500 font-semibold'>
                                        {data?.data?.soccerData?.soccerAverageOdds}
                                    </span>
                                </p>
                                <p className="mb-2 text-xs md:text-base"> Win : {" "}
                                    <span className='text-green-700 dark:text-green-500 font-semibold'>
                                        {
                                            data?.data?.soccerData?.winParc
                                        } %
                                    </span>
                                </p>
                            </CardBody>}
                        </Card>
                    </div>
                    : null

            }
            {
                selected === 'handicap'
                    ?
                    <div className='flex flex-col md:flex-row gap-4 w-full'>
                        <div className='flex px-4 mx-1 py-6 shadow bg-lightSecondary dark:bg-secondary rounded-xl'>
                            <div>
                                <PieChart width={responsiveWidth} height={responsiveHeight}>
                                    <Tooltip />
                                    <Pie
                                        dataKey="value"
                                        startAngle={180}
                                        endAngle={0}
                                        data={handicapData}
                                        cx={cx}
                                        cy={cy}
                                        innerRadius={iR}
                                        outerRadius={oR}
                                        fill="#8884d8"
                                        stroke="none"
                                    >
                                        {handicapData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>

                                    {needle(data?.data?.handicapData?.winParc, null, cx, cy, iR, oR, '#d0d000')}
                                </PieChart>
                                <p className="dark:text-white text-xs md:text-base text-center mt-4"> Accuracy</p>
                            </div>
                            <div>
                                <PieChart width={responsiveWidth} height={responsiveHeight}>
                                    <Tooltip />
                                    <Pie
                                        dataKey="value"
                                        startAngle={180}
                                        endAngle={0}
                                        data={handicapOddsData}
                                        cx={cx}
                                        cy={cy}
                                        innerRadius={iR}
                                        outerRadius={oR}
                                        fill="#8884d8"
                                        stroke="none"
                                    >
                                        {handicapOddsData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>

                                    {needle(handicapOddsValue, handicapOddsData, cx, cy, iR, oR, '#d0d000')}
                                </PieChart>
                                <p className="dark:text-white text-xs md:text-base text-center mt-4">Average Odds</p>
                            </div>
                        </div>
                        <div className='bg-lightSecondary dark:bg-secondary shadow-lg md:w-max  h-full rounded-lg'>
                            <Tabs
                                value={selectedTab}
                                className=" md:w-max scroll-hidden text-black overflow-x-scroll flex flex-col"
                            >
                                <TabsHeader className=" flex flex-row md:flex-col items-center justify-between gap-4 dark:bg-secondary  bg-lightSecondary text-black">
                                    {TABS.map(({ label, value }) => (
                                        <Tab
                                            onClick={() => handleTabClick(value)}
                                            key={value}
                                            value={value}
                                            className="text-gray-600 text-xs md:text-base"
                                        >
                                            &nbsp;&nbsp;{label}&nbsp;&nbsp;
                                        </Tab>
                                    ))}
                                </TabsHeader>
                            </Tabs>
                        </div>
                        <Card className="w-full bg-lightSecondary dark:bg-secondary shadow-lg mr-1">
                            {isLoading ? <Loading className=' h-full' /> : <CardBody className="p-4 md:p-6 text-black dark:text-white">
                                <p className="mb-2 text-xs md:text-base">Total predictions : {" "}
                                    <span className='text-green-700 dark:text-green-500 font-semibold'>
                                        {data?.data?.handicapData?.total}
                                    </span>
                                </p>
                                <p className="mb-2 text-xs md:text-base">Win : {" "}
                                    <span className='text-green-700 dark:text-green-500 font-semibold'>
                                        {data?.data?.handicapData?.handicapWin}
                                    </span>
                                </p>
                                <p className="mb-2 text-xs md:text-base">Lost : {" "}
                                    <span className='text-green-700 dark:text-green-500 font-semibold'>
                                        {data?.data?.handicapData?.handicapLost}
                                    </span>
                                </p>
                                <p className="mb-2 text-xs md:text-base">Average Odds: {" "}
                                    <span className='text-green-700 dark:text-green-500 font-semibold'>
                                        {data?.data?.handicapData?.handicapAverageOdds}
                                    </span>
                                </p>
                                <p className="mb-2 text-xs md:text-base"> Win :{" "}
                                    <span className='text-green-700 dark:text-green-500 font-semibold'>
                                        {
                                           data?.data?.handicapData?.winParc
                                        } %
                                    </span>
                                </p>
                            </CardBody>}
                        </Card>
                    </div>
                    : null
            }
        </div>
    )
}

export default AccuracyChart
