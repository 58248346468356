import { Button, Dialog, DialogBody } from "@material-tailwind/react";
import Cookies from "js-cookie";
import React from "react";
import cardImg from "../../assets/icons/card.png";

const PaymentRequiredModal = ({ open, handleOpen: handler }) => {
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  const handlePayment = () => {
    window.open(user?.fields?.InvoiceURL, "_blank");
    handler();
  };
  return (
    <Dialog
      open={open}
      handler={handler}
      className="bg-lightPrimary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="sm"
    >
      <DialogBody className="text-black dark:text-white p-6">
        <img src={cardImg} alt="upgrade" className="w-56 mx-auto" />

        <h2 className="text-center text-3xl font-bold my-6">
          Payment Required!
        </h2>

        <p className="text-primary dark:text-lightPrimary text-lg text-center">
          Please confirm your payment to get access your subscription features.
        </p>

        <Button
          fullWidth
          color="indigo"
          ripple
          className="w-full mt-12 bg-blue-900 tracking-wider outline-none"
          onClick={handlePayment}
        >
          Confirm Payment
        </Button>
      </DialogBody>
    </Dialog>
  );
};

export default PaymentRequiredModal;
