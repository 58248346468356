import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";
import axios from "axios";

export const useAccuracy = ({ game, search }) => {
  return useQuery({
    queryKey: ["accuracy", game, search],
    queryFn: async () => {
      const response = await fetcher.get(
        `/api/search/game?search=${search}&game=${game}`
      );

      const sortedData = response?.data?.data?.sort((a, b) => {
        const dateA = new Date(`${a.fields.Date} ${a.fields.Time}`);
        const dateB = new Date(`${b.fields.Date} ${b.fields.Time}`);
        return dateB - dateA;
      });
      return sortedData;
    },

    enabled: !!game,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

export const useAccuracyChart = ({days}) => {
  return useQuery({
    queryKey: ["accuracyChart", days],
    queryFn: async () => {
      const response = await fetcher.get(
        `/api/accuracy?days=${days}`
      );

      return response.data;
    },

    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};
