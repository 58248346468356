import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  IconButton,
  Input,
  Tab,
  Tabs,
  TabsHeader,
} from "@material-tailwind/react";
import * as React from "react";
import { useState } from "react";
import { ScrollRestoration, useLocation, useNavigate } from "react-router-dom";
import GameAccuracyTable from "../components/ourAccuracy/GameAccuracyTable";
import HandicapAccuracyTable from "../components/ourAccuracy/HandicapAccuracyTable";
import { useAccuracy } from "../hooks/useAccuracy";
import { useAllHandicapData } from "../hooks/useHandicap";
import formatDate from "../utils/formatter";
import { useAllComboData } from "../hooks/useCombo";
import ComboAccuracyTable from "../components/ourAccuracy/ComboAccuracyTable";
import useDebounce from "../hooks/useDebounce";
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import AccuracyChart from "../components/ourAccuracy/AccuracyChart";
const TABS = [
  {
    id: 1,
    label: "Soccer",
    value: "soccer",
  },
  {
    id: 2,
    label: "Handicap",
    value: "handicap",
  },
  {
    id: 3,
    label: "Combo",
    value: "combo",
  },
];
const TABS2 = [
  {
    id: 4,
    label: "CS:GO",
    value: "csgo",
  },
  {
    id: 5,
    label: "Valorant",
    value: "valorant",
  },
];

const OurAccuracy = () => {
  // const [data, setData] = useState([]);
  const [selected, setSelected] = useState("soccer");
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [selectedTab, setSelectedTab] = useState(7)


  const [dateFilter, setDateFilter] = useState(formatDate(new Date()));

  const { data, isLoading } = useAccuracy({
    game:
      selected !== "handicap" && selected !== "combo" ? selected : undefined,
    search: debouncedSearch,
    // search
  });
  const { data: handicapData, isLoading: handicapLoading } = useAllHandicapData(
    {
      date: dateFilter,
      skip: selected !== "handicap",
      finished: true,
    }
  );

  const { data: comboData, isLoading: comboLoading } = useAllComboData({
    date: dateFilter,
    skip: selected !== "combo",
    finished: true,
  });

  const handleTabClick = (value) => {
    setSelected(value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    if (selected === "handicap") {
      setTotalPages(Math.ceil(handicapData?.data?.length / limit));
    } else if (selected === "combo") {
      setTotalPages(Math.ceil(comboData?.data?.length / limit));
    } else {
      setTotalPages(Math.ceil(data?.length / limit));
    }
  }, [selected, comboData, handicapData, data, limit]);

  const handleNext = () => {
    setPage(page + 1);
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setDateFilter(newDate);
    params.set("date", newDate);
    const newUrl = `${location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };



  return (
    <div >
      <Card className="w-full dark:bg-primary bg-lightPrimary h-[calc(100vh-162px)]">
        <CardBody className="overflow-scroll lg:overflow-y-scroll scroll-hidden p-0">
          <AccuracyChart selected={selected} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row mt-4 px-2 pb-2">
            <Tabs
              value={selected}
              className="w-full md:w-max scroll-hidden text-black overflow-x-scroll flex flex-col lg:flex-row lg:gap-36 gap-4"
            >
              <TabsHeader className="dark:bg-secondary overflow-x-scroll scroll-hidden bg-lightSecondary text-black w-full">
                {TABS.map(({ label, value }) => (
                  <Tab
                    onClick={() => handleTabClick(value)}
                    key={value}
                    value={value}
                    className="text-gray-600 "
                  >
                    &nbsp;&nbsp;{label}&nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
              {/* ------------------------------- FOR SPORTS ------------------------------------ */}
              <TabsHeader className="dark:bg-secondary   bg-lightSecondary text-black w-full">
                {TABS2.map(({ label, value }) => (
                  <Tab
                    onClick={() => handleTabClick(value)}
                    key={value}
                    value={value}
                    className="text-gray-600 "
                  >
                    &nbsp;&nbsp;{label}&nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>

            {selected === "handicap" || selected === "combo" ? (
              <div>
                <input
                  type="date"
                  id="date"
                  className={`bg-lightSecondary w-[288px] text-black outline-none  dark:text-white dark:datepicker dark:bg-secondary px-2.5 text-[10px] py-[3px] rounded-lg h-10`}
                  onChange={handleDateChange}
                  value={dateFilter}
                />
              </div>
            ) : (
              <div className="w-full md:w-72">
                <Input
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  placeholder="Search..."
                  labelProps={{ className: "hidden" }}
                  className="bg-white dark:bg-secondary dark:text-white text-black outline-none border-none"
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                />
              </div>
            )}
          </div>
          {/* </CardHeader> */}

          {selected === "handicap" ? (
            <HandicapAccuracyTable
              isLoading={handicapLoading}
              data={handicapData?.data}
              page={page}
              limit={limit}
              total={handicapData?.data?.length}
              selected={selected}
            />
          ) : selected === "combo" ? (
            <ComboAccuracyTable
              isLoading={comboLoading}
              data={comboData?.data}
              total={data?.data?.length}
              page={page}
              limit={limit}
            />
          ) : (
            <GameAccuracyTable
              isLoading={isLoading}
              data={data}
              page={page}
              limit={limit}
              total={data?.length}
              selected={selected}
            />
          )}
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Button
            onClick={handlePrev}
            variant="outlined"
            size="sm"
            disabled={page === 1}
            className="text-black dark:text-white dark:bg-secondary bg-lightSecondary"
          >
            Newer
          </Button>
          <div className="md:flex items-center gap-2 hidden ">
            {Array.from({ length: totalPages }).map((_, index) => (
              <IconButton
                key={index + 1}
                variant="text"
                size="sm"
                onClick={() => setPage(index + 1)}
                className={
                  index + 1 === page
                    ? "bg-primaryLighter text-black"
                    : "bg-lightSecondary text-black dark:text-white dark:bg-secondary"
                }
              >
                {index + 1}
              </IconButton>
            ))}
          </div>
          <Button
            onClick={handleNext}
            variant="outlined"
            size="sm"
            disabled={page === totalPages}
            className="text-black dark:text-white dark:bg-secondary bg-lightSecondary"
          >
            Previous
          </Button>
        </CardFooter>
      </Card>
      <ScrollRestoration />
    </div>
  );
};

export default OurAccuracy;
