import React from "react";

const CsgoTopComponent = () => {
  return (
    <div className="hidden md:block">
      <div
        className="flex items-center rounded-xl h-40 md:h-56 py-16 px-5 relative bg-no-repeat bg-cover mb-2"
        style={{
          backgroundImage:
            "linear-gradient(rgba(0,0,0,.6), rgba(0,0,0,.6)), url('https://www.gamegune.org/user/themes/gamegune/img/banner23-csgo.jpg')",
        }}
      >
      </div>
    </div>
  );
};

export default CsgoTopComponent;
