import { Card, CardBody } from "@material-tailwind/react";
import React from "react";
import { FaFighterJet } from "react-icons/fa";
import { IoFootball } from "react-icons/io5";
import { MdOutlineSportsCricket } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

const navLinks = [
  {
    name: "Soccer",
    icon: <IoFootball />,
    path: "/football",
    path2: "/",
  },
  // ------------------------------- FOR SPORTS ------------------------------------
  {
    name: "CS:GO",
    icon: <MdOutlineSportsCricket />,
    path: "/csgo",
  },
  {
    name: "Valorant",
    icon: <FaFighterJet />,
    path: "/valorant",
  },
];

const SubHeader = () => {
  const { pathname } = useLocation();
  const isCurrentPath = (path) => (pathname === path ? true : false);

  return (
    <div>
      <Card className="bg-lightPrimary dark:bg-primary">
        <CardBody className="p-4">
          <ul className="flex gap-2 items-center lg:gap-6">
            {navLinks.map((link, index) => (
              <p
                key={index}
                as="li"
                variant="small"
                className={`cursor-pointer p-1 font-normal text-md hover:text-secondary ${
                  isCurrentPath(link?.path) || isCurrentPath(link?.path2)
                    ? "text-secondary dark:text-primaryLighter"
                    : "text-gray-900 dark:text-gray-200"
                }`}
              >
                <Link to={link?.path} className="flex items-center gap-1">
                  <IoFootball />
                  {link?.name}
                </Link>
              </p>
            ))}
          </ul>
        </CardBody>
      </Card>
    </div>
  );
};

export default SubHeader;
