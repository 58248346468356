import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";

const FootballPrediction = ({ open, handleOpen, fixture }) => {
  const [possibleOutcome, setPossibleOutcome] = useState(0.0);
  const [wallet, setWallet] = useState();
  const [investmentPercent, setInvestmentPercent] = useState(15);
  const [calcInvestment, setcalCInvestment] = useState();
  const [investment, setInvestment] = useState();

  const fixtureFields = fixture?.fields;

  useEffect(() => {
    setcalCInvestment(wallet * investmentPercent / 100);
    if (investment) {
      setPossibleOutcome(investment * fixtureFields?.PredictedOdds.toFixed(2));
    } else {
      setPossibleOutcome(calcInvestment * fixtureFields?.PredictedOdds.toFixed(2));
    }
  }, [wallet, investmentPercent, investment, fixtureFields?.PredictedOdds, calcInvestment]);

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className="bg-lightSecondary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="md"
    >
      <DialogHeader className="text-black dark:text-white text-center">
        Our Prediction
      </DialogHeader>
      <DialogBody className="p-2 md:p-4">
        {/* Top */}
        <div className="lg:px-8 px-2 py-6 shadow bg-lightPrimary dark:bg-secondary rounded-xl">
          <div className="flex items-center justify-between gap-4 lg:gap-10">
            <div className="flex-1 flex flex-col items-center gap-2 lg:gap-5 justify-start">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={fixtureFields?.T1Logo}
                alt={`${fixtureFields?.HomeTeam} logo`}
              />
              <p className="font-bold text-xs md:text-lg text-center">
                {fixtureFields?.HomeTeam}
              </p>
            </div>
            <div className="flex flex-col items-center justify-center text-black dark:text-white">
              <h4 className="text-xl lg:text-4xl mb-2 lg:mb-4">
                {fixtureFields?.Time}
              </h4>

              <div className="flex items-center justify-center gap-1 lg:gap-2 mb-2">
                <p className="bg-lightSecondary dark:bg-primary lg:px-3 px-2 text-xs lg:text-md py-[2px] rounded-full">
                  {fixtureFields?.HomeOdds}
                </p>
                <p className="bg-lightSecondary dark:bg-primary lg:px-3 px-2 text-xs lg:text-md py-[2px] rounded-full">
                  {fixtureFields?.DrawOdds}
                </p>
                <p className="bg-lightSecondary dark:bg-primary lg:px-3 px-2 text-xs lg:text-md py-[2px] rounded-full">
                  {fixtureFields?.AwayOdds}
                </p>
              </div>

              <p className="text-sm md:text-md font-medium">
                {format(new Date(fixtureFields?.Date), "PP")}
              </p>
            </div>
            <div className="flex-1 flex flex-col items-center gap-2 lg:gap-5 justify-end">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={fixtureFields?.T2Logo}
                alt={`${fixtureFields?.AwayTeam} logo`}
              />
              <p className="font-bold text-xs md:text-lg  text-center">
                {fixtureFields?.AwayTeam}
              </p>
            </div>
          </div>
        </div>

        {/* 2nd Card */}
        <div className="card mt-4 px-8 py-6 shadow bg-lightPrimary dark:bg-secondary rounded-xl">
          <h4 className="text-sm lg:text-xl text-center text-black dark:text-white">
            We Predict:{" "}
            <strong className="font-bold tracking-widest text-green-800 dark:text-green-500">
              {fixtureFields?.Prediction}
            </strong>
          </h4>
        </div>

        <div className="card mt-4 px-8 py-6 shadow bg-white dark:bg-secondary rounded-xl">
          <h4 className="text-lg lg:text-md text-center text-black dark:text-white">
            We recommend you to invest{" "}
            <span className="text-2xl font-bold dark:text-yellow-700 text-yellow-900">{" "}{fixtureFields?.Investment_percentage
              ? `${fixtureFields?.Investment_percentage}%`
              : "15%"}{" "} </span>
            of your wallet
          </h4>
        </div>

        {/* 3rd Card */}
        <div className="card mt-4 px-4 py-6 shadow bg-lightPrimary dark:bg-secondary rounded-xl">
          <div className="flex items-center justify-between">
            <p className="dark:text-white text-black text-lg">
              {fixtureFields?.Prediction}
            </p>
            <p className="dark:text-white text-black text-lg">
              {fixtureFields?.PredictedOdds}
            </p>
          </div>
          <div className="flex flex-row items-start md:items-center justify-between mt-3">
            <div className="flex flex-col lg:flex-row gap-2 items-center">
              <p className="dark:text-gray-400 text-gray-900 text-xs md:text-sm">
                Your wallet:
              </p>
              <div>
                <input
                  type="text"
                  className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
                  onChange={(e) => {
                    setWallet(e?.target?.value);
                  }}
                  value={wallet}
                />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-2 items-center">
              <p className="dark:text-gray-400 text-gray-900 text-xs md:text-sm">
                Recommended {investmentPercent}%:
              </p>
              <div>
                <input
                  type="text"
                  className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
                  onChange={(e) => {
                    setInvestmentPercent(e?.target?.value);
                  }}
                  value={calcInvestment ? calcInvestment : null}
                  readOnly
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-2 items-center">
              <p className="dark:text-gray-400 text-gray-900 text-xs md:text-sm">
                Investment:
              </p>
              <div>
                <input
                  type="text"
                  className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
                  placeholder={calcInvestment ? calcInvestment : null}
                  onChange={(e) => {
                    setInvestment(e?.target?.value);
                  }}
                  value={investment}
                />
              </div>
            </div>
          </div>
          <div className="w-full bg-gray-500 h-[1px] my-6"></div>

          <div className="flex items-end justify-end text-right">
            {/* <span className="bg-green-800 text-white rounded-md px-2">
              Get RETURN
            </span> */}

            <div className="flex flex-col items-end">
              <p className="dark:text-white text-black">Possible Outcome: </p>
              <p className="dark:text-white text-black text-lg">
                {possibleOutcome ? Number(possibleOutcome).toFixed(2) : 0}
              </p>
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          className="dark:bg-white dark:text-black bg-black text-white"
          onClick={handleOpen}
        >
          <span>Close</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default FootballPrediction;
