import { Card, CardBody } from "@material-tailwind/react";
import React, { useMemo, useState } from "react";
import { FaRegStar, FaRegWindowMaximize, FaStar } from "react-icons/fa";
import { currentDate, currentTime } from "../../utils/timeZone";
import { csgoDate } from "../../utils/formatter";
import csgoLogo from "../../assets/images/csgo/csgo_placeholder.png";

const MultiChainPredictionCard = ({
  d,
  index,
  handlePredictionClick,
  handleDetailsClick,
  filter,
}) => {
  const [isPredictionCorrect, setIsPredictionCorrect] = useState(false);
  const [resultExist, setResultExist] = useState(false);

  useMemo(() => {
    let newIsPredictionCorrect = true; // Initialize as true
    let newResultExist = false;
  
    d?.forEach(({ data: gameData }) => {
      if (
        gameData?.Results === "FALSE" ||
        gameData[`True/false`] === "false" ||
        gameData?.TrueFalse === "False"
      ) {
        newIsPredictionCorrect = false; // Set to false if any condition is met
        return; // Exit the loop early
      }
  
      newResultExist =
        newResultExist ||
        gameData?.Results ||
        gameData?.Result ||
        gameData?.MatchResults;
    });
  
    setIsPredictionCorrect(newIsPredictionCorrect);
    setResultExist(newResultExist);
  }, [d]);
  

  return (
    <>
      <Card className="dark:bg-secondary bg-lightSecondary">
        <CardBody className="px-1 md:px-4 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1 md:gap-8">
              {resultExist ? (
                <FaStar
                  color={isPredictionCorrect ? "green" : "red"}
                  className="text-sm lg:text-md"
                />
              ) : (
                <FaRegStar className="text-black dark:text-white text-sm lg:text-md" />
              )}
              <p className="font-semibold dark:text-white text-black text-[8px] md:text-sm">
                {d[0]?.data?.Date}
              </p>
              <p className="dark:bg-white bg-black text-white dark:text-black px-1 lg:px-3 rounded-full text-[8px] md:text-sm">
                Combo {index + 1}
              </p>
            </div>
            <div className="flex items-center gap-0 md:gap-8">
              {d?.map((game, i) => {
                return (
                  <div className="flex flex-col items-center gap-1" key={i}>
                    <img
                      className="md:w-8 w-6 h-6 md:h-8 rounded-full overflow-hidden"
                      src={
                        game?.name === "soccer"
                          ? "https://www.rustonsportscomplex.com/wp-content/uploads/2021/05/2.png"
                          : game?.name === "csgo"
                          ? csgoLogo
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReCA33wfFbf64cdgrTB0sU3R_f0XsL87HxvQ&usqp=CAU"
                      }
                      alt={game?.name}
                    />
                    <GameStatus name={game?.name} data={game?.data} />
                  </div>
                );
              })}
            </div>
            <div className="flex flex-row items-center lg:gap-4 gap-2">
              <button
                className={`dark:bg-primary bg-lightPrimary md:text-sm text-[8px] px-1 py-0.5 lg:px-3 dark:text-white text-black rounded `}
                onClick={() => handlePredictionClick(d)}
              >
                Prediction
              </button>

              <button
                title="Show Details"
                onClick={() => handleDetailsClick(d)}
              >
                <FaRegWindowMaximize className="text-black dark:text-white lg:text-lg  text-xs" />
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default MultiChainPredictionCard;

const GameStatus = ({ name, data }) => {
  const isSoccer = name === "soccer";
  const isCSGO = name === "csgo";

  const date = isCSGO ? csgoDate(data?.Date) : data?.Date;

  const status =
    data?.Time <= currentTime &&
    date === currentDate &&
    !data?.MatchResults &&
    !data?.Results &&
    !data?.Result
      ? "Live"
      : (date > currentDate ||
          (data?.Time > currentTime && date === currentDate)) &&
        (isSoccer
          ? !data?.MatchResults
          : isCSGO
          ? !data?.Reults
          : !data?.Result)
      ? data?.Time
      : "Finished";

  return (
    <>
      <p
        className={`text-[8px] text-center font-semibold px-2 py-[2px] lg:w-20 lg:text-[16px] ${
          status === "Live"
            ? "text-red-400"
            : status === "Finished"
            ? "text-orange-900"
            : "dark:text-white text-black"
        }`}
      >
        {status}
      </p>
    </>
  );
};
