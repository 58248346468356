import React from "react";
import google from "../../assets/icons/auth/google.png";
import config from "../../utils/config";

const SocialLogin = () => {
  const handleGoogleLogin = () => {
    window.open(`${config.server_url}/api/auth/google`, "_self");
  };
  return (
    <div className="mt-4">
      <button
        className="flex items-center justify-center py-2 px-4 gap-2 w-full rounded-lg border border-1 border-gray-900 hover:border-white hover:shadow-sm hover:shadow-white hover:text-blue-900 transition-all hover:bg-white"
        onClick={handleGoogleLogin}
      >
        <img src={google} alt="google" className="w-6 h-6" />
        <span>Continue with Google</span>
      </button>
    </div>
  );
};

export default SocialLogin;
