import {
  Button,
  Card,
  CardBody,
  Chip,
  Input,
  Option,
  Select,
} from "@material-tailwind/react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { ScrollRestoration } from "react-router-dom";
import config from "../../utils/config";

const AdminValorant = () => {
  const initialFormData = {
    Team1: "",
    Team2: "",
    Date: "",
    Time: "",
    Event: "",
    Prediction: 0,
    PercentPrediction: "15%",
    Odds: 0,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "number" ? parseInt(value) : value,
    });
  };

  const handleResultSelectChange = (value) => {
    setFormData({
      ...formData,
      Prediction: value,
    });
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    const inputData = {
      ...formData,
      BestOdds1: formData.Prediction === 1 ? formData.Odds : undefined,
      BestOdds2: formData.Prediction === 2 ? formData.Odds : undefined,
      Odds: undefined,
    };
    try {
      setIsSubmitted(true);
      // setFormData(initialFormData);

      // modified here
      fetch(`${config.server_url}/api/valorant`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          fields: inputData,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          if (data.success) {
            toast.success("Game is added...");
          }
        });
    } catch (error) {
      setIsSubmitted(false);
      toast.error("Not submitted", error);
    }
  };

  const clearData = () => {
    setFormData(initialFormData);
  };

  return (
    <div className="">
      <Chip
        variant="ghost"
        color="blue"
        size="lg"
        value="valorant Matches"
        className="max-w-min tracking-widest"
        icon={
          <span className="mx-auto mt-2 block h-2 w-2 rounded-full bg-blue-900 content-['']" />
        }
      />
      <div className="my-6">
        <Card className="bg-[#edf0f4]">
          <form onSubmit={formSubmitHandler}>
            <CardBody>
              <p>Create Match</p>
              <p className="border-b border-gray-400 my-4"></p>
              <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-8 ">
                <div>
                  <p className="text-black text-sm mb-2">
                    Team 1 <span className="text-red-500">*</span>
                  </p>
                  <Input
                    type="text"
                    variant="static"
                    placeholder="Enter Team 1"
                    name="Team1"
                    value={formData.Team1}
                    onChange={handleInputChange}
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">
                    Team 2 <span className="text-red-500">*</span>
                  </p>
                  <Input
                    type="text"
                    variant="static"
                    name="Team2"
                    placeholder="Enter Team 2"
                    value={formData.Team2}
                    onChange={handleInputChange}
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">
                    Date <span className="text-red-500">*</span>
                  </p>
                  <Input
                    type="date"
                    variant="static"
                    name="Date"
                    value={formData.Date}
                    onChange={handleInputChange}
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">
                    Time <span className="text-red-500">*</span>
                  </p>
                  <Input
                    type="time"
                    variant="static"
                    name="Time"
                    value={formData.Time}
                    onChange={handleInputChange}
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">Event</p>
                  <Input
                    type="text"
                    placeholder="Enter Event"
                    name="Event"
                    value={formData.Event}
                    onChange={handleInputChange}
                    variant="static"
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">
                    We Predict <span className="text-red-500">*</span>
                  </p>
                  <Select
                    label="Select result"
                    animate={{
                      mount: { y: 0 },
                      unmount: { y: 25 },
                    }}
                    name="Prediction"
                    value={formData.Prediction}
                    onChange={handleResultSelectChange}
                    className="font bg-white shadow-2xl  rounded-md "
                  >
                    <Option value={1} className="bg-tansparent">
                      Team 1
                    </Option>
                    <Option value={2} className="bg-tansparent">
                      Team 2
                    </Option>
                  </Select>
                </div>
                <div>
                  <p className="text-black text-sm mb-2">% Prediction</p>
                  <Input
                    type="text"
                    readOnly
                    placeholder="Enter % Prediction"
                    name="PercentPrediction"
                    value={formData.PercentPrediction}
                    onChange={handleInputChange}
                    variant="static"
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">
                    Odds <span className="text-red-500">*</span>
                  </p>
                  <Input
                    type="number"
                    placeholder="Enter Odds"
                    name="Odds"
                    value={formData.Odds}
                    onChange={handleInputChange}
                    variant="static"
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
              </div>
              <p className="border-t border-gray-400 my-8"></p>
              <Button type="submit" className="capitalize bg-green-500">
                Create
              </Button>
              <Button
                onClick={clearData}
                className="capitalize bg-red-500 ml-4"
              >
                Cancel
              </Button>
            </CardBody>
          </form>
        </Card>
      </div>
      <ScrollRestoration />
    </div>
  );
};

export default AdminValorant;
