import { Card } from "@material-tailwind/react";
import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import menuOptions from "../../static/menuOptions";
import { FiUser } from "react-icons/fi";

const LeftBar = () => {
  const { pathname } = useLocation();
  const isCurrentPath = (path) => pathname === path;

  return (
    <div className="relative">
      <Card className="bg-lightPrimary dark:bg-primary h-[calc(100vh-90px)]">
        <div className="py-4 px-2 flex flex-col justify-between h-full">
          <div>
            {menuOptions.map((data) => {
              return (
                <div key={data.id} className="my-2">
                  <Link to={data.link}>
                    <div
                      className={`flex items-center justify-start gap-2 p-2 rounded-lg text-black dark:text-white hover:bg-lightSecondary dark:hover:bg-secondary active:rounded-2xl 
                        ${isCurrentPath(data?.link)
                          ? "dark:bg-secondary bg-lightSecondary"
                          : ""
                        }`}
                    >
                      <span className="w-4">{data?.icon}</span>
                      <p className={`text-sm`}>{data.name}</p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>


          <div className="text-[14px]">
            <Link to="/account">
              <div className="flex items-center justify-between gap-2 text-black dark:text-white hover:bg-lightSecondary dark:hover:bg-secondary hover:rounded-lg active:rounded-2xl p-2 cursor-pointer">
                <p className="w-full flex items-center gap-2">
                  <FiUser className="text-md" /> Account
                </p>
                <FaAngleRight />
              </div>
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LeftBar;
