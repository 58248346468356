import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";

export const useROI = ({
  startDate,
  endDate,
  initialBalance,
  percent,
  // isSkip,
  filter,
  game
}) => {
  return useQuery({
    queryKey: ["roi", startDate, endDate, initialBalance, percent, game],
    queryFn: async () => {
      const response = await fetcher.get("/api/roi", {
        params: {
          startDate,
          endDate,
          initialBalance,
          percent,
          filter,
          game
        },
      });
      return response.data?.data;
    },
    // enabled: !isSkip,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
