import { Card, CardBody } from "@material-tailwind/react";
import React, { useState } from "react";
import BackButton from "../shared/BackButton";
import MultiChainDetails from "./MultiChainDetails";
import { useStatistics } from "../../hooks/useStatistics";

const MultiChainMiddle = () => {
  const data = JSON.parse(localStorage.getItem("combo"));
  const [fixture, setFixture] = useState(data[0]?.data);
  const [selected, setSelected] = useState(data[0]?.data);
  // const dataArray = [
  //   data?.fields?.GameData1,
  //   data?.fields?.GameData2,
  //   data?.fields?.GameData3,
  // ].filter(Boolean);

  const handleDetails = (d) => {
    setFixture(d);
    setSelected(d);
  };

  const { data: statistics, isLoading } = useStatistics({
    matchId: fixture?.MatchID,
  });

  return (
    <div>
      <Card className="dark:bg-primary bg-lightPrimary h-[calc(100vh-162px)]  ">
        <CardBody className="overflow-y-scroll scroll-hidden text-justify p-3 text-gray-200">
          <div className="flex justify-start mb-6">
            <BackButton />
          </div>
          <div className={`grid lg:grid-cols-${data?.length} gap-4`}>
            {data?.map(({ name, data: gameData }, index) => {
              return (
                <Card
                  key={index}
                  className={`dark:bg-secondary bg-lightSecondary cursor-pointer ${
                    gameData?.MatchID === selected?.MatchID
                      ? "border border-white"
                      : ""
                  }`}
                  onClick={() => handleDetails(gameData)}
                >
                  <CardBody>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <p className="text-sm w-20 h-10 text-left">
                          {gameData?.HomeTeam || gameData?.Team1}
                        </p>
                        <img
                          className="w-6 h-6 object-cover"
                          src={gameData?.T1Logo}
                          alt=""
                        />
                      </div>
                      <p className="text-red-500 font-bold text-sm">V/S</p>
                      <div className="flex items-center gap-2">
                        <img
                          className="w-6 h-6 object-cover"
                          src={gameData?.T2Logo}
                          alt=""
                        />
                        <p className="text-sm w-20 h-10 text-right">
                          {gameData?.AwayTeam || gameData?.Team2}
                        </p>
                      </div>
                    </div>
                    <p className="text-sm text-center mt-2 dark:text-white font-bold text-black">
                      {gameData?.LeagueName || gameData?.Event}
                    </p>
                  </CardBody>
                </Card>
              );
            })}
          </div>
          <div className={`${fixture ? "block" : "hidden"}`}>
            <MultiChainDetails
              fixture={fixture}
              statistics={statistics?.response}
              isLoading={isLoading}
              results={statistics?.results}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default MultiChainMiddle;
