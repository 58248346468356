import React from "react";

const ValorantTopComponent = () => {
  return (
    <div className="hidden md:block">
      <div
        className="flex items-center rounded-xl h-40 md:h-56 py-16 px-5 relative bg-no-repeat bg-cover mb-2"
        style={{
          backgroundImage:
            "linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url(https://geekflare.com/wp-content/uploads/2022/08/valorant-ranks.png)",
        }}
      >
      </div>
    </div>
  );
};

export default ValorantTopComponent;
