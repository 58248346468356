import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";
import { userTimeZone } from "../utils/timeZone";

export const useCsgoAllData = ({ date, time, filter }) => {
  return useQuery({
    queryKey: ["csgo", date, filter], // DD-MM-YYYY
    queryFn: async () => {
      const response = await fetcher.get("/api/csgo/date", {
        params: {
          value: date,
          time,
          timeZone: userTimeZone,
          filter,
        },
      });
      return response.data;
    },
    enabled: !!date,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};

export const useCsgoSingleData = (id) => {
  return useQuery({
    queryKey: ["csgo", id],
    queryFn: async () => {
      const response = await fetcher.get(`/api/csgo/${id}`, {
        params: { timeZone: userTimeZone },
      });
      return response?.data;
    },
    enabled: !!id,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};
