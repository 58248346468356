import { Card, List, ListItem } from "@material-tailwind/react";

import { useState } from "react";
import { BiSolidCoupon } from "react-icons/bi";
import {
  MdDashboard,
  MdGroups,
  MdSettings,
  MdSportsSoccer,
} from "react-icons/md";
import { GiLaserGun, GiMachineGunMagazine } from "react-icons/gi";
import { FaPenFancy } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const adminNavList = [
  {
    name: "Dashboard",
    link: "/admin",
    icon: <MdDashboard />,
  },
  {
    name: "Soccer",
    link: "/admin/matches/soccer",
    icon: <MdSportsSoccer />,
  },
  {
    name: "CS-GO",
    link: "/admin/matches/cs-go",
    icon: <GiMachineGunMagazine />,
  },

  {
    name: "Valorant",
    link: "/admin/matches/valorant",
    icon: <GiLaserGun />,
  },
  // {
  //     name: 'Combo',
  //     link: "/admin/combo",
  //     icon: <MdGroupWork />
  // },
  // {
  //     name: 'Categories M',
  //     link: "/admin/categories",
  //     icon: <MdCategory />
  // },
  // {
  //     name: 'Betting List',
  //     link: "/admin/betting-list",
  //     icon: <AiOutlineUnorderedList />
  // },
  // {
  //     name: 'Teams',
  //     link: "/admin/teams",
  //     icon: <MdPeople />
  // },
  // {
  //     name: 'Categories T',
  //     link: "/admin/team-categories",
  //     icon: <MdCategory />
  // },
  {
    name: "Users",
    link: "/admin/users",
    icon: <MdGroups />,
  },
  // {
  //     name: 'Gift Codes',
  //     link: "/admin/gift-codes",
  //     icon: <MdCardGiftcard />
  // },
  {
    name: "Coupons",
    link: "/admin/coupons",
    icon: <BiSolidCoupon />,
  },
  // {
  //     name: 'Platforms',
  //     link: "/admin/platform",
  //     icon: <DiGoogleCloudPlatform />
  // },
  // {
  //     name: 'Currencies',
  //     link: "/admin/currencies",
  //     icon: <MdCurrencyExchange />
  // },
  // {
  //     name: 'Admins',
  //     link: "/admin/admins",
  //     icon: <MdEmojiPeople />
  // },
  // {
  //     name: 'Staff',
  //     link: "/admin/staff",
  //     icon: <AiOutlineTeam />
  // },
  // {
  //     name: 'Guidelines',
  //     link: "/admin/guidelines",
  //     icon: <MdPictureAsPdf />
  // },
  {
    name: "Settings",
    link: "/admin/settings",
    icon: <MdSettings />,
  },
  {
    name: "Add Blog",
    link: "/admin/create-blog",
    icon: <FaPenFancy />

  }
];

export default function AdminSideBar({ open }) {
  const { pathname } = useLocation();

  const isCurrentPath = (path) => pathname === path;

  return (
    <>
      {
        <Card
          className={`h-[calc(100vh-4.5rem)] ${
            open ? "fixed" : "hidden lg:block"
          } z-10 lg:static w-full max-w-[15rem] py-4 shadow-md shadow-[#344868] rounded-none border-none overflow-y-scroll scroll-hidden bg-[#d3e3fd] `}
        >
          <div className="p-0">
            {adminNavList.map((item, index) => {
              return (
                <Link to={item.link} key={index}>
                  <List>
                    <ListItem
                      className={`group cursor-pointer font hover:bg-white ${
                        isCurrentPath(item?.link)
                          ? "focus:bg-[#b7d3ff] bg-[#b7d3ff] rounded-full text-black font-bold"
                          : ""
                      }`}
                    >
                      <div className="flex items-center gap-4 ">
                        <p className="text-xl">{item.icon}</p>
                        <p
                          className={`text-sm group-hover:text-lg group-focus:text-black group-focus:font-extrabold transition-all`}
                        >
                          {item.name}
                        </p>
                      </div>
                    </ListItem>
                  </List>
                </Link>
              );
            })}
          </div>
        </Card>
      }
    </>
  );
}
