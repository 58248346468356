import emailjs from "@emailjs/browser";
import { Button, Card, CardBody, Rating } from "@material-tailwind/react";
import Cookies from "js-cookie";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { MdWhatsapp } from "react-icons/md";
import { fetcher } from "../utils/authFetch";

const FeedBack = () => {
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
  const userFields = user?.fields;
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: userFields?.Name || "",
    email: userFields?.Email || "",
    rating: 5,
    message: "",
  });

  const sendEmail = async (input) => {
    emailjs
      .send("service_pwyxccc", "template_ey36d3n", input, "V_vHkacTpJ3QSbc4h")
      .then(
        function (response) {
          toast.success("Your review is added successfully");
        },
        function (err) {
          toast.error("Something went wrong");
        }
      );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await sendEmail(formData);
      const res = await fetcher.post("/api/review", formData);
      const data = await res.data;
      if (data.success) {
        toast.success("Your review is added successfully");
        setFormData((prevFormData) => ({
          ...prevFormData,
          message: "",
        }));
      }
    } catch (error) {
      console.error("Error : ", error);
    } finally {
      setIsLoading(false);
    }
  };
  const recipientPhoneNumber = "46708698276";

  return (
    <Card className="dark:bg-primary bg-lightPrimary h-[calc(100vh-162px)]">
      <CardBody className="text-white p-4 md:p-6 overflow-y-scroll scroll-hidden">
        <h2 className="text-2xl font-medium dark:text-white text-blue-900">
          Give FeedBack
        </h2>

        <form className="flex flex-col gap-6 lg:mt-12 mt-6" onSubmit={onSubmit}>
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start gap-1 sm:gap-2">
            <p className="dark:text-gray-300 text-gray-900 text-md basis-2/12">
              Name:
            </p>
            <input
              type="text"
              placeholder="Name"
              className="flex-1 block w-full dark:text-gray-300 text-gray-900 dark:bg-secondary bg-lightSecondary border-0 outline-none p-3 rounded-lg"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start gap-1 sm:gap-2">
            <p className="dark:text-gray-300 text-gray-900 text-md basis-2/12">
              Email:
            </p>
            <input
              type="email"
              placeholder="Email"
              className="flex-1 block w-full dark:text-gray-300 text-gray-900 dark:bg-secondary bg-lightSecondary border-0 outline-none p-3 rounded-lg"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>

          <div className="flex items-center justify-start gap-1 sm:gap-2">
            <p className="dark:text-gray-300 text-gray-900 text-md basis-2/12">
              Rating:
            </p>
            <Rating
              value={formData?.rating}
              onChange={(value) => setFormData({ ...formData, rating: value })}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-start justify-start gap-1 sm:gap-2">
            <p className="dark:text-gray-300 text-gray-900 text-md basis-2/12 mt-4">
              Your Feedback:
            </p>
            <textarea
              placeholder="Please help us improve our service by giving us your feedback!"
              rows={8}
              className="flex-1 block w-full dark:text-gray-300 text-gray-900 placeholder:text-gray-500 dark:bg-secondary bg-lightSecondary border-0 outline-none p-3 rounded-lg"
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              required
            />
          </div>
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start gap-1 sm:gap-2">
            <div className=" basis-2/12"></div>
            <Button
              color="green"
              className="flex-1 block w-full text-md capitalize tracking-wider"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Submitting.." : "Submit"}
            </Button>
          </div>
        </form>
        <div className="fixed bottom-10 right-10">
          <a
            href={`https://wa.me/${recipientPhoneNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MdWhatsapp className="bg-green-800 text-white p-2 h-14 w-14 rounded-full animate-bounce" />
          </a>
        </div>
      </CardBody>
    </Card>
  );
};

export default FeedBack;
