import React, { useState } from "react";
import DashboardFilter from "./DashboardFilter";
import DashboardSummary from "./DashboardSummary";
import DashboardCharts from "./DashboardCharts";
import { useAdminDashboard } from "../../../hooks/admin/useDashboard";

const AdminDashboard = () => {
  const [selectedDate, setSelectedDate] = useState("7");
  const [country, setCountry] = useState("All");
  const [league, setLeague] = useState("All");
  const [game, setGame] = useState("soccer");

  const { data, isLoading } = useAdminDashboard({
    days: selectedDate,
    league,
    country,
    game,
  });

  // console.log(data?.data?.soccerData)

  return (
    <div>
      <DashboardFilter
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        country={country}
        setCountry={setCountry}
        league={league}
        setLeague={setLeague}
        game={game}
        setGame={setGame}
      />
      <DashboardSummary data={data?.data?.accuracyData} isLoading={isLoading} />

      <DashboardCharts data={data?.data} isLoading={isLoading} />
    </div>
  );
};

export default AdminDashboard;
