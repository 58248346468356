import { Card, CardBody } from "@material-tailwind/react";
import React from "react";
import { useParams } from "react-router-dom";
import { useValorantSingleData } from "../../hooks/useValorant";
import { convertTo24HourFormat } from "../../utils/formatter";
import { imgUrl } from "../../utils/img";
import BackButton from "../shared/BackButton";
import Loading from "../shared/Loading/Loading";

const ValorantDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useValorantSingleData(id);
  const fixtureFields = data?.data?.fields;

  return (
    <>
      {isLoading ? (
        <Loading className="h-40" />
      ) : (
        <Card className="dark:bg-primary bg-lightPrimary  h-[calc(100vh-327px)]  md:h-[calc(100vh-393px)] ">
          <CardBody className="overflow-y-scroll scroll-hidden text-justify p-3 text-gray-200">
            <div className="flex justify-start mb-6">
              <BackButton />
            </div>
            <main className="mx-auto max-w-screen-xl my-5">
              <div className="card px-1 lg:px-8 py-6 shadow dark:bg-secondary bg-lightSecondary  rounded-xl">
                <div className="flex items-center justify-between md:gap-10">
                  <div className="flex-1 flex flex-col-reverse md:flex-row items-center gap-5 justify-start">
                    <p className="font-bold text-xs md:text-xl text-center dark:text-white text-black">
                      {fixtureFields?.Team1}
                    </p>
                    <img
                      className="lg:w-14 w-6 lg:h-14 h-6"
                      src={imgUrl(fixtureFields?.T1Logo)}
                      alt={`logo`}
                    />
                  </div>
                  <div className="flex-1 text-center">
                    {fixtureFields?.Result ? (
                      <p className="text-green-500 text-sm">
                        Prediction:{" "}
                        <strong>
                          {fixtureFields[`Team${fixtureFields?.Prediction}`]}
                        </strong>
                      </p>
                    ) : (
                      <p className="text-red-500 text-xl">V/S</p>
                    )}
                  </div>
                  <div className="flex-1 flex flex-col md:flex-row items-center gap-5 justify-end">
                    <img
                      className="lg:w-14 w-6 lg:h-14 h-6"
                      src={imgUrl(fixtureFields?.T2Logo)}
                      alt={`logo`}
                    />
                    <p className="font-bold text-xs md:text-xl text-center text-black dark:text-white">
                      {fixtureFields?.Team2}
                    </p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <h3 className="font-bold text-center text-sm md:text-lg mt-2 text-blue-700">
                    {fixtureFields?.Event} -{" "}
                    {convertTo24HourFormat(fixtureFields?.Time)} -{" "}
                    {fixtureFields?.Venue ? fixtureFields?.Venue : "World"}
                  </h3>
                </div>
              </div>
            </main>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default ValorantDetails;
