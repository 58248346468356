import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import { format } from "date-fns";
import React, { useState } from "react";
import fakeImg from "../../assets/images/football-leagues/1.png";
import { convertTo24HourFormat } from "../../utils/formatter";

const ValorantPrediction = ({ open, handleOpen, data }) => {
  const [possibleOutcome, setPossibleOutcome] = useState(0.0);
  const [betAmount, setBetAmount] = useState(0.0);

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className="bg-lightSecondary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="md"
    >
      <DialogHeader className="dark:text-white text-black text-center">
        Our Prediction
      </DialogHeader>
      <DialogBody className="p-2 md:p-4">
        {/* Top */}
        <div className="px-4 md:px-8 py-6 shadow dark:bg-secondary bg-lightPrimary  rounded-xl">
          <div className="flex items-center justify-between gap-10">
            <div className="flex-1 flex flex-col items-center gap-5 justify-start">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={data?.fields?.T1Logo || fakeImg}
                alt={`${data?.fields?.Team1} logo`}
              />
              <p className="font-bold text-xs md:text-lg text-center">
                {data?.fields?.Team1}
              </p>
            </div>
            <div className="flex flex-col items-center justify-center text-black dark:text-white">
              <h4 className="text-xl lg:text-4xl  mb-2 lg:mb-4">
                {convertTo24HourFormat(data?.fields?.Time)}
              </h4>
              <div className="flex items-center justify-center gap-1 lg:gap-2 mb-2">
                <p className="bg-lightSecondary dark:bg-primary lg:px-3 px-2 text-xs lg:text-md py-[2px] rounded-full">
                  {data?.fields?.BestOdds1}
                </p>
                <p className="bg-lightSecondary dark:bg-primary lg:px-3 px-2 text-xs lg:text-md py-[2px] rounded-full">
                  {data?.fields?.BestOdds2}
                </p>
              </div>
              <p className="text-sm md:text-md font-medium">
                {format(new Date(data?.fields?.Date), "PP")}
              </p>
            </div>
            <div className="flex-1 flex flex-col items-center gap-5 justify-end">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={data?.fields?.T2Logo || fakeImg}
                alt={`${data?.fields?.Team2} logo`}
              />
              <p className="font-bold text-xs md:text-lg text-center">
                {data?.fields?.Team2}
              </p>
            </div>
          </div>
        </div>

        {/* 2nd Card */}
        <div className="mt-4 px-4 md:px-8 py-6 shadow dark:bg-secondary bg-lightPrimary rounded-xl">
          <h4 className="text-sm lg:text-xl text-center text-black dark:text-white">
            We Predict:{" "}
            <strong className="font-bold tracking-widest text-green-800 dark:text-green-500">
              {data?.fields[`Team${data?.fields?.Prediction}`]}
            </strong>
          </h4>
        </div>
        <div className="card mt-4 px-8 py-6 shadow bg-lightPrimary dark:bg-secondary rounded-xl">
          <h4 className="text-sm lg:text-md text-center text-black dark:text-white">
            We recommend you to invest{" "}
            {data?.fields?.Investment_percentage
              ? `${data?.fields?.Investment_percentage}%`
              : "15%"}{" "}
            of your wallet
          </h4>
        </div>
        {/* 3rd Card */}
        <div className="card mt-4 px-4 py-6 shadow dark:bg-secondary bg-lightPrimary rounded-xl">
          <div className="flex items-center justify-between">
            <p className="dark:text-white text-black text-lg">
              {data?.fields[`Team${data?.fields?.Prediction}`]}
            </p>
            <p className="dark:text-white text-black text-lg">
              {data?.fields[`BestOdds${data?.fields?.Prediction}`]}
            </p>
          </div>

          <div className="flex items-center justify-between mt-3">
            <p className="dark:text-gray-400 text-gray-800 text-sm">
              Your Investment:
            </p>
            <div>
              <input
                type="text"
                className="outline-none border-none w-24 h-10 text-right px-1 text-xl text-black rounded-md"
                onChange={(e) => {
                  setBetAmount(e?.target?.value);
                  setPossibleOutcome(
                    e?.target?.value *
                      data?.fields[`BestOdds${data?.fields?.Prediction}`]
                  );
                }}
                value={betAmount}
              />
            </div>
          </div>
          <div className="w-full bg-gray-500 h-[1px] my-4"></div>

          <div className="flex items-start justify-between text-right mt-8">
            <span className="bg-green-800 text-white rounded-md px-2">
              Get RETURN
            </span>

            <div className="flex flex-col items-end">
              <p className="dark:text-white text-black">Possible Outcome: </p>
              <p className="dark:text-white text-black text-lg">
                {Number(possibleOutcome).toFixed(2)}
              </p>
            </div>

            {/* + */}
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          className="dark:bg-white dark:text-black bg-black text-white"
          onClick={handleOpen}
        >
          <span>Close</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default ValorantPrediction;
