import { Card, CardBody } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MultiChainModal from "../components/multiChain/MultiChainModal";
import MultiChainPredictionCard from "../components/multiChain/MultiChainPredictionCard";
import Loading from "../components/shared/Loading/Loading";
import UpdatePlanModal from "../components/shared/UpdatePlanModal";
import { useAllComboData } from "../hooks/useCombo";
import formatDate from "../utils/formatter";
import { isEligible } from "../utils/isEligible";

const navList = [
  {
    name: "All",
    param: "all",
  },
  {
    name: "Finished",
    param: "finished",
  },
  {
    name: "Schedule",
    param: "schedule",
  },
];

const MultiChainPrediction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [dateFilter, setDateFilter] = useState(formatDate(new Date()));
  const [filter, setFilter] = useState("all");
  const params = new URLSearchParams(location.search);
  const [updatePlan, setUpdatePlan] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [filteredData, setFilteredData] = useState([]);

  const { data, isLoading } = useAllComboData({
    date: dateFilter,
  });

  // Get filter and date from url
  useEffect(() => {
    const urlFilter = params?.get("filter");
    const urlDate = params?.get("date");
    if (urlFilter) {
      setFilter(urlFilter);
    }
    if (urlDate) {
      setDateFilter(urlDate);
    }
  }, []);

  // Filter data based on filter and date
  useEffect(() => {
    if (filter === "all") {
      setFilteredData(data?.data);
    } else if (filter === "finished") {
      const newFilter = data?.data?.filter((d) =>
        d?.some(
          (game) =>
            game?.data?.Results ||
            game?.data?.Result ||
            game?.data?.MatchResults
        )
      );
      setFilteredData(newFilter);
    } else if (filter === "schedule") {
      const newFilter = data?.data?.filter((d) =>
        d?.every(
          (game) =>
            !game?.data?.Results &&
            !game?.data?.Result &&
            !game?.data?.MatchResults
        )
      );
      setFilteredData(newFilter);
    }
  }, [filter, data]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setDateFilter(newDate);
  };
  const handleFilterClick = (filterParam) => {
    setFilter(filterParam);
    params.set("filter", filterParam);
    const newUrl = `${location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleDetailsClick = (data) => {
    const d = data;
    if (data) {
      localStorage.setItem("combo", JSON.stringify(d));
      navigate("/multi-prediction/multichaindetails");
    }
  };

  const handlePredictionClick = (data) => {
    const eligibility = isEligible(data, "Proplus");
    if (eligibility) {
      handleOpen();
      setSelected(data);
    } else {
      setUpdatePlan(true);
    }
  };

  return (
    <>
      <Card className="dark:bg-primary bg-lightPrimary h-[calc(100vh-162px)]">
        <CardBody className="text-white p-2 md:p-4">
          <div>
            {isLoading ? (
              <Loading />
            ) : (
              <div className="p-2">
                <div className="flex md:flex-row flex-col justify-between items-start gap-4 sticky top-0 bg-lightPrimary dark:bg-primary rounded-xl z-20">
                  <div className="overflow-y-scroll scroll-hidden text-justify text-gray-200">
                    <div className="flex justify-between items-center ">
                      <div className="flex gap-2 ">
                        {navList.map((item, i) => (
                          <button
                            key={i}
                            className={`text-[10px] py-1 px-2.5 rounded-lg ${
                              filter === item.param
                              ? "bg-primaryLighter text-black"
                              : "bg-lightSecondary dark:bg-secondary dark:text-white text-black"
                            }`}
                            onClick={() => handleFilterClick(item.param)}
                          >
                            {item.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className={`${filter === "live" ? "hidden" : "flex"}`}>
                    <input
                      type="date"
                      id="date"
                      className={`bg-lightSecondary text-black  dark:text-white dark:datepicker dark:bg-secondary px-2.5 text-[10px] py-[3px] rounded-lg w-full`}
                      onChange={handleDateChange}
                      value={dateFilter}
                    />
                  </div>
                </div>
                {filteredData?.length ? (
                  <div className="flex flex-col gap-4 mt-4">
                    {filteredData?.map((d, index) => {
                      return (
                        <MultiChainPredictionCard
                          d={d}
                          key={index}
                          index={index}
                          handlePredictionClick={handlePredictionClick}
                          handleDetailsClick={handleDetailsClick}
                          filter={filter}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <p className="mt-6 dark:text-lightPrimary text-black">
                    No combo today
                  </p>
                )}
              </div>
            )}
          </div>
          {selected ? (
            <MultiChainModal
              open={open}
              handleOpen={handleOpen}
              data={selected}
            />
          ) : null}
        </CardBody>
      </Card>

      <UpdatePlanModal
        open={updatePlan}
        handleOpen={() => setUpdatePlan(false)}
      />
    </>
  );
};

export default MultiChainPrediction;

// ---------------- API V1 ----------------
// <div className="flex flex-col gap-4 mt-4">
//   {data?.data?.map((d, index) => {
//     const GameData1 = d?.fields?.GameData1;
//     const GameData2 = d?.fields?.GameData2;
//     const GameData3 = d?.fields?.GameData3;

//     const resultExist = () => {
//       const PredictionResult1 =
//         GameData1?.MatchResults ||
//         GameData1?.Results ||
//         GameData1?.Result;
//       const PredictionResult2 =
//         GameData2?.MatchResults ||
//         GameData2?.Results ||
//         GameData2?.Result;
//       const PredictionResult3 =
//         GameData3?.MatchResults ||
//         GameData3?.Results ||
//         GameData3?.Result;

//       let result;

//       if (GameData3) {
//         result =
//           PredictionResult1 &&
//           PredictionResult2 &&
//           PredictionResult3;
//       } else if (GameData2) {
//         result = PredictionResult1 && PredictionResult2;
//       }

//       return result;
//     };

//     const isPredictionCorrect =
//       GameData1?.Results === "FALSE" ||
//       GameData1[`True/false`] === "false" ||
//       GameData1?.TrueFalse === "False" ||
//       GameData2?.Results === "FALSE" ||
//       GameData2[`True/false`] === "false" ||
//       GameData2?.TrueFalse === "False" ||
//       (GameData3
//         ? GameData3?.Results === "FALSE" ||
//           GameData3[`True/false`] === "false" ||
//           GameData3?.TrueFalse === "False"
//         : false)
//         ? false
//         : true;

//     return (
//       <Card
//         className="dark:bg-secondary bg-lightSecondary"
//         key={index + 1}
//       >
//         <CardBody className="px-1 md:px-4 py-4">
//           <div className="flex items-center justify-between">
//             <div className="flex items-center gap-1 md:gap-8">
//               {resultExist() ? (
//                 <FaStar
//                   color={
//                     isPredictionCorrect ? "green" : "red"
//                   }
//                   className="text-sm lg:text-md"
//                 />
//               ) : (
//                 <FaRegStar className="text-black dark:text-white text-sm lg:text-md" />
//               )}
//               <p className="font-semibold dark:text-white text-black text-[8px] md:text-sm">
//                 {d?.fields?.Date}
//               </p>
//               <p className="dark:bg-white bg-black text-white dark:text-black px-1 lg:px-3 rounded-full text-[8px] md:text-sm">
//                 Combo {index + 1}
//               </p>
//             </div>
//             <div className="flex items-center gap-0 md:gap-8">
//               {d?.fields?.Gamename1 ? (
//                 <div className="flex flex-col items-center gap-1">
//                   <img
//                     className="md:w-8 w-6 h-6 md:h-8 rounded-full overflow-hidden"
//                     src={
//                       d?.fields?.Gamename1 === "soccer"
//                         ? "https://www.rustonsportscomplex.com/wp-content/uploads/2021/05/2.png"
//                         : d?.fields?.Gamename1 === "csgo"
//                         ? csgoLogo
//                         : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReCA33wfFbf64cdgrTB0sU3R_f0XsL87HxvQ&usqp=CAU"
//                     }
//                     alt=""
//                   />
//                   <GameStatus
//                     name={d?.fields?.Gamename1}
//                     data={d?.fields?.GameData1}
//                   />
//                 </div>
//               ) : null}
//               {d?.fields?.Gamename2 ? (
//                 <div className="flex flex-col gap-1 items-center">
//                   <img
//                     className="md:w-8 w-6 h-6 md:h-8 rounded-full overflow-hidden"
//                     src={
//                       d?.fields?.Gamename2 === "soccer"
//                         ? "https://www.rustonsportscomplex.com/wp-content/uploads/2021/05/2.png"
//                         : d?.fields?.Gamename2 === "csgo"
//                         ? csgoLogo
//                         : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReCA33wfFbf64cdgrTB0sU3R_f0XsL87HxvQ&usqp=CAU"
//                     }
//                     alt=""
//                   />
//                   <GameStatus
//                     name={d?.fields?.Gamename2}
//                     data={d?.fields?.GameData2}
//                   />
//                 </div>
//               ) : null}
//               {d?.fields?.Gamename3 ? (
//                 <div className="flex flex-col items-center gap-1">
//                   <img
//                     className="md:w-8 w-6 h-6 md:h-8 rounded-full overflow-hidden"
//                     src={
//                       d?.fields?.Gamename3 === "soccer"
//                         ? "https://www.rustonsportscomplex.com/wp-content/uploads/2021/05/2.png"
//                         : d?.fields?.Gamename3 === "csgo"
//                         ? csgoLogo
//                         : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReCA33wfFbf64cdgrTB0sU3R_f0XsL87HxvQ&usqp=CAU"
//                     }
//                     alt=""
//                   />
//                   <GameStatus
//                     name={d?.fields?.Gamename3}
//                     data={d?.fields?.GameData3}
//                   />
//                 </div>
//               ) : null}
//             </div>
//             <div className="flex flex-row items-center lg:gap-4 gap-2">
//               <button
//                 className={`dark:bg-primary bg-lightPrimary md:text-sm text-[8px] px-1 py-0.5 lg:px-3 dark:text-white text-black rounded `}
//                 onClick={() => handlePredictionClick(d)}
//               >
//                 Prediction
//               </button>

//               <button
//                 title="Show Details"
//                 onClick={() => handleDetailsClick(data)}
//               >
//                 <FaRegWindowMaximize className="text-black dark:text-white lg:text-lg  text-xs" />
//               </button>
//             </div>
//           </div>
//         </CardBody>
//       </Card>
//     );
//   })}
// </div>
