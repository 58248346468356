import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";

export const usePlans = ({ filter }) => {
  return useQuery({
    queryKey: ["plans", filter],
    queryFn: async () => {
      const response = await fetcher.get("/api/payment/get-plans");

      if (filter === "monthly") {
        return response?.data?.data?.filter(
          (plan) => plan?.fields?.type === "monthly"
        );
      } else if (filter === "semiAnnual") {
        return response?.data?.data?.filter(
          (plan) => plan?.fields?.type === "semiAnnual"
        );
      }

      return response?.data;
    },

    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};
