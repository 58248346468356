import { IconButton, Tooltip } from "@material-tailwind/react";
import React from "react";
import { MdDarkMode, MdOutlineWbSunny } from "react-icons/md";
import { useDarkMode } from "./DarkModeContext";

const DarkMode = () => {
  const { darkMode, toggleDarkMode } = useDarkMode();

  return (
    <>
      <Tooltip
        content="Mode"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0, y: 25 },
        }}
        className="dark:bg-white dark:text-black bg-black text-white"
      >
        <IconButton
          onClick={toggleDarkMode}
          variant="text"
          className="text-2xl group"
        >
          {darkMode ? (
            <MdOutlineWbSunny className="text-white group-hover:rotate-90 transition-all duration-300" />
          ) : (
            <MdDarkMode className="group-hover:rotate-[360deg] transition-all  duration-300" />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default DarkMode;
