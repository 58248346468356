import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";
import { userTimeZone } from "../utils/timeZone";

export const useValorantAllData = ({ date, time, filter }) => {
  return useQuery({
    queryKey: ["valorant", date, filter], // YYYY-MM-DD
    queryFn: async () => {
      const response = await fetcher.get("/api/valorant/date", {
        params: { value: date, time, timeZone: userTimeZone, filter },
      });
      return response.data;
    },
    enabled: !!date,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};

export const useValorantSingleData = (id) => {
  return useQuery({
    queryKey: ["valorant", id],
    queryFn: async () => {
      const response = await fetcher.get(`/api/valorant/${id}`, {
        params: { timeZone: userTimeZone },
      });

      return response?.data;
    },

    enabled: !!id,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};
