import { format, parse } from "date-fns";

export default function formatDate(date = new Date()) {
  // const year = date.toLocaleString("default", { year: "numeric" });
  // const month = date.toLocaleString("default", { month: "2-digit" });
  // const day = date.toLocaleString("default", { day: "2-digit" });

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const convertDate = (date) => {
  const parsedDate = new Date(date);

  const ordinalSuffixes = ["th", "st", "nd", "rd"];

  const day = parsedDate.getDate();
  const dayWithSuffix = day + (ordinalSuffixes[(day - 1) % 10] || "th");

  const formattedDate =
    dayWithSuffix +
    " " +
    parsedDate.toLocaleDateString("en-US", {
      month: "long", // Full month name (e.g., "September")
      year: "numeric", // Full year (e.g., "2023")
    });
  return formattedDate;
};

export const csgoDate = (date) => {
  const dateComponents = date?.split("-");
  const formatted =
    dateComponents[2] + "-" + dateComponents[1] + "-" + dateComponents[0];

  return formatted;
};

export const convertTo24HourFormat = (time) => {
  if (!time.includes("AM") && !time.includes("PM")) return time;
  const inputFormat = "h:mm a"; // Input format
  const outputFormat = "HH:mm"; // Desired output format

  // Parse the time string into a Date object
  const newTime = parse(time, inputFormat, new Date());

  // Format the time in 24-hour format
  const formattedTime = format(newTime, outputFormat);
  return formattedTime;
};

export const weekFormatter = (date) => {
  // date = 2024-W1
  if (!date) return;
  const [year, week] = date?.split("-W");
  const paddedWeek = week?.length === 2 ? week : `0${week}`;
  const formattedWeek = `${year}-W${paddedWeek}`;

  return formattedWeek;
};

export const monthFormatter = (date) => {
  // date = 2024-01
  if (!date) return;

  const formattedMonth = format(new Date(date), "yyyy-MMM");

  return formattedMonth;
};
