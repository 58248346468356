import React from "react";
import img from "../../assets/images/football-leagues/2.png";

const FootBallTopComponent = () => {
  return (
    <div className="hidden md:block">
      <div className="flex items-center rounded-xl h-40 md:h-56 relative bg-[url('https://png.pngtree.com/thumb_back/fw800/back_our/20190621/ourmid/pngtree-world-cup-blue-gradient-flat-football-banner-image_194909.jpg')] bg-no-repeat bg-cover bg-opacity-10 mb-2">
        <img
          className="absolute bottom-0 right-4 h-36 md:h-44 "
          src={img}
          alt="football"
        />
      </div>
    </div>
  );
};

export default FootBallTopComponent;
