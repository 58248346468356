// FootBall
import FootballBottomAllComponent from "../components/football/FootballBottomAllComponent";
import FootballDetails from "../components/football/FootballDetails";
import Football from "../pages/Football";

// Csgo
import CsgoBottomAllComponent from "../components/csgo/CsgoBottomAllComponent";
import CsgoDetails from "../components/csgo/CsgoDetails";
import Csgo from "../pages/Csgo";

// Valorant
import ValorantBottomAllComponent from "../components/valorant/ValorantBottomAllComponent";
import Valorant from "../pages/Valorant";

// Others
import EmailVerify from "../components/Auth/EmailVerify";
import ForgotPassword from "../components/Auth/ForgotPassword";
import ForgotPasswordOTP from "../components/Auth/ForgotPasswordOTP";
import GoogleCallback from "../components/Auth/GoogleCallback";
import ResetPassword from "../components/Auth/ResetPassword";
import Account from "../components/account/Account";
import AdminError from "../components/admin/AdminError";
import NewsDetails from "../components/news/NewsDetails";
import CheckoutElement from "../components/payment/CheckoutElement";
import Payment from "../components/payment/Payment";
import Common from "../components/shared/Common";
import ValorantDetails from "../components/valorant/ValorantDetails";
import AdminLayout from "../layout/AdminLayout";
import AuthLayout from "../layout/AuthLayout";
import FeedBack from "../pages/FeedBack";
import HandicapPrediction from "../pages/HandicapPrediction";
import Login from "../pages/Login";
import MultiChainPrediction from "../pages/MultiChainPrediction";
import News from "../pages/News";
import OurAccuracy from "../pages/OurAccuracy";
import Pricing from "../pages/Pricing";
import SignUp from "../pages/SignUp";
import UserGuide from "../pages/UserGuide";
import AdminCSGO from "../pages/admin/AdminCSGO";
import AdminDashboard from "../pages/admin/AdminDashboard";
import AdminSoccer from "../pages/admin/AdminSoccer";
import AdminValorant from "../pages/admin/AdminValorant";
import Users from "../pages/admin/Users";
import AuthGuard from "../utils/AuthGuard";
import Main from "./Main";
import MultiChainMiddle from "../components/multiChain/MultiChainMiddle";
import ROI from "../pages/ROI";
import AdminDashboardV2 from "../pages/admin/AdminDashboardV2";
import CreateBlog from "../pages/admin/CreateBlog";

const { createBrowserRouter } = require("react-router-dom");

const router = createBrowserRouter([
  // User Routes
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <Football />,
        children: [
          {
            path: "/",
            element: <FootballBottomAllComponent />,
          },
          {
            path: "/football/:id",
            element: <FootballDetails />,
          },
        ],
      },
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/football",
        element: <Football />,
        children: [
          {
            path: "/football",
            element: <FootballBottomAllComponent />,
          },
          {
            path: "/football/:id",
            element: <FootballDetails />,
          },
        ],
      },
      {
        path: "/csgo",
        element: <Csgo />,
        children: [
          {
            path: "/csgo",
            element: <CsgoBottomAllComponent />,
          },
          {
            path: "/csgo/:id",
            element: <CsgoDetails />,
          },
        ],
      },
      {
        path: "/valorant",
        element: <Valorant />,
        children: [
          {
            path: "/valorant",
            element: <ValorantBottomAllComponent />,
          },
          {
            path: "/valorant/:id",
            element: <ValorantDetails />,
          },
        ],
      },
      {
        path: "/news",
        element: <News />,
        children: [
          {
            path: "/news/:id",
            element: <NewsDetails />,
          },
        ],
      },
      {
        path: "/multi-prediction",
        element: <MultiChainPrediction />,
      },
      {
        path: "/multi-prediction/multichaindetails",
        element: <MultiChainMiddle />,
      },
      {
        path: "/handicap-prediction",
        element: <HandicapPrediction />,
      },
      {
        path: "/roi",
        element: <ROI />,
      },
      {
        path: "/feedback",
        element: <FeedBack />,
      },
      {
        path: "/user-guide",
        element: <UserGuide />,
      },
      {
        path: "/our-accuracy",
        element: <OurAccuracy />,
      },

      {
        path: "*",
        element: <Common />,
      },
    ],
  },

  // Admin Routes
  {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "/admin",
        element: <AdminDashboardV2 />,
      },
      {
        path: "/admin/create-blog",
        element: <CreateBlog />,
      },
      // {
      //   path: "/admin/dashboard",
      //   element: <AdminDashboard />,
      // },
      {
        path: "/admin/matches/cs-go",
        element: <AdminCSGO />,
      },
      {
        path: "/admin/matches/soccer",
        element: <AdminSoccer />,
      },
      {
        path: "/admin/matches/valorant",
        element: <AdminValorant />,
      },
      {
        path: "/admin/users",
        element: <Users />,
      },
      // {
      //   path: "/admin/combo",
      //   element: <AdminCombo />,
      // },
      // {
      //   path: "/admin/categories",
      //   element: <CategoriesM />,
      // },
      // {
      //   path: "/admin/betting-list",
      //   element: <BettingList />,
      // },
      // {
      //   path: "/admin/teams",
      //   element: <Teams />,
      // },
      // {
      //   path: "/admin/team-categories",
      //   element: <CategoriesT />,
      // },
      {
        path: "*",
        element: <AdminError />,
      },
    ],
  },

  // Auth Routes
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      { path: "/login", element: <Login /> },
      { path: "/signup", element: <SignUp /> },
      { path: "/email-verify", element: <EmailVerify /> },
      { path: "/google-callback", element: <GoogleCallback /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
      { path: "/forgot-password-otp", element: <ForgotPasswordOTP /> },
      { path: "/reset-password", element: <ResetPassword /> },
    ],
  },

  // General Routes
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/subscription",
    element: (
      <AuthGuard>
        <CheckoutElement />,
      </AuthGuard>
    ),
  },
  {
    path: "/payment",
    element: <Payment />,
  },
]);
export default router;
