import { Button, IconButton } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { MdLock } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { fetcher } from "../../utils/authFetch";
import AuthContainer from "./AuthContainer";

const ResetPassword = () => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const email = sessionStorage.getItem("email");

  useEffect(() => {
    if (!email) {
      toast.error("Session Expired");
      navigate(`/login`);
    }
  }, [email, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  // reset password api
  const resetPasswordApi = async (data) => {
    try {
      const res = await fetcher.post("/api/auth/reset-password", data);
      return res?.data;
    } catch (error) {
      return Promise.reject(
        error?.response?.data?.message || "Something Wen't wrong"
      );
    }
  };

  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: "Password and confirm password does not match",
      });
      return;
    }
    toast.promise(resetPasswordApi({ email, password: data?.password }), {
      loading: "Resetting password...",
      success: (data) => {
        navigate(`/login`);
        return data?.message || "Password reset successful";
      },
      error: (errorMessage) => errorMessage,
    });
  };

  return (
    <AuthContainer>
      <div>
        <h2 className="text-center text-3xl font-semibold mb-4">
          Reset Password
        </h2>
        <p>Enter your new password. We prefer save or store your password.</p>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-12">
          {/* Password */}
          <div className="form-control w-full relative mt-4">
            <MdLock className="absolute left-3 top-4 text-xl" />
            <input
              type={visiblePassword ? "text" : "password"}
              placeholder="Password"
              className="p-3 pl-10 bg-gray-100 border-l-4 border-blue-900 focus:outline-none w-full rounded"
              {...register("password", {
                required: { value: true, message: "Choose a strong password" },
                pattern: {
                  // value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                  value:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d])?.{8,}$/,
                  message:
                    "Password should contain at least 8 characters, 1 uppercase, 1 lowercase and 1 number",
                },
              })}
            />
            <div className="absolute top-1 right-1">
              <IconButton
                variant="text"
                className="cursor-pointer text-xl rounded-full"
                onClick={() => setVisiblePassword(!visiblePassword)}
              >
                {visiblePassword ? <IoEye /> : <IoEyeOff />}
              </IconButton>
            </div>
            <label className="label">
              {(errors.password?.type === "required" ||
                errors.password?.type === "pattern") && (
                <span className="text-red-500 text-xs">
                  {errors.password.message}
                </span>
              )}
            </label>
          </div>

          {/* Confirm Password */}
          <div className="form-control w-full relative mt-4">
            <MdLock className="absolute left-3 top-4 text-xl" />
            <input
              type={visibleConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              className="p-3 pl-10 bg-gray-100 border-l-4 border-blue-900 focus:outline-none w-full rounded"
              {...register("confirmPassword", {
                required: {
                  value: true,
                  message: "Retype your password",
                },
              })}
            />
            <div className="absolute top-1 right-1">
              <IconButton
                variant="text"
                className="cursor-pointer text-xl rounded-full"
                onClick={() =>
                  setVisibleConfirmPassword(!visibleConfirmPassword)
                }
              >
                {visibleConfirmPassword ? <IoEye /> : <IoEyeOff />}
              </IconButton>
            </div>
            <label className="label">
              {(errors.confirmPassword?.type === "required" ||
                errors.confirmPassword?.type === "manual") && (
                <span className="text-red-500 text-xs">
                  {errors.confirmPassword.message}
                </span>
              )}
            </label>
          </div>

          <Button className="w-full bg-blue-900 mt-6" type="submit">
            Reset Password
          </Button>
        </form>
      </div>
    </AuthContainer>
  );
};

export default ResetPassword;
