import { Card, CardBody, Spinner } from "@material-tailwind/react";
import { format } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";
import fakeImg from "../../assets/images/football-leagues/1.png";
import { useCsgoSingleData } from "../../hooks/useCsgo";
import { csgoDate } from "../../utils/formatter";
import BackButton from "../shared/BackButton";

const CsgoDetails = () => {
  const params = useParams();

  const { data, isLoading } = useCsgoSingleData(params?.id);
  const dataFields = data?.data?.fields;

  return (
    <Card className="dark:bg-primary bg-lightPrimary h-[calc(100vh-327px)] md:h-[calc(100vh-393px)]">
      <CardBody className="overflow-y-scroll scroll-hidden text-justify p-3 text-gray-200">
        {/* Back Button */}
        <div className="flex justify-start mb-6">
          <BackButton />
        </div>

        {/* Main Content */}
        <main className="mx-auto max-w-screen-xl my-5">
          {isLoading ? (
            <div className="grid place-items-center h-20">
              <Spinner />
            </div>
          ) : (
            <div className="px-1 lg:px-8 py-6 shadow dark:bg-secondary bg-lightSecondary rounded-xl">
              <div className="flex items-center justify-between  md:gap-10">
                {/* Left Side */}
                <div className="flex-1 flex flex-col items-center gap-3 justify-center">
                  <img
                    className="lg:w-14 w-6 lg:h-14 h-6"
                    src={dataFields?.T1Logo || fakeImg}
                    alt={`${dataFields?.Team1} logo`}
                  />
                  <p className="font-bold  text-xs md:text-xl text-center dark:text-white text-black">
                    {dataFields?.Team1}
                  </p>
                </div>

                {/* Date & Time */}
                <div className="flex flex-col items-center justify-start">
                  <p className="text-blue-500 text-center text-xs">{dataFields?.Event}</p>
                  <div className="text-center mt-4 mb-2">
                    {dataFields?.Results ? (
                      <p className="dark:text-green-500 text-green-900 text-sm md:text-lg">
                        Prediction:{" "}
                        <strong className="text-sm md:text-lg">
                          {dataFields[`Team${dataFields?.Prediction}`]}
                        </strong>
                      </p>
                    ) : (
                      <p className= "text-md md:text-xl text-red-500">{dataFields?.Time}</p>
                    )}
                  </div>
                  <p className="text-center dark:text-white text-black text-sm md:text-xl">
                    {format(new Date(csgoDate(dataFields?.Date)), "PP")}
                  </p>
                </div>

                {/* Right Side */}
                <div className="flex-1 flex flex-col items-center gap-3 justify-center">
                  <img
                    className="lg:w-14 w-6 lg:h-14 h-6"
                    src={dataFields?.T2Logo || fakeImg}
                    alt={`${dataFields?.Team2} logo`}
                  />
                  <p className="font-bold text-xs md:text-xl text-center dark:text-white text-black">
                    {dataFields?.Team2}
                  </p>
                </div>
              </div>
            </div>
          )}
        </main>
      </CardBody>
    </Card>
  );
};

export default CsgoDetails;
