import Cookies from "js-cookie";

const AuthGuard = ({ children }) => {
  const token = Cookies.get("token");
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  if (token && user) {
    return children;
  }
  window.location.href = "/login";
};

export default AuthGuard;
