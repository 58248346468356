import { useEffect, useState } from "react";
import {
  FaAngleRight,
  FaRegStar,
  FaRegWindowMaximize,
  FaStar,
} from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import placeholderImg from "../../assets/images/valorant/valorant_placeholder.png";
import { convertTo24HourFormat } from "../../utils/formatter";
import { imgUrl } from "../../utils/img";
import { isEligible } from "../../utils/isEligible";
import { currentDate, currentTime } from "../../utils/timeZone";
import UpdatePlanModal from "../shared/UpdatePlanModal";
import ValorantPrediction from "./ValorantPrediction";

const ValorantCard = ({ item, isOdds }) => {
  const data = item?.fields;
  const navigate = useNavigate();
  const [predictionOpen, setPredictionOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [notEligibleOpen, setNotEligibleOpen] = useState(false);

  useEffect(() => {
    const newStatus =
      convertTo24HourFormat(data?.Time) <= currentTime &&
      data?.Date === currentDate &&
      !data?.Result
        ? "Live"
        : (data?.Date > currentDate ||
            (convertTo24HourFormat(data?.Time) > currentTime &&
              data?.Date === currentDate)) &&
          !data?.Results
        ? "UpComing"
        : "Finished";
    setStatus(newStatus);
  }, [data]);

  const handlePredictionClick = async () => {
    const eligibility = isEligible(item);
    if (eligibility) {
      setPredictionOpen(true);
    } else {
      setNotEligibleOpen(true);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mt-10">
        <div className="flex items-center gap-2">
          <div>
            <LazyLoadImage
              width={20}
              height={20}
              effect="opacity"
              placeholderSrc={placeholderImg}
              src={imgUrl(data?.Tournament_logo) || placeholderImg}
              alt="event"
            />
          </div>
          <div>
            <p className="dark:text-white text-black text-[13px] font-medium">
              {data?.Event}
            </p>
          </div>
        </div>
        <div>
          <FaAngleRight className="text-black dark:text-white" />
        </div>
      </div>

      {/* --------------------------Big devices------------------------------------------ */}
      <div className="lg:block hidden">
        <div className="flex justify-center lg:justify-between flex-col lg:flex-row  items-center mt-3 hover-bg-black p-3  rounded-lg bg-lightSecondary dark:bg-secondary gap-4">
          <div className="flex items-center gap-4">
            {data["TrueFalse"] === "False" || data["TrueFalse"] === "True" ? (
              <FaStar
                color={data["TrueFalse"] === "False" ? "red" : "green"}
                className="absolute left-3 lg:static"
              />
            ) : (
              <FaRegStar className="text-black dark:text-white " />
            )}
            {status === "UpComing" ? (
              <p
                className={`text-sm font-semibold px-2 py-[2px] dark:text-white text-black w-[60px]`}
              >
                {convertTo24HourFormat(data?.Time)}
              </p>
            ) : (
              <p
                className={`text-xs font-semibold px-1 py-[2px] rounded-md w-[60px] ${
                  status === "Finished" ? "text-orange-600 " : "text-red-600"
                }`}
              >
                {status || "N/A"}
              </p>
            )}
          </div>

          <div className="grid  grid-cols-3 w-full items-center justify-between">
            <div className="">
              <div className="flex items-center gap-4">
                <p className="font-semibold text-right text-sm text-black dark:text-white w-28">
                  {data?.Team1}
                </p>
                <LazyLoadImage
                  width={20}
                  height={20}
                  effect="opacity"
                  placeholderSrc={placeholderImg}
                  src={imgUrl(data?.T1Logo) || placeholderImg}
                  alt="team1"
                />
              </div>
            </div>

            {/* Middle */}
            {isOdds ? (
              <div className="">
                <div className="flex justify-center items-center gap-4">
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-xs text-black dark:text-white">Team 1</p>
                    <p className="px-3 font-medium text-orange-500 bg-primary rounded-full">
                      {Number(data?.BestOdds1).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-xs text-black dark:text-white">Team 2</p>
                    <p className="px-3 font-medium text-orange-500 bg-primary rounded-full">
                      {Number(data?.BestOdds2).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <div className="flex flex-col justify-center items-center">
                  {data?.Date >= currentDate && !data?.Result ? (
                    <p className="dark:bg-primary bg-lightPrimary text-black dark:text-orange-500 text-xs font-light px-3.5 py-0.5 rounded-full cursor-pointer">
                      Score
                    </p>
                  ) : (
                    <>
                      <p className="dark:bg-primary bg-lightPrimary text-black dark:text-orange-500 text-xs font-light px-3.5 py-0.5 rounded-full">
                        {data?.Team1Score} -{data?.Team2Score}
                      </p>
                      <p className="dark:text-yellow-500 text-center text-yellow-900 text-xs mt-2">
                        Winner is {data[`Team${data?.Result}`]}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="flex justify-end">
              <div className="flex items-center gap-4">
                <LazyLoadImage
                  width={20}
                  height={20}
                  effect="opacity"
                  placeholderSrc={placeholderImg}
                  src={imgUrl(data?.T2Logo) || placeholderImg}
                  alt="team2"
                />
                <p className="font-semibold text-left text-sm text-black dark:text-white w-28">
                  {data?.Team2}
                </p>
              </div>
            </div>
          </div>
          <div className="flex  items-center gap-4">
            <button
              disabled={status === "Finished"}
              className={`dark:bg-primary bg-lightPrimary text-black dark:text-white text-xs px-2 py-0.5 rounded ${
                status === "Finished" ? "static opacity-0" : "flex"
              }`}
              onClick={handlePredictionClick}
            >
              Prediction
            </button>
            <button onClick={() => navigate(`/valorant/${item?.id}`)}>
              <FaRegWindowMaximize className=" text-black dark:text-white" />
            </button>
          </div>
        </div>
      </div>
      {/* --------------------------Small devices------------------------------------------ */}
      <div className="block lg:hidden mt-2 px-0 py-4  bg-lightSecondary dark:bg-secondary rounded-xl">
        <div className="flex items-center relative">
          <div>
            {status === "UpComing" ? (
              <p
                className={`text-[10px] font-semibold px-2 py-[2px] dark:text-white text-black w-[58px]`}
              >
                {convertTo24HourFormat(data?.Time)}
              </p>
            ) : (
              <p
                className={`text-[10px] font-semibold px-1 py-[2px] rounded-md w-[58px] ${
                  status === "Finished" ? "text-orange-600 " : "text-red-600"
                }`}
              >
                {status || "N/A"}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-1">
              <LazyLoadImage
                className="w-4 h-4 object-contain"
                effect="opacity"
                placeholderSrc={placeholderImg}
                src={imgUrl(data?.T1Logo) || placeholderImg}
                alt="team1"
              />
              <p className="font-semibold text-[10px] text-black dark:text-white">
                {data?.Team1.length > 10
                  ? `${data?.Team1.slice(0, 10)}..`
                  : data?.Team1}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <LazyLoadImage
                className="w-4 h-4 object-contain"
                effect="opacity"
                placeholderSrc={placeholderImg}
                src={imgUrl(data?.T2Logo) || placeholderImg}
                alt="team2"
              />
              <p className="font-semibold text-[10px] text-black dark:text-white">
                {data?.Team2.length > 10
                  ? `${data?.Team2.slice(0, 10)}..`
                  : data?.Team2}
              </p>
            </div>
          </div>
          <div className={`flex items-center ${isOdds ? `gap-[6px]` : `gap-[18px]`} absolute right-2`}>
            {/* Middle */}
            {isOdds ? (
              <div className="">
                <div className="flex justify-center items-center gap-2">
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-[8px] text-black dark:text-white">
                      Team 1
                    </p>
                    <p className="px-1 text-[9px] font-medium text-orange-500 bg-primary rounded-full">
                      {Number(data?.BestOdds1).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-[8px] text-black dark:text-white">
                      Team 2
                    </p>
                    <p className="px-1 text-[9px] font-medium text-orange-500 bg-primary rounded-full">
                      {Number(data?.BestOdds2).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <div className="flex flex-col justify-center items-center">
                  {data?.Date >= currentDate && !data?.Result ? (
                    <p className="dark:bg-primary bg-lightPrimary text-black dark:text-orange-500 text-[10px] font-light px-2 py-0.5 rounded-full cursor-pointer">
                      Score
                    </p>
                  ) : (
                    <>
                      <p className="dark:bg-primary bg-lightPrimary text-black dark:text-orange-500 text-[10px] font-light px-3.5 py-0.5 rounded-full">
                        {data?.Team1Score} -{data?.Team2Score}
                      </p>
                      <p className="dark:text-yellow-500 text-yellow-900 text-[8px] w-[16] text-center mt-2">
                        Winner is  {data[`Team${data?.Result}`]}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className={`flex  items-center ${isOdds ? `gap-2` : `gap-4`}`}>
              <button
                disabled={status === "Finished"}
                className={`bg-lightPrimary dark:bg-primary  text-black dark:text-white   rounded  ${
                  status === "Finished" ? "opacity-0" : "flex"
                }
                ${isOdds ? `px-2 py-0.5 text-[8px]` : `px-2 py-0.5  text-[10px]`}`}
                onClick={handlePredictionClick}
              >
                Prediction
              </button>
              <button onClick={() => navigate(`/valorant/${item?.id}`)}>
                <FaRegWindowMaximize className=" text-black dark:text-white text-xs" />
              </button>
            </div>
            <div>
            {data["TrueFalse"] === "False" || data["TrueFalse"] === "True" ? (
              <FaStar
                color={data["TrueFalse"] === "False" ? "red" : "green"}
                className="text-xs"
              />
            ) : (
              <FaRegStar className="text-black dark:text-white text-xs" />
            )}
            </div>
          </div>
        </div>
      </div>

      <ValorantPrediction
        open={predictionOpen}
        handleOpen={() => setPredictionOpen(!predictionOpen)}
        data={item}
      />

      <UpdatePlanModal
        open={notEligibleOpen}
        handleOpen={() => setNotEligibleOpen(!notEligibleOpen)}
      ></UpdatePlanModal>
    </>
  );
};

export default ValorantCard;
