import { useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import FeaturedImage from "../../components/csgo/FeaturedImage";
import { fetcher } from "../../utils/authFetch";


const CreateBlog = () => {

    // hooks
    const [publishLoading, setPublishLoading] = useState(false);
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [slug, setSlug] = useState('');
    const [author, setAuthor] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setTags] = useState('');
    const [title, setTitle] = useState('');


    // sending data to backend

    const handlePublishArticle = () => {
        const blogDetails = { image, title, author, description, slug, tags, metaDescription, category };

        console.log(blogDetails)

        try {
            setPublishLoading(true);
            // setFormData(initialFormData);

            // modified here
            fetcher.post("/api/blogs", blogDetails)
                .then(res => {
                    const data = res.data;
                    if (data.success) {
                        console.log("successful:", data)
                        setPublishLoading(false);
                    }
                })
                .catch(error => {
                    setPublishLoading(false)
                    console.log(error)
                })
        } catch (error) {
            console.log("failed:", error)
            setPublishLoading(false);
        }
    }




    return (
        <div className="flex justify-between items-start gap-8">

            {/* React quill editor interface */}
            <div className="w-3/4 mt-10 flex flex-col justify-start items-start gap-5">

                {/* title */}
                <input
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                    name="blogTitle"
                    id="blogTitle"
                    placeholder="Title"
                    className="focus:outline-none py-4 bg-transparent text-5xl font-bold placeholder-gray-700" />

                <ReactQuill
                    theme="snow"
                    value={description}
                    style={{ height: '500px' }}
                    onChange={setDescription}
                    className="w-full rounded-xl min-h-60 bg-white mb-20" />


                {/* seo part */}
                <div className="w-full flex flex-col justify-start items-start gap-4">

                    <h3 className="text-3xl font-bold text-black">SEO Section</h3>

                    {/* meta description */}
                    <div className="w-full flex flex-col justify-start items-start gap-2">
                        <label htmlFor="metaDsc" className="font-medium flex justify-start items-center gap-2">
                            Meta description {metaDescription.length >= 160 && <p className="bg-[#ffe7be8e] text-orange-700 text-sm px-2 py-0.5 rounded-lg">(You are exceeding 160 characters!)</p>}
                        </label>
                        <textarea
                            onChange={(e) => setMetaDescription(e.target.value)}
                            name="metaDsc"
                            id="metaDsc"
                            placeholder="Enter meta description here"
                            className={`focus:outline-none px-3 py-1 border ${metaDescription.length >= 160 ? "border-yellow-400 focus:border-yellow-600 bg-[#ffffe5]" : "border-gray-300 focus:border-gray-500 bg-white"} transition-all duration-500 rounded-md w-full`} />
                    </div>
                </div>
            </div>

            {/* other post options */}
            <div className="w-1/4 min-h-[100vh] border-l px-5">

                <button
                    onClick={handlePublishArticle}
                    className="bg-primary hover:bg-primaryLighter duration-500 text-white px-5 py-3 font-semibold w-full rounded-lg text-xl">
                    {publishLoading ? "Please wait" : "Publish"}
                </button>

                {/* author + category + slug */}
                <div className="bg-white p-6 my-8 rounded-lg flex flex-col justify-start items-start gap-4">
                    {/* slug */}
                    <div className="w-full flex flex-col justify-start items-start gap-2">
                        <label htmlFor="slug" className="font-medium">Slug</label>
                        <input
                            type="text"
                            onChange={(e) => setSlug(e.target.value)}
                            name="slug"
                            id="slug"
                            placeholder="your-website-url" className="focus:outline-none px-3 py-1 border border-gray-300 focus:border-gray-500 rounded-md w-full" />
                    </div>

                    {/* category */}
                    <div className="w-full flex flex-col justify-start items-start gap-2">
                        <label
                            htmlFor="slug"
                            className="font-medium">
                            Category
                        </label>
                        <select
                            name="category"
                            id="category"
                            onChange={(e) => setCategory(e.target.value)}
                            className="focus:outline-none px-3 py-1 border border-gray-300 focus:border-gray-500 rounded-md w-full">
                            <option disabled value="">Choose a category</option>
                            <option value="soccer">Soccer</option>
                        </select>
                    </div>

                    {/* author  */}
                    <div className="w-full flex flex-col justify-start items-start gap-2">
                        <label htmlFor="author" className="font-medium">Author</label>
                        <input
                            type="text"
                            onChange={(e) => setAuthor(e.target.value)}
                            name="author"
                            id="author"
                            className="focus:outline-none px-3 py-1 border border-gray-300 focus:border-gray-500 rounded-md w-full" />
                    </div>

                    {/* featured image */}
                    <FeaturedImage setImage={setImage} />

                    {/* Tags */}
                    <div className="w-full flex flex-col justify-start items-start gap-2 mt-5">
                        <label htmlFor="tags" className="font-medium">Tags</label>
                        <input
                            onChange={(e) => setTags(e.target.value)}
                            type="text"
                            name="tags"
                            id="tags"
                            placeholder="tag1, tag2, tag3"
                            className="focus:outline-none px-3 py-1 border border-gray-300 focus:border-gray-500 rounded-md w-full" />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CreateBlog;