import { useState } from "react";
import { IoImageOutline } from "react-icons/io5";




const FeaturedImage = ({ data, setImage }) => {

    // hooks
    const [imageSrc, setImageSrc] = useState();

    // handle change
    function handleOnChange(event) {
        const reader = new FileReader();
        reader.onload = function (onLoadEvent) {
            setImageSrc(onLoadEvent.target.result);
        }
        setImage(event.target.files[0])
        reader.readAsDataURL(event.target.files[0]);
    }



    return (
        <div className=" relative">

            <form
                onChange={handleOnChange}
                className="my-5">
                <label htmlFor="featuredImage" className="font-medium">Featured image</label>
                <input type="file" id="featuredImage" accept="image/*" className="absolute -bottom-4 opacity-60 left-0" />
            </form>

            {
                imageSrc ?
                    <img
                        src={imageSrc}
                        alt={`featured`}
                        className="w-full mb-6"
                    />
                    :
                    data?.Image ?
                        <img
                            src={data?.Image}
                            alt={`${data?.Name}`}
                            className="w-full mb-6"
                        />
                        :
                        <div className="">
                            <IoImageOutline className="text-[300px] text-gray-500 mb-6" />
                        </div>
            }
        </div>
    );
};

export default FeaturedImage;