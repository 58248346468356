import { Avatar, Button, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import { imgUrl } from "../../utils/img";
import Loading from "../shared/Loading/Loading";
import HandicapAccuracyModal from "./HandicapAccuracyModal";

const TABLE_HEAD = ["Date and Time", "Teams", "Score", "League", "We Predict"];

const HandicapAccuracyTable = ({ isLoading, data = [], total = 0 }) => {
  const [predictionOpen, setPredictionOpen] = useState(false);
  const [item, setItem] = useState({});

  const handlePredictionClick = async (data) => {
    setItem(data);
    setPredictionOpen(true);
  };

  const isTrue = <div className="h-4 w-4 bg-green-700 rounded-full"></div>
  const isFalse = <div className="h-4 w-4 bg-red-700 rounded-full"></div>


  return (
    <div>
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD?.map((head) => (
              <th
                key={head}
                className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
              >
                <Typography
                  variant="small"
                  className="font-normal leading-none text-black"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={5}>
                <Loading className="h-96" />
              </td>
            </tr>
          ) : (
            <>
              {!data?.length ? (
                <tr>
                  <td colSpan={5}>
                    <Typography className="text-black dark:text-white text-center">
                      No data found
                    </Typography>
                  </td>
                </tr>
              ) : (
                <>
                  {data?.map((d, index) => {
                    const isLast = index === total - 1;
                    const classes = isLast ? "p-4" : "p-4";
                    return (
                      <tr
                        key={index + 1}
                        className="dark:even:bg-secondary even:bg-lightSecondary"
                      >
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                className="font-normal text-black dark:text-white"
                              >
                                {d?.fields.Date}
                              </Typography>
                              <Typography
                                variant="small"
                                className="font-normal opacity-70 text-black dark:text-white"
                              >
                                {d?.fields.Time}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-1">
                            {d?.fields?.T1Logo && (
                              <div className="flex items-center -space-x-4">
                                <Avatar
                                  variant="circular"
                                  alt="user 1"
                                  className="border object-contain h-full w-9 bg-white border-gray-300 hover:z-10 focus:z-10"
                                  src={imgUrl(d?.fields?.T1Logo)}
                                />
                                <Avatar
                                  variant="circular"
                                  alt="user 2"
                                  className="border object-contain h-full w-9 bg-white border-gray-300 hover:z-10 focus:z-10"
                                  src={imgUrl(d?.fields?.T2Logo)}
                                />
                              </div>
                            )}

                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                className="font-normal text-black dark:text-white"
                              >
                                {d?.fields.Matches ||
                                  `${d?.fields.Team1} vs ${d?.fields.Team2}`}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography className="text-black dark:text-white">
                            {Number(d?.fields.HomeGoals) === 0.0 ||
                              d?.fields?.Team1Score === 0
                              ? "0"
                              : Number(d?.fields.HomeGoals) ||
                              d?.fields?.Team1Score}{" "}
                            -{" "}
                            {Number(d?.fields.AwayGoals) === 0.0 ||
                              d?.fields?.Team2Score === 0
                              ? "0"
                              : Number(d?.fields.AwayGoals) ||
                              d?.fields?.Team2Score}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            title={d?.fields.LeagueName || d?.fields.Event}
                            className="text-black dark:text-white cursor-pointer"
                          >
                            {d?.fields.LeagueName ||
                              d?.fields.Event.slice(0, 18)}
                            ..
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Button
                            onClick={() => handlePredictionClick(d)}
                            size="sm"
                            className="bg-blue-500 text-white dark:text-black dark:bg-blue-200"
                          >
                            Our Prediction
                          </Button>
                          
                          {/* section for the showing point of the prediction */}

                          <div className="mt-2 flex gap-1">
                            {d?.fields?.T1CornerResult?.trim().toLowerCase() === "true"
                              ? isTrue
                              : d?.fields?.T1CornerResult?.trim().toLowerCase() === "false"
                                ? isFalse
                                : null
                            }
                            {
                              d?.fields?.T2CornerResult?.trim().toLowerCase() === "true"
                                ? isTrue
                                : d?.fields?.T2CornerResult?.trim().toLowerCase() === "false"
                                  ? isFalse
                                  : null
                            }
                            {
                              d?.fields?.TCornerResult?.trim().toLowerCase() === "true"
                                ? isTrue
                                : d?.fields?.TCornerResult?.trim().toLowerCase() === "false"
                                  ? isFalse
                                  : null
                            }
                            {
                              d?.fields?.T1GoalUOResult?.trim().toLowerCase() === "true"
                                ? isTrue
                                : d?.fields?.T1GoalUOResult?.trim().toLowerCase() === "false"
                                  ? isFalse
                                  : null
                            }
                            {
                              d?.fields?.T2GoalUOResult?.trim().toLowerCase() === "true"
                                ? isTrue
                                : d?.fields?.T2GoalUOResult?.trim().toLowerCase() === "false"
                                  ? isFalse
                                  : null
                            }
                            {
                              d?.fields?.TGoalUOResult?.trim().toLowerCase() === "true"
                                ? isTrue
                                : d?.fields?.TGoalUOResult?.trim().toLowerCase() === "false"
                                  ? isFalse
                                  : null
                            }

                            {
                              d?.fields?.T1YellowResult?.trim().toLowerCase() === "true"
                                ? isTrue
                                : d?.fields?.T1YellowResult?.trim().toLowerCase() === "false"
                                  ? isFalse
                                  : null
                            }
                            {
                              d?.fields?.T2YellowResult?.trim().toLowerCase() === "true"
                                ? isTrue
                                : d?.fields?.T2YellowResult?.trim().toLowerCase() === "false"
                                  ? isFalse
                                  : null
                            }
                            {
                              d?.fields?.TYellowResult?.trim().toLowerCase() === "true"
                                ? isTrue
                                : d?.fields?.TYellowResult?.trim().toLowerCase() === "false"
                                  ? isFalse
                                  : null
                            }
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
      <HandicapAccuracyModal
        open={predictionOpen}
        handleOpen={() => setPredictionOpen(!predictionOpen)}
        fixture={item}
      />
    </div>
  );
};

export default HandicapAccuracyTable;
