import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";

const TABLE_HEAD = ["", "Prediction", "Predicted Odds", "Status"];

const HandicapAccuracyModal = ({ open, handleOpen, fixture }) => {
  const fixtureFields = fixture?.fields;

  const TABLE_ROWS = fixtureFields
    ? [
      {
        name: "Corner Kicks",
        prediction: fixtureFields.T1CornerPredict1,
        odds: fixtureFields.T1CornerOdds ? fixtureFields.T1CornerOdds : null,
        status: fixtureFields.T1CornerPredict
          ? fixtureFields.T1CornerResult
            ? fixtureFields.T1CornerResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
      {
        name: "Goals",
        prediction: fixtureFields.T1GoalUOPredict
          ? fixtureFields.T1GoalUOPredict < 0
            ? `Under ${Math.abs(fixtureFields.T1GoalUOPredict)}`
            : `Over ${Math.abs(fixtureFields.T1GoalUOPredict)}`
          : null,
        odds: fixtureFields.T1GoalUOOdds ? fixtureFields.T1GoalUOOdds : null,
        status: fixtureFields.T1GoalUOPredict
          ? fixtureFields.HomeGoals
            ? fixtureFields.T1GoalUOPredict < 0
              ? (Number(fixtureFields.HomeGoals)) < Math.abs(fixtureFields.T1GoalUOPredict)
                ? 'Won'
                : 'Lost'
              : (Number(fixtureFields.HomeGoals)) > Math.abs(fixtureFields.T1GoalUOPredict)
                ? "Won"
                : 'Lost'
            : 'Not updated yet'
          : null,
      },
      {
        name: "Yellow Cards",
        prediction: fixtureFields.T1YellowPredict,
        odds: fixtureFields.T1YellowOdds ? fixtureFields.T1YellowOdds : null,
        status: fixtureFields.T1YellowPredict
          ? fixtureFields.T1YellowResult
            ? fixtureFields.T1YellowResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
    ]
    : [];

  const TABLE_ROWS2 = fixtureFields
    ? [
      {
        name: "Corner Kicks",
        prediction: fixtureFields.T2CornerPredict1,
        odds: fixtureFields.T2CornerOdds ? fixtureFields.T2CornerOdds : null,
        status: fixtureFields.T2CornerPredict
          ? fixtureFields.T2CornerResult
            ? fixtureFields.T2CornerResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
      {
        name: "Goals",
        prediction: fixtureFields.T2GoalUOPredict
          ? fixtureFields.T2GoalUOPredict < 0
            ? `Under ${Math.abs(fixtureFields.T2GoalUOPredict)}`
            : `Over ${Math.abs(fixtureFields.T2GoalUOPredict)}`
          : null,
        odds: fixtureFields.T2GoalUOOdds ? fixtureFields.T2GoalUOOdds : null,
        status: fixtureFields.T2GoalUOPredict
          ? fixtureFields.AwayGoals
            ? fixtureFields.T2GoalUOPredict < 0
              ? (Number(fixtureFields.AwayGoals)) < Math.abs(fixtureFields.T2GoalUOPredict)
                ? 'Won'
                : 'Lost'
              : (Number(fixtureFields.AwayGoals)) > Math.abs(fixtureFields.T2GoalUOPredict)
                ? "Won"
                : 'Lost'
            : 'Not updated yet'
          : null,
      },
      {
        name: "Yellow Cards",
        prediction: fixtureFields.T2YellowPredict,
        odds: fixtureFields.T2YellowOdds ? fixtureFields.T2YellowOdds : null,
        status: fixtureFields.T2YellowPredict
          ? fixtureFields.T2YellowResult
            ? fixtureFields.T2YellowResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
    ]
    : [];

  const TABLE_ROWSTotal = fixtureFields
    ? [
      {
        name: "Corner Kicks",
        prediction: fixtureFields.TCornerPredict1,
        odds: fixtureFields.TCornerOdds ? fixtureFields.TCornerOdds : null,
        status: fixtureFields.TCornerPredict
          ? fixtureFields.TCornerResult
            ? fixtureFields.TCornerResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
      {
        name: "Goals",
        prediction: fixtureFields.TGoalUOPredict
          ? fixtureFields.TGoalUOPredict < 0
            ? `Under ${Math.abs(fixtureFields.TGoalUOPredict)}`
            : `Over ${Math.abs(fixtureFields.TGoalUOPredict)}`
          : null,
        odds: fixtureFields.TGoalUOOdds ? fixtureFields.TGoalUOOdds : null,
        status: fixtureFields.TGoalUOPredict
          ? fixtureFields.AwayGoals && fixtureFields.HomeGoals
            ? fixtureFields.TGoalUOPredict < 0
              ? (Number(fixtureFields.AwayGoals) + Number(fixtureFields.HomeGoals)) < Math.abs(fixtureFields.TGoalUOPredict)
                ? 'Won'
                : 'Lost'
              : (Number(fixtureFields.AwayGoals) + Number(fixtureFields.HomeGoals)) > Math.abs(fixtureFields.TGoalUOPredict)
                ? "Won"
                : 'Lost'
            : 'Not updated yet'
          : null,
      },
      {
        name: "Yellow Cards",
        prediction: fixtureFields.TYellowPredict,
        odds: fixtureFields.TYellowOdds ? fixtureFields.TYellowOdds : null,
        status: fixtureFields.TYellowPredict
          ? fixtureFields.TYellowResult
            ? fixtureFields.TYellowResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
    ]
    : [];

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className="bg-lightSecondary  dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="lg"
    >
      <DialogHeader className="text-black dark:text-white text-center">
        Our Prediction
      </DialogHeader>
      <DialogBody>
        <Card className="bg-lightPrimary dark:bg-secondary rounded-xl overflow-hidden">
          <CardBody className=" px-2 py-6">
            {/* first team */}
            <h3 className="font-bold lg:text-lg text-gray-900 dark:text-gray-400 pl-4 ">
              {fixtureFields?.HomeTeam}
            </h3>
            <Card className="h-full w-full shadow-none rounded-none overflow-x-scroll scroll-hidden lg:overflow-hidden  bg-lightPrimary dark:bg-secondary">
              <table className="w-full min-w-max table-auto text-left  bg-lightPrimary dark:bg-secondary">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" bg-lightPrimary dark:bg-secondary pt-0 px-4 pb-4"
                      >
                        <Typography
                          variant="small"
                          className="font-normal leading-none opacity-70 dark:text-gray-100 text-black"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_ROWS.map(
                    ({ name, prediction, odds, status }, index) => {
                      const isLast = index === TABLE_ROWS.length - 1;
                      const classes = isLast ? "px-4 py-1" : "px-4 py-1";

                      return (
                        <tr key={name}>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {prediction}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {odds}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className={
                                status === "Won"
                                  ? "dark:text-green-700 text-green-900 font-semibold"
                                  : status === "Lost"
                                    ? "dark:text-red-700 text-red-900 font-semibold"
                                    : status === "Not updated yet"
                                      ? "dark:text-yellow-700 text-yellow-900 font-semibold"
                                      : "font-normal opacity-70 dark:text-gray-100 text-black"
                              }
                            >
                              {status || ""}
                            </Typography>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Card>

            <h3 className="font-bold lg:text-lg text-gray-900 dark:text-gray-400 pl-4 pt-6">
              {fixtureFields?.AwayTeam}
            </h3>
            <Card className="h-full rounded-none scroll-hidden shadow-none w-full overflow-scroll lg:overflow-hidden  bg-lightPrimary dark:bg-secondary">
              <table className="w-full min-w-max table-auto text-left  bg-lightPrimary dark:bg-secondary">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" bg-lightPrimary dark:bg-secondary pt-0 px-4 pb-4"
                      >
                        <Typography
                          variant="small"
                          className="font-normal leading-none opacity-70 dark:text-gray-100 text-black"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_ROWS2.map(
                    ({ name, prediction, odds, status }, index) => {
                      const isLast = index === TABLE_ROWS.length - 1;
                      const classes = isLast ? "px-4 py-1" : "px-4 py-1";

                      return (
                        <tr key={name}>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {prediction}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {odds}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className={
                                status === "Won"
                                  ? "dark:text-green-700 text-green-900 font-semibold"
                                  : status === "Lost"
                                    ? "dark:text-red-700 text-red-900 font-semibold"
                                    : status === "Not updated yet"
                                      ? "dark:text-yellow-700 text-yellow-900 font-semibold"
                                      : "font-normal opacity-70 dark:text-gray-100 text-black"
                              }
                            >
                              {status || ""}
                            </Typography>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Card>

            <h3 className="font-bold lg:text-lg text-gray-900 dark:text-gray-400 pl-4 pt-6">
              Total
            </h3>
            <Card className="h-full rounded-none scroll-hidden shadow-none  w-full overflow-scroll lg:overflow-hidden  bg-lightPrimary dark:bg-secondary">
              <table className="w-full min-w-max table-auto text-left  bg-lightPrimary dark:bg-secondary">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" bg-lightPrimary dark:bg-secondary pt-0 px-4 pb-4"
                      >
                        <Typography
                          variant="small"
                          className="font-normal leading-none opacity-70 dark:text-gray-100 text-black"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_ROWSTotal.map(
                    ({ name, prediction, odds, status }, index) => {
                      const isLast = index === TABLE_ROWS.length - 1;
                      const classes = isLast ? "px-4 py-1" : "px-4 py-1";

                      return (
                        <tr key={name}>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {prediction}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {odds}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className={
                                status === "Won"
                                  ? "dark:text-green-700 text-green-900 font-semibold"
                                  : status === "Lost"
                                    ? "dark:text-red-700 text-red-900 font-semibold"
                                    : status === "Not updated yet"
                                      ? "dark:text-yellow-700 text-yellow-900 font-semibold"
                                      : "font-normal opacity-70 dark:text-gray-100 text-black"
                              }
                            >
                              {status || ""}
                            </Typography>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Card>
          </CardBody>
        </Card>
      </DialogBody>
      <DialogFooter>
        <Button
          className="dark:bg-white dark:text-black bg-black text-white"
          onClick={handleOpen}
        >
          <span>Close</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default HandicapAccuracyModal;
