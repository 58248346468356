import { Spinner } from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaRegStar, FaRegWindowMaximize, FaStar } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderImg from "../../assets/images/football-leagues/football_placeholder.png";
import { isEligible } from "../../utils/isEligible";
import { currentDate, currentTime } from "../../utils/timeZone";
import UpdatePlanModal from "../shared/UpdatePlanModal";
import FootballPrediction from "./FootballPrediction";

const FootballCard = ({ item, handleDetailsClick, isOdds }) => {
  const data = item?.fields;
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [predictionOpen, setPredictionOpen] = useState(false);
  const [notEligibleOpen, setNotEligibleOpen] = useState(false);

  useEffect(() => {
    const newStatus =
      data?.Time <= currentTime &&
      data?.Date === currentDate &&
      !data?.MatchResults
        ? "Live"
        : (data?.Date > currentDate ||
            (data?.Time > currentTime && data?.Date === currentDate)) &&
          !data?.MatchResults
        ? "UpComing"
        : "Finished";
    setStatus(newStatus);
    setResult((prev) => ({ ...prev, elapsed: newStatus }));
  }, [data]);

  const [result, setResult] = useState({
    elapsed: status || null,
    status: null,
    goalsHomeTeam: null,
    goalsAwayTeam: null,
    homeTeamWinner: null,
    awayTeamWinner: null,
  });

  const displayResult = async (id) => {
    const options = {
      method: "GET",
      url: "https://api-football-v1.p.rapidapi.com/v3/fixtures",
      params: { id },
      headers: {
        "X-RapidAPI-Key": "b6e89817d6msh36107de73277139p116779jsne307fb015e33",
        "X-RapidAPI-Host": "api-football-v1.p.rapidapi.com",
      },
    };
    try {
      setIsLoading(true);
      const response = await axios.request(options);
      const fixture = response.data.response[0];

      setShow(true);
      setResult({
        elapsed: fixture?.fixture?.status?.elapsed,
        status: fixture?.fixture?.status?.long,
        goalsHomeTeam: fixture?.goals?.home,
        goalsAwayTeam: fixture?.goals?.away,
        homeTeamWinner: fixture?.teams?.home?.winner,
        awayTeamWinner: fixture?.teams?.away?.winner,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handlePredictionClick = async () => {
    const eligibility = isEligible(item);
    if (eligibility) {
      setPredictionOpen(true);
    } else {
      setNotEligibleOpen(true);
    }
  };

  let league = null;
  if (
    data?.LeagueName === "EFL Trophy" ||
    data?.LeagueName === "Superliga" ||
    data?.LeagueName === "Premier League" ||
    data?.LeagueName === "FA Cup" ||
    data?.LeagueName === "Primera Division" ||
    data?.LeagueName === "Serie A" ||
    data?.LeagueName === "La Liga" ||
    data?.LeagueName === "Ligue 1" ||
    data?.LeagueName === "Bundesliga" ||
    data?.LeagueName === "Eredivisie" ||
    data?.LeagueName === "Primeira Liga" ||
    data?.LeagueName === "Jupiler Pro League" ||
    data?.LeagueName === "Allsvenskan" ||
    data?.LeagueName === "Championship" ||
    data?.LeagueName === "Copa del Rey"
  ) {
    league = "Europe League";
  }
  if (
    data?.LeagueName === "Euro Championship" ||
    data?.LeagueName === "UEFA Super Cup" ||
    data?.LeagueName === "UEFA Europa Conference League" ||
    data?.LeagueName === "UEFA Champions League" ||
    data?.LeagueName === "UEFA Europa League"
  ) {
    league = "Champions League";
  }
  if(data?.LeagueName === "Pro League"){league = "Arabian League";}
  return (
    <>
      <div className="flex justify-between items-center mt-6">
        <div className="flex items-center gap-2">
          <div>
            <LazyLoadImage
              width={20}
              height={20}
              src={data?.Tournament_logo}
              alt={data?.LeagueName}
              effect="opacity"
              placeholderSrc={placeholderImg}
            />
          </div>
          <div>
            <p className="text-black dark:text-white text-[13px] font-medium">
              {data?.LeagueName}
            </p>
            <p className="dark:text-gray-200 text-gray-900 text-[9px]">
              {data?.Country}
            </p>
          </div>
        </div>
        <div>
          <p className="font-semibold lg:text-sm text-[9px] dark:text-white text-black">
            {league}
          </p>
        </div>
      </div>

      {/* ------------------------Big devices------------------------  */}

      <div className="hidden lg:block">
        <div className="flex lg:flex-row flex-col lg:justify-between items-center mt-3 hover-bg-black p-3  rounded-lg bg-lightSecondary dark:bg-secondary gap-4 ">
          <div className="flex flex-row items-center">
            <div className="flex items-center gap-0 lg:gap-4">
              {data?.Results === "TRUE" || data?.Results === "FALSE" ? (
                <FaStar color={data?.Results === "FALSE" ? "red" : "green"} />
              ) : (
                <FaRegStar className="text-black dark:text-white" />
              )}
              {result?.elapsed === "UpComing" ? (
                <p
                  className={`text-sm font-semibold px-2 py-[2px] dark:text-white text-black w-20`}
                >
                  {data?.Time}
                </p>
              ) : (
                <p
                  className={`text-sm font-semibold px-2 py-[2px] rounded-md ${
                    result?.elapsed === "Finished"
                      ? "text-orange-600"
                      : "text-red-600"
                  }`}
                >
                  {result?.elapsed
                    ? result?.elapsed === 90
                      ? "Finished"
                      : result?.elapsed
                    : "N/A"}
                </p>
              )}
            </div>
          </div>
          <div className="w-full flex items-center justify-between">
            <div className="justify-center  flex items-center gap-4">
              <p className="font-semibold text-sm text-black dark:text-white w-20 md:w-28 break-words text-right">
                {data?.HomeTeam}
              </p>
              <LazyLoadImage
                width={20}
                height={20}
                src={data?.T1Logo}
                alt={data?.HomeTeam}
                placeholderSrc={placeholderImg}
              />
            </div>

            {/* Middle */}
            <div>
              {isOdds ? (
                <div className=" text-black dark:text-white">
                  <div className="flex justify-center items-center gap-2">
                    <div className="flex flex-col items-center justify-center gap-1">
                      <p className="text-xs">Home</p>
                      <p className="px-3 font-medium text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary rounded-full text-sm">
                        {Number(data?.HomeOdds).toFixed(2)}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-1">
                      <p className="text-xs">Draw</p>
                      <p className="px-3 font-medium text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary rounded-full text-sm">
                        {Number(data?.DrawOdds).toFixed(2)}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-1">
                      <p className="text-xs">Away</p>
                      <p className="px-3 font-medium text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary rounded-full text-sm">
                        {Number(data?.AwayOdds).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" flex flex-col gap-2 justify-center items-center mx-auto">
                  {data.Results ? (
                    <p className="text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary  text-xs font-light px-3.5 py-0.5 rounded-full cursor-pointer">
                      {Number(data?.HomeGoals)} - {Number(data?.AwayGoals)}
                    </p>
                  ) : (
                    <p
                      className="text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary  text-xs font-light px-3.5 py-0.5 rounded-full cursor-pointer"
                      onClick={() => displayResult(data?.MatchID)}
                    >
                      {show ? (
                        <p>
                          {result?.goalsHomeTeam} - {result?.goalsAwayTeam}
                        </p>
                      ) : isLoading ? (
                        <Spinner className="h-4 w-4" />
                      ) : (
                        "Score"
                      )}
                    </p>
                  )}
                  {/* <p className="text-[10px] text-yellow-900 dark:text-yellow-500">
                    {result?.status
                      ? result?.status === "Not Started"
                        ? "Not Started"
                        : result?.status === "Live"
                        ? "Live"
                        : result?.homeTeamWinner === true
                        ? `${data?.HomeTeam} is winner`
                        : result?.awayTeamWinner === true
                        ? `${data?.AwayTeam} is winner`
                        : result?.awayTeamWinner === null
                        ? "Draw"
                        : null
                      : null}
                  </p> */}
                </div>
              )}
            </div>

            <div className="flex items-center  gap-4">
              <LazyLoadImage
                width={20}
                height={20}
                src={data?.T2Logo}
                alt={data?.AwayTeam}
                effect="opacity"
                placeholderSrc={placeholderImg}
              />
              <p className="font-semibold text-sm text-black dark:text-white w-20 md:w-28 break-words text-left">
                {data?.AwayTeam}
              </p>
            </div>
          </div>

          <div className="flex  items-center gap-4">
            <button
              disabled={status === "Finished"}
              className={`bg-lightPrimary dark:bg-primary text-black dark:text-white text-xs px-2 py-0.5 rounded  ${
                status === "Finished" ? "opacity-0" : "flex"
              }`}
              onClick={() => handlePredictionClick(data?.MatchID)}
            >
              Prediction
            </button>
            <button
              title="Show Details"
              onClick={() => handleDetailsClick(data?.MatchID)}
            >
              <FaRegWindowMaximize className="text-black dark:text-white" />
            </button>
          </div>
        </div>
      </div>

      {/* ------------------------Small Devices------------------------  */}

      <div className="block lg:hidden mt-2 px-0 py-4  bg-lightSecondary dark:bg-secondary rounded-xl">
        <div className="  flex items-center relative">
          <div>
            {result?.elapsed === "UpComing" ? (
              <p
                className={`text-[10px] font-medium px-2 py-[2px] dark:text-white text-black w-[58px]`}
              >
                {data?.Time}
              </p>
            ) : (
              <p
                className={`text-[10px] font-medium px-2 py-[2px] rounded-md w-[58px] ${
                  result?.elapsed === "Finished"
                    ? "text-orange-600"
                    : "text-red-600"
                }`}
              >
                {result?.elapsed
                  ? result?.elapsed === 90
                    ? "Finished"
                    : result?.elapsed
                  : "N/A"}
              </p>
            )}
          </div>
          <div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1">
                <LazyLoadImage
                  src={data?.T1Logo}
                  alt={data?.HomeTeam}
                  placeholderSrc={placeholderImg}
                  className="w-4 h-4 object-contain"
                />
                <p className="font-medium text-[10px] text-black dark:text-white">
                  {data?.HomeTeam.length > 10
                    ? `${data?.HomeTeam.slice(0, 10)}..`
                    : data?.HomeTeam}
                </p>
              </div>
              <div className="flex items-center gap-1">
                <LazyLoadImage
                  src={data?.T2Logo}
                  alt={data?.AwayTeam}
                  className="w-4 h-4 object-contain"
                  placeholderSrc={placeholderImg}
                />
                <p className="font-medium text-[10px] text-black dark:text-white">
                  {data?.AwayTeam.length > 10
                    ? `${data?.AwayTeam.slice(0, 10)}..`
                    : data?.AwayTeam}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`flex items-center ${
              isOdds ? `gap-[6px]` : `gap-[18px]`
            } absolute right-2`}
          >
            {isOdds ? (
              <div className=" text-black dark:text-white">
                <div className="flex justify-center items-center gap-2">
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-[8px]">Home</p>
                    <p className=" px-1 text-[9px] font-medium text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary rounded-full">
                      {Number(data?.HomeOdds).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-[8px]">Draw</p>
                    <p className="px-1 text-[9px] font-medium text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary rounded-full">
                      {Number(data?.DrawOdds).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-[8px]">Away</p>
                    <p className="px-1 text-[9px] font-medium text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary rounded-full">
                      {Number(data?.AwayOdds).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" flex flex-col gap-0 justify-center items-center mx-auto">
                {data.Results ? (
                  <p className="text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary  text-[10px] font-light py-0.5 rounded-full cursor-pointer w-10 text-center">
                    {Number(data?.HomeGoals)} - {Number(data?.AwayGoals)}
                  </p>
                ) : (
                  <p
                    className="text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary  text-[10px] font-light  py-0.5 rounded-full cursor-pointer w-10 text-center"
                    onClick={() => displayResult(data?.MatchID)}
                  >
                    {show ? (
                      <p>
                        {result?.goalsHomeTeam} - {result?.goalsAwayTeam}
                      </p>
                    ) : isLoading ? (
                      <Spinner className="h-4 w-4 mx-auto" />
                    ) : (
                      "Score"
                    )}
                  </p>
                )}
                {/* <p className="text-[7px] text-yellow-900 dark:text-yellow-500 ">
                  {result?.status
                    ? result?.status === "Not Started"
                      ? "Not Started"
                      : result?.status === "Live"
                      ? "Live"
                      : result?.homeTeamWinner === true
                      ? `${data?.HomeTeam} is winner`
                      : result?.awayTeamWinner === true
                      ? `${data?.AwayTeam} is winner`
                      : result?.awayTeamWinner === null
                      ? "Draw"
                      : null
                    : null}
                </p> */}
              </div>
            )}
            <div className={`flex  items-center ${isOdds ? `gap-2` : `gap-4`}`}>
              <button
                disabled={status === "Finished"}
                className={`bg-lightPrimary dark:bg-primary  text-black dark:text-white   rounded  ${
                  status === "Finished" ? "opacity-0" : "flex"
                }
                ${
                  isOdds ? `px-2 py-0.5 text-[8px]` : `px-2 py-0.5  text-[10px]`
                }`}
                onClick={() => handlePredictionClick(data?.MatchID)}
              >
                Prediction
              </button>
              <button
                title="Show Details"
                onClick={() => handleDetailsClick(data?.MatchID)}
              >
                <FaRegWindowMaximize className="text-black dark:text-white text-xs" />
              </button>
            </div>
            {data?.Results === "TRUE" || data?.Results === "FALSE" ? (
              <FaStar
                color={data?.Results === "FALSE" ? "red" : "green"}
                className="text-xs"
              />
            ) : (
              <FaRegStar className="text-black dark:text-white text-xs" />
            )}
          </div>
        </div>
      </div>

      <FootballPrediction
        open={predictionOpen}
        handleOpen={() => setPredictionOpen(!predictionOpen)}
        fixture={item}
      />
      <UpdatePlanModal
        open={notEligibleOpen}
        handleOpen={() => setNotEligibleOpen(!notEligibleOpen)}
      />
    </>
  );
};

export default FootballCard;
