import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";

const TABLE_HEAD = ["", "Prediction", "Predicted Odds", "Status"];

const HandicapModal = ({ open, handleOpen, fixture }) => {
  const [possibleOutcome, setPossibleOutcome] = useState(0.0);
  const [wallet, setWallet] = useState();
  const [investmentPercent, setInvestmentPercent] = useState(15);
  const [calcInvestment, setcalCInvestment] = useState();
  const [investment, setInvestment] = useState();


  const fixtureFields = fixture?.fields;



  const invetmentData = [
    fixtureFields?.T1CornerOdds
      ? {
        name: `${fixtureFields?.HomeTeam} (Corner Kicks)`,
        pOdds: fixtureFields.T1CornerOdds,
      }
      : null,
    fixtureFields?.T1GoalUOOdds
      ? {
        name: `${fixtureFields?.HomeTeam} (Goals)`,
        pOdds: fixtureFields.T1GoalUOOdds,
      }
      : null,
    fixtureFields?.T1YellowOdds
      ? {
        name: `${fixtureFields?.HomeTeam} (Yellow Cards)`,
        pOdds: fixtureFields.T1YellowOdds,
      }
      : null,
    fixtureFields?.T2CornerOdds
      ? {
        name: `${fixtureFields?.AwayTeam} (Corner Kicks)`,
        pOdds: fixtureFields.T2CornerOdds,
      }
      : null,
    fixtureFields?.T2GoalUOOdds
      ? {
        name: `${fixtureFields?.AwayTeam} (Goals)`,
        pOdds: fixtureFields.T2GoalUOOdds,
      }
      : null,
    fixtureFields?.T2YellowOdds
      ? {
        name: `${fixtureFields?.AwayTeam} (Yellow Cards)`,
        pOdds: fixtureFields.T2YellowOdds,
      }
      : null,
    fixtureFields?.TCornerOdds
      ? { name: `Total Corner Kicks`, pOdds: fixtureFields.TCornerOdds }
      : null,
    fixtureFields?.TGoalUOOdds
      ? { name: `Total Goals`, pOdds: fixtureFields.TGoalUOOdds }
      : null,
    fixtureFields?.TYellowOdds
      ? { name: `Total Yellow Cards`, pOdds: fixtureFields.TYellowOdds }
      : null,
  ];
  const filteredInvestmentData = invetmentData?.filter((item) => item !== null);

  const defaultSelectedName = `${fixtureFields?.HomeTeam} (Corner Kicks)`;
  const defaultSelectedPercentage = fixtureFields?.T1CornerOdds || null;

  const [selectedValue, setSelectedValue] = useState(defaultSelectedName);
  const [selectedPercentage, setSelectedPercentage] = useState(
    defaultSelectedPercentage
  );

  const handleDropdownChange = (event) => {
    const selectedName = event.target.value;
    const selectedData = filteredInvestmentData?.find(
      (item) => item?.name === selectedName
    );

    if (selectedData) {
      setSelectedValue(selectedName);
      setSelectedPercentage(selectedData?.pOdds);
      // Perform actions with the selected percentage value here
    } else {
      setSelectedValue("");
      setSelectedPercentage(null);
    }
  };

  useEffect(() => {
    // Set default values when the component mounts
    setSelectedValue(defaultSelectedName);
    setSelectedPercentage(defaultSelectedPercentage);
  }, [defaultSelectedName, defaultSelectedPercentage]);

  useEffect(() => {
    setcalCInvestment(wallet * investmentPercent / 100);
    if (investment) {
      setPossibleOutcome(investment * selectedPercentage);
    } else {
      setPossibleOutcome(calcInvestment * selectedPercentage);
    }
  }, [wallet, investmentPercent, investment, selectedPercentage, calcInvestment]);

  const TABLE_ROWS = fixtureFields
    ? [
      {
        name: "Corner Kicks",
        prediction: fixtureFields.T1CornerPredict1,
        odds: fixtureFields.T1CornerOdds ? fixtureFields.T1CornerOdds : null,
        status: fixtureFields.T1CornerPredict
          ? fixtureFields.T1CornerResult
            ? fixtureFields.T1CornerResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
      {
        name: "Goals",
        prediction: fixtureFields.T1GoalUOPredict
          ? fixtureFields.T1GoalUOPredict < 0
            ? `Under ${Math.abs(fixtureFields.T1GoalUOPredict)}`
            : `Over ${Math.abs(fixtureFields.T1GoalUOPredict)}`
          : null,
        odds: fixtureFields.T1GoalUOOdds ? fixtureFields.T1GoalUOOdds : null,
        status: fixtureFields.T1GoalUOPredict
          ? fixtureFields.HomeGoals
            ? fixtureFields.T1GoalUOPredict < 0
              ? (Number(fixtureFields.HomeGoals)) < Math.abs(fixtureFields.T1GoalUOPredict)
                ? 'Won'
                : 'Lost'
              : (Number(fixtureFields.HomeGoals)) > Math.abs(fixtureFields.T1GoalUOPredict)
                ? "Won"
                : 'Lost'
            : 'Not updated yet'
          : null,
      },
      {
        name: "Yellow Cards",
        prediction: fixtureFields.T1YellowPredict,
        odds: fixtureFields.T1YellowOdds ? fixtureFields.T1YellowOdds : null,
        status: fixtureFields.T1YellowPredict
          ? fixtureFields.T1YellowResult
            ? fixtureFields.T1YellowResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
    ]
    : [];

  const TABLE_ROWS2 = fixtureFields
    ? [
      {
        name: "Corner Kicks",
        prediction: fixtureFields.T2CornerPredict1,
        odds: fixtureFields.T2CornerOdds ? fixtureFields.T2CornerOdds : null,
        status: fixtureFields.T2CornerPredict
          ? fixtureFields.T2CornerResult
            ? fixtureFields.T2CornerResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
      {
        name: "Goals",
        prediction: fixtureFields.T2GoalUOPredict
          ? fixtureFields.T2GoalUOPredict < 0
            ? `Under ${Math.abs(fixtureFields.T2GoalUOPredict)}`
            : `Over ${Math.abs(fixtureFields.T2GoalUOPredict)}`
          : null,
        odds: fixtureFields.T2GoalUOOdds ? fixtureFields.T2GoalUOOdds : null,
        status: fixtureFields.T2GoalUOPredict
          ? fixtureFields.AwayGoals
            ? fixtureFields.T2GoalUOPredict < 0
              ? (Number(fixtureFields.AwayGoals)) < Math.abs(fixtureFields.T2GoalUOPredict)
                ? 'Won'
                : 'Lost'
              : (Number(fixtureFields.AwayGoals)) > Math.abs(fixtureFields.T2GoalUOPredict)
                ? "Won"
                : 'Lost'
            : 'Not updated yet'
          : null,
      },
      {
        name: "Yellow Cards",
        prediction: fixtureFields.T2YellowPredict,
        odds: fixtureFields.T2YellowOdds ? fixtureFields.T2YellowOdds : null,
        status: fixtureFields.T2YellowPredict
          ? fixtureFields.T2YellowResult
            ? fixtureFields.T2YellowResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
    ]
    : [];

  const TABLE_ROWSTotal = fixtureFields
    ? [
      {
        name: "Corner Kicks",
        prediction: fixtureFields.TCornerPredict1,
        odds: fixtureFields.TCornerOdds ? fixtureFields.TCornerOdds : null,
        status: fixtureFields.TCornerPredict
          ? fixtureFields.TCornerResult
            ? fixtureFields.TCornerResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
      {
        name: "Goals",
        prediction: fixtureFields.TGoalUOPredict
          ? fixtureFields.TGoalUOPredict < 0
            ? `Under ${Math.abs(fixtureFields.TGoalUOPredict)}`
            : `Over ${Math.abs(fixtureFields.TGoalUOPredict)}`
          : null,
        odds: fixtureFields.TGoalUOOdds ? fixtureFields.TGoalUOOdds : null,
        status: fixtureFields.TGoalUOPredict
          ? fixtureFields.AwayGoals && fixtureFields.HomeGoals
            ? fixtureFields.TGoalUOPredict < 0
              ? (Number(fixtureFields.AwayGoals) + Number(fixtureFields.HomeGoals)) < Math.abs(fixtureFields.TGoalUOPredict)
                ? 'Won'
                : 'Lost'
              : (Number(fixtureFields.AwayGoals) + Number(fixtureFields.HomeGoals)) > Math.abs(fixtureFields.TGoalUOPredict)
                ? "Won"
                : 'Lost'
            : 'Not updated yet'
          : null,
      },
      {
        name: "Yellow Cards",
        prediction: fixtureFields.TYellowPredict,
        odds: fixtureFields.TYellowOdds ? fixtureFields.TYellowOdds : null,
        status: fixtureFields.TYellowPredict
          ? fixtureFields.TYellowResult
            ? fixtureFields.TYellowResult === "True"
              ? "Won"
              : "Lost"
            : "Not updated yet"
          : null,
      },
    ]
    : [];

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className="bg-lightSecondary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="lg"

    >
      <DialogHeader className="text-black dark:text-white text-center">
        Our Prediction
      </DialogHeader>
      <DialogBody>
        {/* Top */}
        <Card className="px-2 py-6 shadow bg-lightPrimary dark:bg-secondary rounded-xl">
          <div className="flex items-center justify-between gap-4 lg:gap-10">
            <div className="flex-1 flex flex-col items-center gap-2 lg:gap-5 justify-start">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={fixtureFields?.T1Logo}
                alt={`${fixtureFields?.HomeTeam} logo`}
              />
              <p className="font-bold text-[8px] lg:text-lg text-center">
                {fixtureFields?.HomeTeam}
              </p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <h4 className="text-xl lg:text-4xl text-black dark:text-white mb-2 lg:mb-4">
                {fixtureFields?.Time}
              </h4>

              <div className="flex items-center justify-center gap-1 lg:gap-2">
                <p className="dark:text-white bg-lightSecondary text-black dark:bg-primary lg:px-3 px-2 text-xs lg:text-md py-[2px] rounded-full">
                  {fixtureFields?.HomeOdds || ""}
                </p>
                <p className="dark:text-white bg-lightSecondary text-black dark:bg-primary lg:px-3 px-2 text-xs lg:text-md py-[2px] rounded-full">
                  {fixtureFields?.DrawOdds || ""}
                </p>
                <p className="dark:text-white bg-lightSecondary text-black dark:bg-primary lg:px-3 px-2 text-xs lg:text-md py-[2px] rounded-full">
                  {fixtureFields?.AwayOdds || ""}
                </p>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-center gap-2 lg:gap-5 justify-end">
              <img
                className="lg:w-14 w-8 h-8 lg:h-14"
                src={fixtureFields?.T2Logo}
                alt={`${fixtureFields?.AwayTeam} logo`}
              />
              <p className="font-bold text-[8px] lg:text-lg text-center">
                {fixtureFields?.AwayTeam}
              </p>
            </div>
          </div>
        </Card>

        <Card className="bg-lightPrimary dark:bg-secondary rounded-xl overflow-hidden mt-4">
          <CardBody className=" px-2 py-6">
            {/* first team */}
            <h3 className="font-bold lg:text-lg text-gray-900 dark:text-gray-400 pl-4">
              {fixtureFields?.HomeTeam}
            </h3>
            <Card className="h-full w-full shadow-none rounded-none overflow-x-scroll scroll-hidden lg:overflow-hidden  bg-lightPrimary dark:bg-secondary">
              <table className="w-full min-w-max table-auto text-left  bg-lightPrimary dark:bg-secondary">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" bg-lightPrimary dark:bg-secondary pt-0 px-4 pb-4"
                      >
                        <Typography
                          variant="small"
                          className="font-normal leading-none opacity-70 dark:text-gray-100 text-black"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_ROWS.map(
                    ({ name, prediction, odds, status }, index) => {
                      const isLast = index === TABLE_ROWS.length - 1;
                      const classes = isLast ? "px-4 py-1" : "px-4 py-1";

                      return (
                        <tr key={name}>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {prediction || ""}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {odds ? Number(odds).toFixed(2) : ""}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className={
                                status === "Won"
                                  ? "dark:text-green-700 text-green-900 font-semibold"
                                  : status === "Lost"
                                    ? "dark:text-red-700 text-red-900 font-semibold"
                                    : status === "Not updated yet"
                                      ? "dark:text-yellow-700 text-yellow-900 font-semibold"
                                      : "font-normal opacity-70 dark:text-gray-100 text-black"
                              }
                            >
                              {status || ""}
                            </Typography>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Card>

            <h3 className="font-bold lg:text-lg text-gray-900 dark:text-gray-400 pl-4 pt-6">
              {fixtureFields?.AwayTeam}
            </h3>
            <Card className="h-full rounded-none scroll-hidden shadow-none w-full overflow-scroll lg:overflow-hidden  bg-lightPrimary dark:bg-secondary">
              <table className="w-full min-w-max table-auto text-left  bg-lightPrimary dark:bg-secondary">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" bg-lightPrimary dark:bg-secondary pt-0 px-4 pb-4"
                      >
                        <Typography
                          variant="small"
                          className="font-normal leading-none opacity-70 dark:text-gray-100 text-black"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_ROWS2.map(
                    ({ name, prediction, odds, status }, index) => {
                      const isLast = index === TABLE_ROWS.length - 1;
                      const classes = isLast ? "px-4 py-1" : "px-4 py-1";

                      return (
                        <tr key={name}>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {prediction || ""}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {odds ? Number(odds).toFixed(2) : ""}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className={
                                status === "Won"
                                  ? "dark:text-green-700 text-green-900 font-semibold"
                                  : status === "Lost"
                                    ? "dark:text-red-700 text-red-900 font-semibold"
                                    : status === "Not updated yet"
                                      ? "dark:text-yellow-700 text-yellow-900 font-semibold"
                                      : "font-normal opacity-70 dark:text-gray-100 text-black"
                              }
                            >
                              {status || ""}
                            </Typography>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Card>

            <h3 className="font-bold lg:text-lg text-gray-900 dark:text-gray-400 pl-4 pt-6">
              Total
            </h3>
            <Card className="h-full rounded-none scroll-hidden shadow-none  w-full overflow-scroll lg:overflow-hidden  bg-lightPrimary dark:bg-secondary">
              <table className="w-full min-w-max table-auto text-left  bg-lightPrimary dark:bg-secondary">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className=" bg-lightPrimary dark:bg-secondary pt-0 px-4 pb-4"
                      >
                        <Typography
                          variant="small"
                          className="font-normal leading-none opacity-70 dark:text-gray-100 text-black"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {TABLE_ROWSTotal.map(
                    ({ name, prediction, odds, status }, index) => {
                      const isLast = index === TABLE_ROWS.length - 1;
                      const classes = isLast ? "px-4 py-1" : "px-4 py-1";

                      return (
                        <tr key={name}>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {name || ""}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {prediction || ""}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 dark:text-gray-100 text-black"
                            >
                              {odds ? Number(odds).toFixed(2) : ""}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              className={
                                status === "Won"
                                  ? "dark:text-green-700 text-green-900 font-semibold"
                                  : status === "Lost"
                                    ? "dark:text-red-700 text-red-900 font-semibold"
                                    : status === "Not updated yet"
                                      ? "dark:text-yellow-700 text-yellow-900 font-semibold"
                                      : "font-normal opacity-70 dark:text-gray-100 text-black"
                              }
                            >
                              {status || ""}
                            </Typography>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Card>
          </CardBody>
        </Card>

        <div className="card mt-4 px-8 py-6 shadow bg-white dark:bg-secondary rounded-xl">
          <h4 className="text-lg lg:text-md text-center text-black dark:text-white">
            We recommend you to invest{" "}
            <span className="text-2xl font-bold dark:text-yellow-700 text-yellow-900">{" "}{fixtureFields?.Investment_percentage
              ? `${fixtureFields?.Investment_percentage}%`
              : "15%"}{" "} </span>
            of your wallet
          </h4>
        </div>

        {/* 3rd Card */}
        <div className="card mt-4 px-4 py-6 shadow bg-lightPrimary dark:bg-secondary rounded-xl">
          <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-0">
            <select
              value={selectedValue}
              onChange={handleDropdownChange}
              className="p-2 rounded-md bg-transparent border border-solid dark:text-white text-black"
            >
              {filteredInvestmentData?.map((item, index) => (
                <option className="text-black" key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
            {selectedPercentage !== null && (
              <p className="dark:text-white text-black text-lg">
                Predicted Odds : {selectedPercentage}
              </p>
            )}
          </div>

          <div className="flex flex-row items-start md:items-center justify-between mt-6">
            <div className="flex flex-col lg:flex-row gap-2 items-center">
              <p className="dark:text-gray-400 text-gray-900 text-xs md:text-base">
                Your wallet:
              </p>
              <div>
                <input
                  type="text"
                  className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
                  onChange={(e) => {
                    setWallet(e?.target?.value);
                  }}
                  value={wallet}
                />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-2 items-center">
              <p className="dark:text-gray-400 text-gray-900 text-xs md:text-base">
                Recommended {investmentPercent}%:
              </p>
              <div>
                <input
                  type="text"
                  className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
                  onChange={(e) => {
                    setInvestmentPercent(e?.target?.value);
                  }}
                  value={calcInvestment ? calcInvestment : null}
                  readOnly
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-2 items-center">
              <p className="dark:text-gray-400 text-gray-900 text-xs md:text-base">
                Investment:
              </p>
              <div>
                <input
                  type="text"
                  className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
                  placeholder={calcInvestment ? calcInvestment : null}
                  onChange={(e) => {
                    setInvestment(e?.target?.value);
                  }}
                  value={investment}
                />
              </div>
            </div>
          </div>

          <div className="w-full bg-gray-500 h-[1px] my-6"></div>

          <div className="flex items-end justify-end text-right">
            {/* <span className="bg-green-800 text-white rounded-md px-2 py-1">
              Get RETURN
            </span> */}

            <div className="flex flex-col items-end">
              <p className="dark:text-white text-black">Possible Outcome: </p>
              <p className="dark:text-white text-black text-lg">
                {possibleOutcome ? Number(possibleOutcome).toFixed(2) : 0}
              </p>
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          className="dark:bg-white dark:text-black bg-black text-white"
          onClick={handleOpen}
        >
          <span>Close</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default HandicapModal;
