import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { fetcher } from "../../utils/authFetch";

const ExistingUserNotice = ({ open, handler }) => {
  const navigate = useNavigate();
  const email = sessionStorage.getItem("email");
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async () => {
    setIsLoading(true);
    try {
      const res = await fetcher.post("/api/auth/forgot-password", { email });
      toast.success(res?.data?.message || "Check your email for OTP");
      navigate(`/forgot-password-otp`);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
      handler();
    }
  };
  return (
    <Dialog open={open} onClose={handler} size="sm" className="px-4 pb-4">
      <DialogHeader className="justify-between">
        <h2>Welcome Back!</h2>
        <IconButton
          color="blue-gray"
          variant="text"
          onClick={handler}
          className="rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody className="py-6">
        <p className="text-lg">
          We developed our site, and our new package. As an existence user,
          please <span className="text-red-500">Reset</span> your password.
        </p>
      </DialogBody>

      <Button
        color="blue"
        onClick={handleResetPassword}
        ripple
        fullWidth
        className="mt-4 capitalize text-lg tracking-wider"
      >
        {isLoading ? "Resetting..." : "Reset Password"}
      </Button>
    </Dialog>
  );
};

export default ExistingUserNotice;
