import { Card, CardBody } from "@material-tailwind/react";
import React from "react";
import { MdDoubleArrow } from "react-icons/md";

const guideData = [
  {
    title: "Daily Game Selection",
    description:
      "We meticulously analyze all available games daily, subjecting them to our advanced AI funnel. This process calculates the risk for each game, selecting only those with the optimal percentage chance of winning and favorable odds.",
  },
  {
    title: "Strategic Betting",
    description:
      "Allocate 15% of your betting account balance to the selected game. This conservative approach helps manage risk while maximizing potential returns.",
  },
  {
    title: "Sequential Betting",
    description:
      "If you decide to place bets on more than one game simultaneously, deduct the previous game's amount (current balance) before placing 15% on the next game. This ensures a disciplined and strategic betting pattern.",
  },
  {
    title: "Post-Game Adjustment",
    description:
      "If you prefer to wait until the conclusion of the games, use your new balance to calculate the 15% for subsequent bets. This allows you to adjust your strategy based on your ongoing success.",
  },
  {
    title: "Utilize Our Tool for Precision",
    description:
      "Take advantage of our user-friendly tool by inputting your balance or wallet amount. The tool will guide you on the optimal amount to place, enhancing your decision-making process.",
  },
];

const UserGuide = () => {
  return (
    <div className="">
      <Card className="dark:bg-primary bg-lightPrimary h-[calc(100vh-162px)] py-0 overflow-y-scroll scroll-hidden">
        <CardBody>
          <h2 className="text-2xl font-medium dark:text-white text-blue-900 mb-4">
            User Guide
          </h2>

          <div className="text-black dark:text-white">
            <p>
              At Better Return, we believe in a prudent and calculated approach
              to sports betting, emphasizing the importance of never going all
              in. Our strategy is grounded in data-driven decision-making,
              leveraging the power of our AI funnel to assess the risk and
              identify the most favorable opportunities each day.
            </p>

            <p className="mt-8">
              Here's step-by-step guide on how to make the most of Bet:tter
              Return:
            </p>
          </div>

          <div className="mt-4 mb-12">
            {guideData.map((d, index) => {
              return (
                <div key={index} className="mb-4">
                  <div className="dark:text-white text-black flex items-start ">
                    <MdDoubleArrow className="basis-1/12 mt-1" />

                    <p className="basis-11/12">
                      <strong>{d?.title}:</strong> {d.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <p className="text-black dark:text-white">
            Remember, success in sports betting is a marathon, not a sprint. By
            following our strategic approach and incorporating data-backed
            insights, you're not only safeguarding your funds but also
            increasing your chances of achieving better returns over the long
            term.
          </p>
        </CardBody>
      </Card>
    </div>
  );
};

export default UserGuide;
