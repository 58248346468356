import { Button, Card, CardBody } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";
import img from "../../assets/images/dashboard/error_404.png";

const AdminError = () => {
  return (
    <div>
      <Card className="bg-[#f2f6fc] h-[calc(100vh-13.3vh)]">
        <CardBody className="my-auto">
          <div className="flex 2xl:flex-row flex-col justify-evenly items-center">
            <div>
              <img src={img} alt="error" />
            </div>
            <div>
              <p className="text-red-500 font-bold text-3xl lg:text-4xl">
                Oops! Page Not Found
              </p>
              <Link to="/">
                <Button fullWidth className="capitalize bg-green-500 mt-8">
                  back to home
                </Button>
              </Link>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default AdminError;
