import { useEffect, useState } from "react";
import {
  FaAngleRight,
  FaRegStar,
  FaRegWindowMaximize,
  FaStar,
} from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import placeholderImg from "../../assets/images/csgo/csgo_placeholder.png";
import { isEligible } from "../../utils/isEligible";
import { currentDate, currentTime } from "../../utils/timeZone";
import UpdatePlanModal from "../shared/UpdatePlanModal";
import CsgoPrediction from "./CsgoPrediction";

const CsgoCard = ({ item, isOdds }) => {
  const data = item?.fields;
  const navigate = useNavigate();
  const [predictionOpen, setPredictionOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [notEligibleOpen, setNotEligibleOpen] = useState(false);

  useEffect(() => {
    const newStatus =
      data?.Time <= currentTime && data?.Date === currentDate && !data?.Results
        ? "Live"
        : (data?.Date > currentDate ||
            (data?.Time > currentTime && data?.Date === currentDate)) &&
          !data?.Results
        ? "UpComing"
        : "Finished";
    setStatus(newStatus);
  }, [data]);

  const handlePredictionClick = async () => {
    const eligibility = await isEligible(item);
    if (eligibility) {
      setPredictionOpen(true);
    } else {
      setNotEligibleOpen(true);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mt-8">
        <div className="flex items-center gap-2">
          <div>
            <LazyLoadImage
              width={20}
              height={20}
              effect="opacity"
              placeholderSrc={placeholderImg}
              src={data?.Tournament_logo || placeholderImg}
              alt="tournament"
            />
          </div>
          <div>
            <p className="text-black dark:text-white text-[13px] font-medium">
              {data?.Event}
            </p>
          </div>
        </div>
        <div>
          <FaAngleRight className="text-black dark:text-white" />
        </div>
      </div>

      {/* --------------------------------Big devices--------------------------------  */}

      <div className="lg:block hidden">
        <div className="flex lg:flex-row flex-col lg:justify-between items-center mt-3 hover-bg-black p-3  rounded-lg bg-lightSecondary dark:bg-secondary gap-8">
          <div className="flex items-center gap-4">
            <div className="flex flex-row items-center gap-4">
              {data["True/false"] === "true" ||
              data["True/false"] === "false" ? (
                <FaStar
                  color={data["True/false"] === "false" ? "red" : "green"}
                />
              ) : (
                <FaRegStar className="text-black dark:text-white" />
              )}
              {status === "UpComing" ? (
                <p
                  className={`text-sm font-semibold px-2 py-[2px] w-[60px] dark:text-white text-black`}
                >
                  {data?.Time}
                </p>
              ) : (
                <p
                  className={`text-xs font-semibold px-1 py-[2px] w-[60px] rounded-md ${
                    status === "Finished" ? "text-orange-600" : "text-red-600 "
                  }`}
                >
                  {status || "N/A"}
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-3 w-full items-center justify-between">
            <div className="">
              <div className="flex items-center gap-4">
                <p className="font-semibold text-right text-sm dark:text-white text-black w-28">
                  {data?.Team1}
                </p>
                <LazyLoadImage
                  width={20}
                  height={20}
                  effect="opacity"
                  placeholderSrc={placeholderImg}
                  src={data?.T1Logo || placeholderImg}
                  alt="logo"
                />
              </div>
            </div>

            {/* Middle */}
            {isOdds ? (
              <div className="">
                <div className="flex  justify-center items-center gap-4">
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-xs text-black dark:text-white">Team 1</p>
                    <p className="px-3 font-medium text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary rounded-full">
                      {Number(data["Best-odds-1"]).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-xs text-black dark:text-white">Team 2</p>
                    <p className="px-3 font-medium text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary rounded-full">
                      {Number(data["Best-odds-2"]).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex-1">
                <div className="flex flex-col justify-center items-center">
                  {data?.Date >= currentDate && !data?.Results ? (
                    <p className="text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary text-xs font-light px-3.5 py-0.5 rounded-full cursor-pointer">
                      Score
                    </p>
                  ) : (
                    <>
                      <p className="bg-lightPrimary text-black dark:text-orange-500 dark:bg-primary text-xs font-light px-3.5 py-0.5 rounded-full">
                        {data?.Team1Score} - {data?.Team2Score}
                      </p>
                      <p className="dark:text-yellow-500 text-center text-yellow-900 text-xs mt-2">
                        Winner is {data[`Team${data?.Results}`]}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="flex  justify-end">
              <div className="flex items-center gap-4">
                <LazyLoadImage
                  width={20}
                  height={20}
                  effect="opacity"
                  placeholderSrc={placeholderImg}
                  src={data?.T2Logo || placeholderImg}
                  alt="logo"
                />
                <p className="font-semibold text-left text-sm text-black dark:text-white w-28">
                  {data?.Team2}
                </p>
              </div>
            </div>
          </div>
          <div className="flex  items-center gap-4">
            <button
              disabled={status === "Finished"}
              className={`text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary text-xs px-2 py-0.5 rounded  ${
                status === "Finished" ? "static opacity-0" : "flex"
              }`}
              onClick={handlePredictionClick}
            >
              Prediction
            </button>
            <button onClick={() => navigate(`/csgo/${item?.id}`)}>
              <FaRegWindowMaximize className="text-black dark:text-white" />
            </button>
          </div>
        </div>
      </div>

      {/* --------------------------------Small devices--------------------------------  */}

      <div className="block lg:hidden mt-2 px-0 py-4  bg-lightSecondary dark:bg-secondary rounded-xl">
        <div className="flex items-center relative">
          <div>
            {status === "UpComing" ? (
              <p
                className={`text-[10px] font-semibold px-2 py-[2px] w-[58px] dark:text-white text-black`}
              >
                {data?.Time}
              </p>
            ) : (
              <p
                className={`text-[10px] font-semibold px-1 py-[2px] w-[58px] rounded-md ${
                  status === "Finished" ? "text-orange-600" : "text-red-600 "
                }`}
              >
                {status || "N/A"}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-1">
              <LazyLoadImage
                className="w-4 h-4 object-contain"
                effect="opacity"
                placeholderSrc={placeholderImg}
                src={data?.T1Logo || placeholderImg}
                alt="logo"
              />
              <p className="font-medium text-[10px]  dark:text-white text-black">
                {data?.Team1.length > 10
                  ? `${data?.Team1.slice(0, 10)}..`
                  : data?.Team1}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <LazyLoadImage
                className="w-4 h-4 object-contain"
                effect="opacity"
                placeholderSrc={placeholderImg}
                src={data?.T2Logo || placeholderImg}
                alt="logo"
              />
              <p className="font-medium text-[10px]  text-black dark:text-white">
                {data?.Team2.length > 10
                  ? `${data?.Team2.slice(0, 10)}..`
                  : data?.Team2}
              </p>
            </div>
          </div>
          <div className={`flex items-center ${isOdds ? `gap-[6px]` : `gap-[18px]`} absolute right-2`}>
              {/* Middle */}
              {isOdds ? (
              <div className="">
                <div className="flex  justify-center items-center gap-2">
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-[8px] text-black dark:text-white">Team 1</p>
                    <p className="px-1 text-[9px] font-medium text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary rounded-full">
                      {Number(data["Best-odds-1"]).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-1">
                    <p className="text-[8px] text-black dark:text-white">Team 2</p>
                    <p className="px-1 text-[9px] font-medium text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary rounded-full">
                      {Number(data["Best-odds-2"]).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex-1">
                <div className="flex flex-col justify-center items-center">
                  {data?.Date >= currentDate && !data?.Results ? (
                    <p className="text-black dark:text-orange-500 bg-lightPrimary dark:bg-primary text-[10px] px-2 font-light py-0.5 rounded-full cursor-pointer">
                      Score
                    </p>
                  ) : (
                    <>
                      <p className="bg-lightPrimary text-black dark:text-orange-500 dark:bg-primary text-[10px] font-light px-3.5 py-0.5 rounded-full">
                        {data?.Team1Score} - {data?.Team2Score}
                      </p>
                      <p className="dark:text-yellow-500 text-yellow-900 text-[8px] mt-2 w-16 text-center">
                        Winner is {data[`Team${data?.Results}`]}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className={`flex  items-center ${isOdds ? `gap-2` : `gap-4`}`}>
            <button
              disabled={status === "Finished"}
              className={`bg-lightPrimary dark:bg-primary  text-black dark:text-white   rounded  ${
                status === "Finished" ? "opacity-0" : "flex"
              }
              ${isOdds ? `px-2 py-0.5 text-[8px]` : `px-2 py-0.5  text-[10px]`}`}
              onClick={handlePredictionClick}
            >
              Prediction
            </button>
            <button onClick={() => navigate(`/csgo/${item?.id}`)}>
              <FaRegWindowMaximize className="text-black dark:text-white text-xs" />
            </button>
          </div>
          <div>
          {data["True/false"] === "true" ||
              data["True/false"] === "false" ? (
                <FaStar
                  color={data["True/false"] === "false" ? "red" : "green"}
                  className="text-xs"
                />
              ) : (
                <FaRegStar className="text-black dark:text-white text-xs" />
              )}
          </div>
          </div>
        </div>
      </div>

      <CsgoPrediction
        open={predictionOpen}
        handleOpen={() => setPredictionOpen(!predictionOpen)}
        data={item}
      />

      <UpdatePlanModal
        open={notEligibleOpen}
        handleOpen={() => setNotEligibleOpen(!notEligibleOpen)}
      ></UpdatePlanModal>
    </>
  );
};

export default CsgoCard;
