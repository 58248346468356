import React from "react";
import { Avatar, Typography } from "@material-tailwind/react";
import Loading from "../shared/Loading/Loading";
import { imgUrl } from "../../utils/img";
import { FaStar } from "react-icons/fa";
import { sliceText } from "../../utils/sliceText";

const TABLE_HEAD = ["Date and Time", "Teams", "Score", "League", "We Predict"];

const GameAccuracyTable = ({
  isLoading,
  data = [],
  page = 1,
  limit = 10,
  total = 0,
  selected,
}) => {
  return (
    <div>
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
              >
                <Typography
                  variant="small"
                  className="font-normal leading-none text-black"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={5}>
                <Loading className="h-96" />
              </td>
            </tr>
          ) : (
            <>
              {data
                ?.slice((page - 1) * limit, limit * page)
                ?.map((d, index) => {
                  const isLast = index === total - 1;
                  const classes = isLast ? "p-4" : "p-4";
                  const fieldValue = d.fields["True/false"];
                  const color =
                    d.fields.Results === "TRUE" ||
                    fieldValue === "true" ||
                    d.fields.TrueFalse === "True"
                      ? "text-green-600"
                      : "text-red-600";
                  const divBgColor =
                    d.fields.Results === "TRUE" ||
                    fieldValue === "true" ||
                    d.fields.TrueFalse === "True"
                      ? " bg-green-600 w-[2px] h-10"
                      : "bg-red-600 w-[2px] h-10";

                  return (
                    <tr
                      key={index + 1}
                      className="dark:even:bg-secondary even:bg-lightSecondary"
                    >
                      {/* 1st Column */}
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className={divBgColor}></div>
                          <FaStar className={color} />
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              className="font-normal text-black dark:text-white"
                            >
                              {d?.fields.Date}
                            </Typography>
                            <Typography
                              variant="small"
                              className="font-normal opacity-70 text-black dark:text-white"
                            >
                              {d?.fields.Time}
                            </Typography>
                          </div>
                        </div>
                      </td>

                      {/* 2nd Column */}
                      <td className={classes}>
                        <div className="flex items-center gap-1">
                          {d?.fields?.T1Logo && (
                            <div className="flex items-center -space-x-4">
                              <Avatar
                                variant="circular"
                                alt="user 1"
                                className="border object-contain h-full w-9 bg-white border-gray-300 hover:z-10 focus:z-10"
                                src={imgUrl(d?.fields?.T1Logo)}
                              />
                              <Avatar
                                variant="circular"
                                alt="user 2"
                                className="border object-contain h-full w-9 bg-white border-gray-300 hover:z-10 focus:z-10"
                                src={imgUrl(d?.fields?.T2Logo)}
                              />
                            </div>
                          )}

                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              className="font-normal text-black dark:text-white"
                            >
                              {d?.fields.Matches ||
                                `${d?.fields.Team1} vs ${d?.fields.Team2}`}
                            </Typography>
                          </div>
                        </div>
                      </td>

                      {/* 3rd Column */}
                      <td className={classes}>
                        <Typography className="text-black dark:text-white">
                          {Number(d?.fields.HomeGoals) === 0.0 ||
                          d?.fields?.Team1Score === 0
                            ? "0"
                            : Number(d?.fields.HomeGoals) ||
                              d?.fields?.Team1Score}{" "}
                          -{" "}
                          {Number(d?.fields.AwayGoals) === 0.0 ||
                          d?.fields?.Team2Score === 0
                            ? "0"
                            : Number(d?.fields.AwayGoals) ||
                              d?.fields?.Team2Score}
                        </Typography>
                      </td>

                      {/* 4th Column */}
                      <td className={classes}>
                        <Typography
                          title={d?.fields.LeagueName || d?.fields.Event}
                          className="text-black dark:text-white cursor-pointer"
                        >
                          {d?.fields.LeagueName ||
                            (d?.fields?.Event && d?.fields?.Event.length > 18
                              ? `${d?.fields?.Event.slice(0, 18)}..`
                              : d?.fields?.Event)}
                        </Typography>
                      </td>


                      {/* 5th Column */}
                      <td className={classes}>
                        <Typography className={`${color} font-semibold`}>
                          {sliceText(
                            selected === "soccer"
                              ? d?.fields?.Prediction || ""
                              : d?.fields[`Team${d?.fields?.Prediction}`] || "",
                            20
                          )}
                        </Typography>
                      </td>
                    </tr>
                  );
                })}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default GameAccuracyTable;
