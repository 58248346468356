import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import AdminSideBar from "../components/admin/AdminSideBar";
import Cookies from "js-cookie";
import { useSingleUser } from "../hooks/useUser";
import Loading from "../components/shared/Loading/Loading";
import toast from "react-hot-toast";

const AdminLayout = () => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  const { data, isSuccess } = useSingleUser({
    access_token: token,
  });

  useEffect(() => {
    if (!token || !user) {
      navigate("/login");
    }

    if (user?.fields?.Role !== "admin" && !user?.fields?.Is_staff_member) {
      toast.error("You are not authorized to access this page");
      navigate("/");
    }
    
    if (isSuccess) {
      Cookies.set("user", JSON.stringify(data?.data), { expires: 7 });
      setIsLoading(false);
    }
  }, [isSuccess, data, token, user, navigate]);

  return (
    <>
      {isLoading ? (
        <Loading className=" h-screen bg-secondary" />
      ) : (
        <>
          <AdminHeader open={open} setOpen={setOpen} />
          <div className="flex">
            <AdminSideBar open={open} />
            <div className="bg-gradient-to-r from-[#cdece8] via-[#f3e1d3] to-[#e1e2ee] w-full p-3 md:p-6 h-[calc(100vh-4.5rem)] overflow-y-scroll scroll-hidden">
              <Outlet />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminLayout;
