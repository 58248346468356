import { Button, Dialog, DialogBody } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import upgradeImg from "../../assets/images/upgrade.png";

const UpdatePlanModal = ({ open, handleOpen: handler }) => {
  const navigate = useNavigate();
  const handleUpgrade = () => {
    handler();
    navigate("/pricing");
  };
  return (
    <Dialog
      open={open}
      handler={handler}
      className="bg-lightPrimary dark:bg-primary max-h-[90vh] overflow-auto scroll-hidden"
      size="sm"
    >
      <DialogBody className="text-black dark:text-white p-6">
      <img src={upgradeImg} alt="upgrade" className="w-72 mx-auto" />

        <h2 className="text-center text-3xl font-bold my-6">
          Upgrade your plan!
        </h2>

        <p className="text-primary dark:text-lightPrimary text-lg text-center">
          Please upgrade your plan to get access for this prediction.
        </p>

        <Button
          fullWidth
          className="w-full mt-12 bg-blue-900 tracking-wider"
          onClick={handleUpgrade}
        >
          Upgrade Now
        </Button>
      </DialogBody>
    </Dialog>
  );
};

export default UpdatePlanModal;
