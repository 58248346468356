import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";
import { currentTime, userTimeZone } from "../utils/timeZone";

export const useAllHandicapData = ({ date, skip, finished }) => {
  return useQuery({
    queryKey: ["handicap", date],
    queryFn: async () => {
      const response = await fetcher.get("/api/handicap", {
        params: {
          date,
          finished: finished ? finished : undefined,
          time: currentTime,
          timeZone: userTimeZone,
        },
      });
      return response.data;
    },
    enabled: !skip,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};
