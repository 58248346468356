import { Spinner } from "@material-tailwind/react";
import "./Loading.css";

const Loading = ({ className }) => {
  return (
    <div className={`grid place-items-center ${className}`}>
      <Spinner color="blue" className="h-8 w-8" />
    </div>
  );
};

export default Loading;
