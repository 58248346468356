import React from "react";
import { Outlet } from "react-router-dom";
import TrendingNews from "../components/news/TrendingNews";

const News = () => {
  return (
    <div>
      <div className="flex gap-2">
        <div className="basis-12/12 md:basis-9/12">
          <Outlet />
        </div>
        <div className="hidden basis-0 md:block md:basis-3/12">
          <TrendingNews />
        </div>
      </div>
    </div>
  );
};

export default News;
