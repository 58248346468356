import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../../utils/authFetch";

export const useAdminDashboard = ({ days, league, team, country, game }) => {
  const formattedLeague = league === "All" ? "" : league;
  const formattedCountry = country === "All" ? "" : country;

  return useQuery({
    queryKey: ["adminDashboard", days, league, team, country, game],
    queryFn: async () => {
      const response = await fetcher.get("/api/admin/dashboard", {
        params: {
          days,
          league: formattedLeague,
          team,
          country: formattedCountry,
          game,
        },
      });
      return response.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

// api/admin/dashboard?days=7&league=&team=&country=
