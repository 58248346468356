import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";
import React from "react";

const DeleteDialog = ({
  open,
  handler,
  title,
  description,
  handleDelete,
  loading,
}) => {
  return (
    <Dialog
      open={open}
      handler={handler}
      className="bg-white  max-h-[90vh] overflow-auto"
      size="xs"
    >
      <DialogHeader className="text-black text-center justify-between">
        <h2>{title}</h2>
        <IconButton
          color="gray"
          variant="text"
          onClick={handler}
          className="rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody className="text-black">
        <p className="">{description}</p>
      </DialogBody>
      <DialogFooter className="mt-8">
        <div className="flex items-center justify-end gap-2">
          <Button
            variant="gradient"
            color="blue"
            onClick={handler}
            className="w-full"
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="red"
            onClick={handleDelete}
            className="w-full"
            disabled={loading}
          >
            {loading ? "Deleting..." : "Delete"}
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default DeleteDialog;
