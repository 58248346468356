import { Button } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { fetcher } from "../../utils/authFetch";
import AuthContainer from "./AuthContainer";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const email = data.email;

    try {
      const res = await fetcher.post("/api/auth/forgot-password", { email });
      toast.success(res?.data?.message || "Check your email for OTP");
      navigate(`/forgot-password-otp`);
      sessionStorage.setItem("email", email);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <AuthContainer>
      <div>
        <h2 className="text-center text-3xl font-semibold mb-4">
          Forgot Password
        </h2>
        <p>
          Enter your email to reset your password. We will send an OTP to the
          e-mail for verification.
        </p>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-12">
          {/* Email */}
          <div className="w-full relative">
            <MdEmail className="absolute left-3 top-4 text-xl" />
            <input
              type="email"
              placeholder="Your Email address"
              className="p-3 pl-10 bg-gray-100 border-l-4 border-blue-900 focus:outline-none w-full rounded"
              {...register("email", {
                required: {
                  value: true,
                  message: "Please provide your email",
                },
                pattern: {
                  value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Please enter a valid email",
                },
              })}
            />
            <label className="label">
              {(errors?.email?.type === "required" ||
                errors?.email?.type === "pattern") && (
                <span className="text-red-500 text-xs">
                  {errors.email.message}
                </span>
              )}
            </label>
          </div>

          <Button className="w-full bg-blue-900 mt-6" type="submit">
            Send OTP
          </Button>
        </form>
      </div>
    </AuthContainer>
  );
};

export default ForgotPassword;
