import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import config from "../../utils/config";
import CheckoutForm from "./CheckoutForm";

const CheckoutElement = () => {
  const stripePromise = loadStripe(config.stripe_key);
  return (
    <div>
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};

export default CheckoutElement;
