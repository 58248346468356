import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Chip, IconButton, Input } from "@material-tailwind/react";
import React, { useState } from "react";

import { format } from "date-fns";
import { FaCreditCard, FaTrash } from "react-icons/fa";
import { ScrollRestoration } from "react-router-dom";
import verifiedImg from "../../assets/icons/check.png";
import unVerifiedImg from "../../assets/icons/close.png";
import DataTable from "../../components/shared/DataTable";
import { useUsers } from "../../hooks/admin/useUsers";
import useDebounce from "../../hooks/useDebounce";
import DeleteDialog from "../../components/admin/DeleteDialog";
import toast from "react-hot-toast";
import { fetcher } from "../../utils/authFetch";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Users = () => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [limit, setLimit] = useState(10);
  const { data, isLoading } = useUsers({ search: debouncedSearch });

  const queryClient = useQueryClient();

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const { mutateAsync: deleteUser, isLoading: deleteLoading } = useMutation({
    mutationFn: async (recordId) =>
      fetcher.delete("/api/user", { params: { recordId } }),
    onSuccess: (res) => {
      queryClient.invalidateQueries(["user"]);
      toast.success(res?.data?.message || "User Deleted successfully!");
      setSelected({});
      setDeleteOpen(false);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message || "Something went wrong!");
    },
  });

  const handleDelete = async () => {
    await deleteUser(selected?.recordId);
  };

  const columns = [
    {
      field: "Serial",
      headerName: "Serial",
    },
    {
      field: "Name",
      headerName: "Name",
    },
    {
      field: "Country",
      headerName: "Address",
    },
    {
      field: "Email",
      headerName: "Email",
    },

    {
      field: "Mobile",
      headerName: "Phone No.",
      render: (row) => <span>{row?.Country_code + row?.Mobile || "N/A"}</span>,
    },
    {
      field: "emailVerified",
      headerName: "Email Verified",
      type: "number",
      render: (row) => (
        <img
          src={row?.Email_verified_at ? verifiedImg : unVerifiedImg}
          alt="verification"
          className="h-4 w-4 mx-8"
        />
      ),
    },
    {
      field: "cardAdded",
      headerName: "Card Added",
      render: (row) =>
        row?.Stripe_id ? (
          <FaCreditCard size={20} color="#032b66" className="mx-6" />
        ) : null,
    },
    {
      field: "Plan_name",
      headerName: "Plan Name",
      render: (row) =>
        row?.Plan_name ? (
          <p className="mx-4">{row?.Plan_name}</p>
        ) : (
          <p className="mx-4">N/A</p>
        ),
    },
    {
      field: "Logins_count",
      headerName: "Logins",
      render: (row) => <p className="mx-4">{row?.Logins_count || "N/A"}</p>,
    },
    {
      field: "Created_at",
      headerName: "Created At",
      render: (row) =>
        format(
          row?.Created_at ? new Date(row?.Created_at) : new Date(),
          "dd/MM/yyyy"
        ),
    },
    {
      field: "action",
      headerName: "Action",
      render: (row) => (
        <IconButton
          title="Delete"
          color="red"
          variant="gradient"
          className="text-white text-md h-8 w-8 rounded-full p-1 ml-3"
          onClick={() => {
            setSelected(row);
            setDeleteOpen(true);
          }}
        >
          <FaTrash />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between">
        <Chip
          variant="ghost"
          color="blue"
          size="lg"
          value={`Users: ${data?.meta?.total || 0}`}
          className="max-w-min tracking-widest"
          icon={
            <span className="mx-auto mt-2 block h-2 w-2 rounded-full bg-blue-900 content-['']" />
          }
        />

        <div className="w-full md:w-72 bg-gray-200 rounded-lg">
          <Input
            onChange={(e) => {
              handleSearch(e);
            }}
            placeholder="Search..."
            labelProps={{ className: "hidden" }}
            className="outline-none border-none"
            icon={<MagnifyingGlassIcon className="h-5 w-5" />}
          />
        </div>
      </div>

      <div className="mt-6">
        <DataTable
          isLoading={isLoading}
          rows={
            data?.data
              ?.slice((page - 1) * limit, limit * page)
              ?.map((item, index) => ({
                recordId: item?.id,
                Serial: index + 1 + (page - 1) * limit,
                ...item?.fields,
              })) || []
          }
          columns={columns}
          total={data?.data?.length}
          pagination={true}
          paginationOptions={{
            page,
            setPage,
            limit,
          }}
        />
      </div>
      <ScrollRestoration />

      {/* Delete Dialog */}
      <DeleteDialog
        open={deleteOpen}
        handler={() => setDeleteOpen(!deleteOpen)}
        title="Delete User"
        description="Are you sure you want to delete this user?"
        handleDelete={handleDelete}
        loading={deleteLoading}
      />
    </>
  );
};

export default Users;
