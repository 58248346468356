import { Card, CardBody, Chip } from "@material-tailwind/react";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSoccerAllData } from "../../hooks/useSoccer";
import formatDate from "../../utils/formatter";
import { isEligible } from "../../utils/isEligible";
import { currentDate, currentTime } from "../../utils/timeZone";
import Loading from "../shared/Loading/Loading";
import FootballCard from "./FootballCard";
import { soccerCountries, soccerLeagues } from "../../static/soccerFilters";

const FootballBottomAllComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [dateFilter, setDateFilter] = useState(formatDate(new Date()));
  const [filter, setFilter] = useState("all");
  const [filteredData, setFilteredData] = useState([]);

  const { data, isLoading } = useSoccerAllData({
    date: dateFilter,
    time: currentTime,
    filter: filter === "schedule" ? "schedule" : undefined,
  });

  const selectedCountries = (stat) =>
    soccerCountries.includes(stat?.fields?.Country);
  const selectedLeagues = (stat) =>
    soccerLeagues.includes(stat?.fields?.LeagueName);

  // Get filter and date from url
  useEffect(() => {
    const urlFilter = params?.get("filter");
    const urlDate = params?.get("date");
    if (urlFilter) {
      setFilter(urlFilter);
    }
    if (urlDate) {
      setDateFilter(urlDate);
    }
  }, []);

  // Filter data based on filter and date
  useEffect(() => {
    if (filter === "all") {
      setFilteredData(
        data?.data?.filter(
          (stat) => selectedCountries(stat) && selectedLeagues(stat)
        )
      );
    } else if (filter === "live") {
      setFilteredData(
        data?.data?.filter(
          (stat) =>
            selectedCountries(stat) &&
            selectedLeagues(stat) &&
            stat?.fields?.Time <= currentTime &&
            stat?.fields?.Date === currentDate &&
            !stat?.fields?.Results
        )
      );
    } else if (filter === "odds") {
      setFilteredData(
        data?.data?.filter(
          (stat) =>
            selectedCountries(stat) && selectedLeagues(stat) && isEligible(stat)
        )
      );
    } else if (filter === "finished") {
      setFilteredData(
        data?.data?.filter(
          (stat) =>
            selectedCountries(stat) &&
            selectedLeagues(stat) &&
            stat?.fields?.Results
        )
      );
    } else if (filter === "schedule") {
      // setSortOrder("desc");
      setFilteredData(
        data?.data?.filter(
          (stat) => selectedCountries(stat) && selectedLeagues(stat)
          // &&
          // stat?.fields?.Time > currentTime &&
          // stat?.fields?.Date >= currentDate &&
          // !stat?.fields?.Results
        )
      );
    }
  }, [filter, data]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setDateFilter(newDate);
    params.set("date", newDate);
    const newUrl = `${location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleDetailsClick = async (id) => {
    const fixture = data?.data?.find((stat) => stat?.fields?.MatchID === id);
    localStorage.setItem("fixture", JSON.stringify(fixture));
    navigate(`/football/${id}`);
  };

  const handleFilterClick = (filterParam) => {
    setFilter(filterParam);
    params.set("filter", filterParam);
    const newUrl = `${location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const navList = [
    {
      name: "All",
      param: "all",
    },
    {
      name: "Live",
      param: "live",
    },
    {
      name: "ODDS",
      param: "odds",
    },
    {
      name: "Finished",
      param: "finished",
    },
    {
      name: "Schedule",
      param: "schedule",
    },
  ];

  // Memoize the filtered and sorted data
  const sortedData = useMemo(() => {
    const sortedMatches = filteredData?.slice().sort((a, b) => {
      const timeA = a.fields.Time;
      const timeB = b.fields.Time;
      const dateA = a.fields.Date;
      const dateB = b.fields.Date;

      if (filter === "schedule") {
        // return sortOrder === "desc"
        //   ? new Date(dateA) - new Date(dateB)
        //   : new Date(dateB) - new Date(dateA);

        return new Date(dateA + " " + timeA) - new Date(dateB + " " + timeB);
      }

      // return sortOrder === "asc"
      //   ? timeA.localeCompare(timeB)
      //   : timeB.localeCompare(timeA);
      return timeA.localeCompare(timeB);
    });

    return sortedMatches;
  }, [filteredData, filter]);

  return (
    <>
      <Card className="bg-lightPrimary dark:bg-primary h-[calc(100vh-152px)]  md:h-[calc(100vh-393px)]">
        <CardBody className="overflow-y-scroll scroll-hidden text-justify text-gray-200 p-0">
          <div className="flex md:flex-row flex-col justify-between items-start gap-4 sticky top-0 bg-lightPrimary dark:bg-primary p-4  md:py-6 md:px-6 rounded-xl z-20">
            <div className="overflow-y-scroll scroll-hidden text-justify text-gray-200">
              <div className="flex justify-between items-center ">
                <div className="flex gap-2 ">
                  {navList.map((item, i) => (
                    <button
                      key={i}
                      className={`text-[10px] py-1 px-2.5 rounded-lg ${
                        filter === item.param
                          ? "bg-primaryLighter text-black"
                          : "bg-lightSecondary dark:bg-secondary dark:text-white text-black"
                      }`}
                      onClick={() => handleFilterClick(item.param)}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className={`${filter === "live" ? "hidden" : "flex"}`}>
              <input
                type="date"
                id="date"
                className={`bg-lightSecondary text-black  dark:text-white dark:datepicker dark:bg-secondary px-2.5 text-[10px] py-[3px] rounded-lg w-full`}
                onChange={handleDateChange}
                value={dateFilter}
              />
            </div>
          </div>

          {isLoading ? (
            <Loading className="h-40 " />
          ) : (
            <div className="md:px-6 px-1 md:pb-6 pb-4">
              {sortedData?.length > 0 ? (
                <div>
                  {sortedData.map((item, i) => {
                    const isNewDate =
                      filter === "schedule" &&
                      (sortedData[i - 1]?.fields?.Date !== item?.fields?.Date ||
                        i === 0);

                    return (
                      <div key={i}>
                        {/* Date Title */}
                        {isNewDate ? (
                          <Chip
                            variant="filled"
                            className="max-w-min mt-12 bg-lightSecondary dark:bg-secondary text-black dark:text-white"
                            value={`Date: ${format(
                              new Date(item?.fields?.Date),
                              "dd MMMM, yyyy"
                            )}`}
                          />
                        ) : null}

                        <FootballCard
                          item={item}
                          key={i}
                          handleDetailsClick={handleDetailsClick}
                          isOdds={filter === "odds"}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="dark:text-lightPrimary text-black">
                  No matches are available.
                </p>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default FootballBottomAllComponent;
