import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Loading from "../../shared/Loading/Loading";
import { monthFormatter, weekFormatter } from "../../../utils/formatter";

const DashboardCharts = ({ data, isLoading }) => {
  const [monthlyData, setMonthlyData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");

  const oddsData = [
    {
      name: "Maximum Odds",
      value: data?.accuracyData?.maxOdds < 0 ? null : data?.accuracyData?.maxOdds,
    },
    { name: "Average Odds", value: Number(data?.accuracyData?.avgOdds) },
    {
      name: "Minimum Odds",
      value: data?.accuracyData?.minOdds > 10 ? null : data?.accuracyData?.minOdds,
    },
  ];

  // Accuracy By Month Data
  useEffect(() => {
    if (data?.dataGroupedByMonth) {
      const newMonthlyData =
        data?.dataGroupedByMonth &&
        Object?.entries(data?.dataGroupedByMonth)?.map(([key, value]) => ({
          name: monthFormatter(key),
          value: value?.accuracy,
        }));
      setSelectedMonth(newMonthlyData[0]?.name);
      setMonthlyData(newMonthlyData);
    }
  }, [data?.dataGroupedByMonth]);

  // Accuracy By Week Data
  useEffect(() => {
    if (data?.dataGroupedByWeek && monthlyData?.length) {
      const newWeeklyData = [];
      try {
        Object?.entries(data?.dataGroupedByWeek).forEach(([key, value]) => {
          const weekDate = parseISO(weekFormatter(key));
          const weekMonth = weekDate ? format(weekDate, "yyyy-MMM") : null;
          if (weekMonth === selectedMonth)
            newWeeklyData.push({
              name: weekFormatter(key),
              value: value?.accuracy,
            });
        });
      } catch (error) {
        console.log(error);
      }
      setSelectedWeek(weekFormatter(newWeeklyData[0]?.name));
      setWeeklyData(newWeeklyData);
    }
  }, [data?.dataGroupedByWeek, monthlyData, selectedMonth]);

  // Accuracy By Day Data
  useEffect(() => {
    if (data?.dataGroupedByDate && weeklyData?.length) {
      const newDailyData = [];
      try {
        Object?.entries(data?.dataGroupedByDate).forEach(([key, value]) => {
          const dayDate = parseISO(key);
          const dayWeek = format(dayDate, "w");
          const weekName = dayDate.getFullYear() + "-W" + dayWeek;

          if (weekFormatter(weekName) === selectedWeek)
            newDailyData.push({
              name: format(dayDate, "EEE"),
              value: value?.accuracy,
            });
        });
      } catch (error) {
        console.log(error);
      }

      setDailyData(newDailyData);
    }
  }, [data?.dataGroupedByDate, weeklyData, selectedWeek]);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedOddsLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {/* {`${(percent * 100).toFixed(0)}%`} */}
        {`${oddsData[index].value}`}
      </text>
    );
  };

  if (isLoading) return <Loading className="h-40" />;

  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        {/* Accuracy By Month */}
        <div className="col-span-12 lg:col-span-7 rounded-lg bg-white p-4">
          <h3 className="text-lg font-bold text-center mb-8">
            Accuracy By Month
          </h3>

          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={monthlyData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend Bar />
              <Bar
                dataKey="value"
                name="Accuracy"
                fill="#032b66"
                barSize={40}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Accuracy By Week */}
        <div className="col-span-12 lg:col-span-5 rounded-lg bg-white p-4">
          <div className="flex items-center justify-between gap-4 mb-8">
            <h3 className="text-lg font-bold text-center">Accuracy By Week</h3>

            <div className="">
              <select
                label="Month"
                className="h-8 outline-none border border-gray-300 rounded-md px-2 py-1"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                {monthlyData.map((month, i) => {
                  return (
                    <option key={i} value={month.name}>
                      {month.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={weeklyData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend Bar />
              <Bar dataKey="value" name="Accuracy" fill="green" barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Odds Pie Chart */}
        <div className="col-span-12 lg:col-span-3 rounded-lg bg-white p-4">
          <h3 className="text-lg font-bold text-center mb-8">Odds Overview</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={oddsData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedOddsLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {oddsData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Accuracy By Day */}
        <div className="col-span-12 lg:col-span-5 rounded-lg bg-white p-4">
          <div className="flex items-center justify-between gap-4 mb-8">
            <h3 className="text-lg font-bold text-center">Accuracy By Day</h3>

            <div className="">
              <select
                label="Week"
                className="h-8 outline-none border border-gray-300 rounded-md px-2 py-1"
                value={selectedWeek}
                onChange={(e) => setSelectedWeek(e.target.value)}
              >
                {weeklyData.map((week, i) => (
                  <option key={i} value={week.name}>
                    {week.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={dailyData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend Bar />
              <Bar dataKey="value" name="Accuracy" fill="orange" barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default DashboardCharts;
