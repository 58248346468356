import React from "react";
import { Outlet } from "react-router-dom";

import FootBallTopComponent from "../components/football/FootballTopComponent";
import TrendingNews from "../components/news/TrendingNews";

const Football = () => {
  return (
    <div className="md:flex gap-2">
      <div className="basis-12/12 md:basis-9/12">
        <FootBallTopComponent />
        <Outlet />
      </div>
      <div className="hidden basis-0 md:block md:basis-3/12">
        <TrendingNews />
      </div>
    </div>
  );
};

export default Football;
