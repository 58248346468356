import { Button, Chip, Drawer, IconButton } from "@material-tailwind/react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import logo2 from "../../assets/images/logo/logo2.png";
import menuOptions from "../../static/menuOptions";
import DarkMode from "./moon/DarkMode";
import { FiUser } from "react-icons/fi";
import { FaAngleRight } from "react-icons/fa";
import { useDarkMode } from "./moon/DarkModeContext";

const MobileMenu = ({ open, onClose, handleLogOut }) => {
  const { pathname } = useLocation();
  const isCurrentPath = (path) => pathname === path;
  const { darkMode } = useDarkMode();

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      className={`p-4 dark:bg-primary bg-lightPrimary ${
        open ? "block" : "hidden"
      }`}
    >
      <div className="mb-6 flex items-center justify-between">
        <Link to="/">
          <img className="w-28" src={darkMode ? logo : logo2} alt="logo" />
        </Link>

        <IconButton
          variant="text"
          className="dark:text-white text-black"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>

      <div className="flex flex-col gap-1">
        {menuOptions.map((data) => {
          return (
            <div key={data.id} onClick={onClose}>
              <Link to={data.link}>
                <div
                  className={`flex items-center justify-start gap-2 p-2 rounded-lg dark:text-white text-black active:rounded-2xl 
                        ${
                          isCurrentPath(data?.link)
                            ? "dark:bg-secondary bg-lightSecondary"
                            : "dark:hover:bg-secondary hover:bg-lightSecondary"
                        }`}
                >
                  <span className="w-4">{data?.icon}</span>
                  <p className={``}>{data.name}</p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      <Link to="/account" onClick={onClose}>
        <div className="flex items-center justify-between gap-2 text-black dark:text-white hover:bg-lightSecondary dark:hover:bg-secondary hover:rounded-lg active:rounded-2xl p-2 cursor-pointer">
          <p className="w-full flex items-center gap-2">
            <FiUser className="text-md" /> Account
          </p>
          <FaAngleRight />
        </div>
      </Link>
      <div className="mt-0 flex items-center gap-2">
        <DarkMode id="darkMode2" />
        <Chip
          variant="ghost"
          size="sm"
          value={darkMode ? "Light Mode" : "Dark Mode"}
          className="max-w-min dark:text-white text-black tracking-widest"
        />
      </div>

      <Button
        className="mt-2"
        fullWidth
        color="deep-orange"
        size="sm"
        onClick={handleLogOut}
      >
        LogOut
      </Button>
    </Drawer>
  );
};

export default MobileMenu;
