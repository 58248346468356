import React, { useEffect, useState } from "react";
import Loading from "../shared/Loading/Loading";
import { csgoDate } from "../../utils/formatter";

const MultiChainDetails = ({ fixture, statistics, isLoading, results }) => {
  const fixtureFields = fixture;

  const limit = [
    15, 15, 50, 15, 25, 25, 25, 25, 15, 100, 10, 5, 25, 1000, 750, 100, 10,
  ];

  const [date, setDate] = useState(null);

  const mergedStatistics = [];
  if (results !== 0 && statistics?.length) {
    // Loop through each type of statistic
    statistics[0].statistics.forEach((stat, idx) => {
      const homeStat = stat;
      const awayStat = statistics[1].statistics.find(
        (s) => s.type === stat.type
      );

      if (awayStat) {
        mergedStatistics.push({
          type: stat.type,
          home: homeStat.value,
          away: awayStat.value,
          limit: limit[idx],
        });
      }
    });
  }

  const convertDate = (date) => {
    const parsedDate = new Date(date);

    const ordinalSuffixes = ["th", "st", "nd", "rd"];

    const day = parsedDate.getDate();
    const dayWithSuffix = day + (ordinalSuffixes[(day - 1) % 10] || "th");

    const formattedDate =
      dayWithSuffix +
      " " +
      parsedDate.toLocaleDateString("en-US", {
        month: "long", // Full month name (e.g., "September")
        year: "numeric", // Full year (e.g., "2023")
      });

    setDate(formattedDate);
  };

  useEffect(() => {
    // Check if it is csgo date
    if (fixtureFields["Best-odds-1"]) {
      const normalDate = csgoDate(fixtureFields?.Date || new Date());
      convertDate(normalDate || new Date());
    } else {
      convertDate(fixtureFields?.Date || new Date());
    }
  }, [fixtureFields]);

  return (
    <div>
      <main className="mx-auto max-w-screen-xl my-5 overflow-y-scroll scroll-hidden">
        <div className="card px-1 lg:px-8 py-6 shadow dark:bg-secondary bg-lightSecondary rounded-xl">
          <div className="flex items-center justify-between md:gap-10">
            <div className="flex-1 flex flex-col-reverse md:flex-row items-center gap-5 justify-start">
              <p className="font-bold text-xs md:text-xl text-center text-black dark:text-white">
                {fixtureFields?.HomeTeam || fixtureFields?.Team1}
              </p>
              <img
                className="lg:w-14 w-6 lg:h-14 h-6"
                src={fixtureFields?.T1Logo}
                alt={`${fixtureFields?.HomeTeam} logo`}
              />
            </div>
            <div className="flex-1 text-center">
              {fixtureFields?.MatchResults ? (
                <p className="dark:text-green-500 text-green-900 text-sm">
                  Prediction: <strong>{fixtureFields?.Prediction}</strong>
                </p>
              ) : (
                <p className="text-red-500 text-xl">V/S</p>
              )}
            </div>
            <div className="flex-1 flex flex-col md:flex-row items-center gap-5 justify-end">
              <img
                className="lg:w-14 w-6 lg:h-14 h-6"
                src={fixtureFields?.T2Logo}
                alt={`${fixtureFields?.AwayTeam} logo`}
              />
              <p className="font-bold text-xs md:text-xl text-center text-black dark:text-white">
                {fixtureFields?.AwayTeam || fixtureFields?.Team2}
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <h3 className="font-bold text-center text-sm md:text-lg mt-2 text-blue-700">
              {fixtureFields?.LeagueName || fixtureFields?.Event} - {date} -{" "}
              {fixtureFields?.Venue ? fixtureFields?.Venue : "World"}
            </h3>
          </div>
        </div>

        {isLoading ? (
          <Loading className="h-40" />
        ) : (
          <>
            {results > 0 ? (
              <div className="mb-14">
                {mergedStatistics?.map((statistics, idx) => (
                  <div key={idx}>
                    <div className="w-full my-6">
                      <p className="text-center font-bold text-blue-600">
                        {statistics.type}
                      </p>
                      <div className="flex items-center justify-between gap-3 md:gap-4 lg:gap-6">
                        <div className="w-full">
                          <div className="text-right">
                            <p>
                              {statistics.home === null ? 0 : statistics.home}
                            </p>
                          </div>
                          <progress
                            className={`${
                              statistics.home > statistics.away
                                ? "progress_0"
                                : "progress_1"
                            } w-full !h-4`}
                            style={{ direction: "rtl" }}
                            value={
                              statistics.home === null
                                ? 0
                                : typeof statistics.home === "string"
                                ? parseInt(statistics.home.replace("%", ""))
                                : statistics.home
                            }
                            max={statistics.limit}
                          ></progress>
                        </div>
                        <div className="w-full">
                          <div className="text-left">
                            <p>
                              {statistics.away === null ? 0 : statistics.away}
                            </p>
                          </div>
                          <progress
                            className={`${
                              statistics.away > statistics.home
                                ? "progress_0"
                                : "progress_1"
                            } w-full !h-4`}
                            value={
                              statistics.away === null
                                ? 0
                                : typeof statistics.away === "string"
                                ? parseInt(statistics.away.replace("%", ""))
                                : statistics.away
                            }
                            max={statistics.limit}
                          ></progress>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </>
        )}
      </main>
    </div>
  );
};

export default MultiChainDetails;
