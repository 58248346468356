import React from "react";
import { Outlet } from "react-router-dom";
import CsgoTopComponent from "../components/csgo/CsgoTopComponent";
import TrendingNews from "../components/news/TrendingNews";

const Csgo = () => {
  return (
    <div className="md:flex gap-2">
      <div className="basis-12/12 md:basis-9/12">
        <CsgoTopComponent />
        <Outlet />
      </div>
      <div className="hidden basis-0 md:block md:basis-3/12">
        <TrendingNews />
      </div>
    </div>
  );
};

export default Csgo;
