import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../../utils/authFetch";

export const useUsers = ({ search }) => {
  return useQuery({
    queryKey: ["users", search],
    queryFn: async () => {
      const response = await fetcher.get("/api/user", {
        params: {
          search: search || "",
        },
      });
      return response?.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};
