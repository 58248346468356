import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";
import developmentImg from "../../assets/icons/maintenance.png";
import { useDarkMode } from "./moon/DarkModeContext";

const Common = () => {
  const { darkMode } = useDarkMode();
  return (
    <div className="">
      <Card className="dark:bg-primary bg-lightPrimary  h-[calc(100vh-162px)]">
        <div className="h-full grid place-items-center">
          <CardBody className="overflow-y-scroll scroll-hidden text-justify p-3 text-gray-200">
            <div className="flex items-center justify-center h-full gap-5 flex-col w-full">
              <img
                src={developmentImg}
                alt="Development"
                style={{
                  filter: darkMode ? "brightness(0) invert(1)" : "none",
                }}
              />
              <Typography className="text-4xl text-orange-600 font-bold">
                Under Development..
              </Typography>
              <Link to="/">
                <Button className="bg-secondary hover:bg-[#171a3a] capitalize font">
                  Back to home
                </Button>{" "}
              </Link>
            </div>
          </CardBody>
        </div>
      </Card>
    </div>
  );
};

export default Common;
