import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";

const MultiChainModal = ({ open, handleOpen, data }) => {
  const [possibleOutcome, setPossibleOutcome] = useState(0.0);
  const [wallet, setWallet] = useState();
  const [investmentPercent, setInvestmentPercent] = useState(15);
  const [calcInvestment, setcalCInvestment] = useState(0);
  const [investment, setInvestment] = useState(0);
  const [totalOdds, setTotalOdds] = useState(1);

  const getGameOdds = ({ name, gameData }) => {
    const odds =
      name === "soccer"
        ? gameData?.PredictedOdds
        : name === "csgo"
        ? Number(gameData[`Best-odds-${gameData?.Prediction}`]).toFixed(2)
        : Number(gameData[`BestOdds${gameData?.Prediction}`]).toFixed(2);
    return odds;
  };

  useEffect(() => {
    setcalCInvestment((wallet * investmentPercent) / 100);
    if (investment) {
      setPossibleOutcome(investment * totalOdds?.toFixed(2));
    } else {
      setPossibleOutcome(calcInvestment * totalOdds?.toFixed(2));
    }
  }, [wallet, investmentPercent, investment, totalOdds, calcInvestment]);

  useEffect(() => {
    setTotalOdds(1);
    data?.forEach(({ name, data: gameData }) => {
      const gameOdds = getGameOdds({ name, gameData });
      setTotalOdds((prev) => prev * gameOdds);
    });
  }, [data]);

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className="dark:bg-primary bg-lightPrimary overflow-auto scroll-hidden max-h-[90vh]"
    >
      <DialogHeader className="dark:text-white text-black">
        Our Prediction
      </DialogHeader>
      <DialogBody>
        <div className="flex flex-col gap-2">
          {/* ----------------------------- GameData ------------------- */}
          {data?.map((game, i) => {
            const gameData = game?.data;
            return (
              <Card className="dark:bg-secondary bg-lightSecondary" key={i}>
                <CardBody className="p-1 md:p-4">
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col items-center gap-2">
                      <img
                        className="bg-transparent  md:w-10 w-8 h-8 md:h-10 object-contain"
                        src={gameData?.T1Logo}
                        alt={gameData?.HomeTeam || gameData?.Team1}
                      />
                      <p className="font-bold text-xs md:text-[16px] w-28  text-center">
                        {gameData?.HomeTeam || gameData?.Team1}
                      </p>
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                      <p className="text-xl md:text-2xl dark:text-white text-black font-bold">
                        {gameData?.Time}
                      </p>
                      <p className="text-black dark:text-white text-xs md:text-lg text-center">
                        We Predict:{" "}
                        <span className="dark:text-white text-black">
                          {game?.name === "soccer"
                            ? gameData?.Prediction
                            : gameData[`Team${gameData?.Prediction}`]}
                        </span>
                      </p>
                      <p className="dark:bg-primary bg-lightPrimary text-[8px] text-center md:text-sm px-2 md:px-3 py-0.5 rounded-full text-black dark:text-white">
                        Predicted odds:{" "}
                        {getGameOdds({ name: game?.name, gameData })}
                      </p>
                    </div>
                    <div className="flex flex-col items-center gap-2">
                      <img
                        className="bg-transparent md:w-10 w-8 h-8 md:h-10  object-contain"
                        src={gameData?.T2Logo}
                        alt={gameData?.AwayTeam || gameData?.Team2}
                      />
                      <p className="font-bold text-xs md:text-[16px] text-center w-28 ">
                        {gameData?.AwayTeam || gameData?.Team2}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            );
          })}

          <div className="card  px-8 py-6 shadow bg-lightPrimary dark:bg-secondary rounded-xl">
            <h4 className="text-lg lg:text-md text-center text-black dark:text-white">
              We recommend you to invest{" "}
              <span className="text-2xl font-bold dark:text-yellow-700 text-yellow-900">
                {data?.fields?.Investment_percentage
                  ? `${data?.fields?.Investment_percentage}%`
                  : "15%"}{" "}
              </span>
              of your wallet
            </h4>
          </div>
        </div>

        <Card className="dark:bg-secondary bg-lightSecondary my-2">
          <CardBody className="p-2 md:p-4">
            <div className="flex items-center justify-between">
              <p className="text-xs md:text-lg dark:text-white text-black">
                Total Odds
              </p>
              <p className="text-xs md:text-lg dark:text-white text-black dark:bg-primary px-2 md:px-3 my-0.5 rounded-full">
                {Number(totalOdds).toFixed(2)}
              </p>
            </div>

            <div className="flex flex-row items-start md:items-center justify-between my-3">
              <div className="flex flex-col lg:flex-row gap-2 items-center">
                <p className="dark:text-gray-400 text-gray-900 text-xs md:text-base">
                  Your wallet:
                </p>
                <div>
                  <input
                    type="text"
                    className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
                    onChange={(e) => {
                      setWallet(e?.target?.value);
                    }}
                    value={wallet}
                  />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-2 items-center">
                <p className="dark:text-gray-400 text-gray-900 text-xs md:text-base">
                  Recommended {investmentPercent}%:
                </p>
                <div>
                  <input
                    type="text"
                    className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
                    onChange={(e) => {
                      setInvestmentPercent(e?.target?.value);
                    }}
                    value={calcInvestment ? calcInvestment : null}
                    readOnly
                  />
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-2 items-center">
                <p className="dark:text-gray-400 text-gray-900 text-xs md:text-base">
                  Investment:
                </p>
                <div>
                  <input
                    type="text"
                    className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
                    placeholder={calcInvestment ? calcInvestment : null}
                    onChange={(e) => {
                      setInvestment(e?.target?.value);
                    }}
                    value={investment}
                  />
                </div>
              </div>
            </div>

            <div className="border-b border-gray-900 dark:border-gray-600"></div>
            <div className="flex justify-end items-start my-3">
              {/* <span className="bg-green-800 text-white rounded-md px-2 py-0">
                Get RETURN
              </span> */}
              <div>
                <p className="text-xs md:text-lg text-black dark:text-white text-right">
                  Possible outcome
                </p>
                <p className="text-xs text-right md:text-lg text-black dark:text-white">
                  {possibleOutcome ? Number(possibleOutcome).toFixed(2) : 0}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </DialogBody>
      <DialogFooter>
        <Button
          className="dark:bg-white dark:text-black bg-black text-white"
          onClick={handleOpen}
        >
          <span>Close</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default MultiChainModal;

// ------------------------------- API V1 -----------------------------
// import {
//   Button,
//   Card,
//   CardBody,
//   Dialog,
//   DialogBody,
//   DialogFooter,
//   DialogHeader,
// } from "@material-tailwind/react";
// import React, { useEffect, useState } from "react";

// const MultiChainModal = ({ open, handleOpen, data }) => {
//   const [possibleOutcome, setPossibleOutcome] = useState(0.0);
//   const [wallet, setWallet] = useState();
//   const [investmentPercent, setInvestmentPercent] = useState(15);
//   const [calcInvestment, setcalCInvestment] = useState();
//   const [investment, setInvestment] = useState();

//   const { GameData1, GameData2, GameData3 } = data.fields || {};

//   const game1Odds = data?.fields?.Gamename1
//     ? data?.fields?.Gamename1 === "soccer"
//       ? GameData1?.PredictedOdds
//       : data?.fields?.Gamename1 === "csgo"
//         ? Number(GameData1[`Best-odds-${GameData1?.Prediction}`]).toFixed(2)
//         : GameData1[`BestOdds${GameData1?.Prediction}`]
//     : 1;

//   const game2Odds = data?.fields?.Gamename2
//     ? data?.fields?.Gamename2 === "soccer"
//       ? GameData2?.PredictedOdds
//       : data?.fields?.Gamename2 === "csgo"
//         ? Number(GameData2[`Best-odds-${GameData2?.Prediction}`]).toFixed(2)
//         : GameData2[`BestOdds${GameData2?.Prediction}`]
//     : 1;

//   const game3Odds = data?.fields?.Gamename3
//     ? data?.fields?.Gamename3 === "soccer"
//       ? GameData3?.PredictedOdds
//       : data?.fields?.Gamename3 === "csgo"
//         ? Number(GameData3[`Best-odds-${GameData3?.Prediction}`]).toFixed(2)
//         : GameData3[`BestOdds${GameData3?.Prediction}`]
//     : 1;

//   const totalOdds = Number(game1Odds) * Number(game2Odds) * Number(game3Odds);

//   useEffect(() => {
//     setcalCInvestment(wallet * investmentPercent / 100);
//     if (investment) {
//       setPossibleOutcome(investment * totalOdds?.toFixed(2));
//     } else {
//       setPossibleOutcome(calcInvestment * totalOdds?.toFixed(2));
//     }
//   }, [wallet, investmentPercent, investment, totalOdds, calcInvestment]);

//   return (
//     <Dialog
//       open={open}
//       handler={handleOpen}
//       className="dark:bg-primary bg-lightPrimary overflow-auto scroll-hidden max-h-[90vh]"
//     >
//       <DialogHeader className="dark:text-white text-black">
//         Our Prediction
//       </DialogHeader>
//       <DialogBody>
//         <div className="flex flex-col gap-2">
//           {/* ----------------------------- GameData1 ------------------- */}
//           {GameData1 ? (
//             <Card className="dark:bg-secondary bg-lightSecondary ">
//               <CardBody className="p-1 md:p-4">
//                 <div className="flex items-center justify-between">
//                   <div className="flex flex-col items-center gap-2">
//                     <img
//                       className="bg-transparent  md:w-10 w-8 h-8 md:h-10 object-contain"
//                       src={GameData1?.T1Logo}
//                       alt=""
//                     />
//                     <p className="font-bold text-xs md:text-[16px] w-28  text-center">
//                       {GameData1?.HomeTeam || GameData1?.Team1}
//                     </p>
//                   </div>
//                   <div className="flex flex-col gap-2 items-center">
//                     <p className="text-xl md:text-2xl dark:text-white text-black font-bold">
//                       {GameData1?.Time}
//                     </p>
//                     <p className="text-black dark:text-white text-xs md:text-lg text-center">
//                       We Predict:{" "}
//                       <span className="dark:text-white text-black">
//                         {data?.fields?.Gamename1 === "soccer"
//                           ? GameData1?.Prediction
//                           : GameData1[`Team${GameData1?.Prediction}`]}
//                       </span>
//                     </p>
//                     <p className="dark:bg-primary bg-lightPrimary text-[8px] text-center md:text-sm px-2 md:px-3 py-0.5 rounded-full text-black dark:text-white">
//                       Predicted odds: {game1Odds}
//                     </p>
//                   </div>
//                   <div className="flex flex-col items-center gap-2">
//                     <img
//                       className="bg-transparent md:w-10 w-8 h-8 md:h-10  object-contain"
//                       src={GameData1?.T2Logo}
//                       alt=""
//                     />
//                     <p className="font-bold text-xs md:text-[16px] text-center w-28 ">
//                       {GameData1?.AwayTeam || GameData1?.Team2}
//                     </p>
//                   </div>
//                 </div>
//               </CardBody>
//             </Card>
//           ) : null}

//           {/* ----------------------------- GameData2 ------------------- */}
//           {GameData2 ? (
//             <Card className="dark:bg-secondary bg-lightSecondary ">
//               <CardBody className="p-1 md:p-4">
//                 <div className="flex items-center justify-between">
//                   <div className="flex flex-col items-center gap-2">
//                     <img
//                       className="bg-transparent  md:w-10 w-8 h-8 md:h-10 object-contain"
//                       src={GameData2?.T1Logo}
//                       alt=""
//                     />
//                     <p className="font-bold text-xs md:text-[16px] w-28  text-center">
//                       {GameData2?.HomeTeam || GameData2?.Team1}
//                     </p>
//                   </div>
//                   <div className="flex flex-col gap-2 items-center">
//                     <p className="text-xl md:text-2xl dark:text-white text-black font-bold">
//                       {GameData2?.Time}
//                     </p>
//                     <p className="text-black dark:text-white text-xs md:text-lg text-center">
//                       We Predict:{" "}
//                       <span className="dark:text-white text-black">
//                         {data?.fields?.Gamename2 === "soccer"
//                           ? GameData2?.Prediction
//                           : GameData2[`Team${GameData2?.Prediction}`]}
//                       </span>
//                     </p>
//                     <p className="dark:bg-primary bg-lightPrimary text-[8px] text-center md:text-sm px-2 md:px-3 py-0.5 rounded-full text-black dark:text-white">
//                       Predicted odds: {game2Odds}
//                     </p>
//                   </div>
//                   <div className="flex flex-col items-center gap-2">
//                     <img
//                       className="bg-transparent md:w-10 w-8 h-8 md:h-10  object-contain"
//                       src={GameData2?.T2Logo}
//                       alt=""
//                     />
//                     <p className="font-bold text-xs md:text-[16px] text-center w-28 ">
//                       {GameData2?.AwayTeam || GameData2?.Team2}
//                     </p>
//                   </div>
//                 </div>
//               </CardBody>
//             </Card>
//           ) : null}

//           {/* ----------------------------- GameData3 ------------------- */}
//           {GameData3 ? (
//             <Card className="dark:bg-secondary bg-lightSecondary ">
//               <CardBody className="p-1 md:p-4">
//                 <div className="flex items-center justify-between">
//                   <div className="flex flex-col items-center gap-2">
//                     <img
//                       className="bg-transparent  md:w-10 w-8 h-8 md:h-10 object-contain"
//                       src={GameData3?.T1Logo}
//                       alt=""
//                     />
//                     <p className="font-bold text-xs md:text-[16px] w-28  text-center">
//                       {GameData3?.HomeTeam || GameData3?.Team1}
//                     </p>
//                   </div>
//                   <div className="flex flex-col gap-2 items-center">
//                     <p className="text-xl md:text-2xl dark:text-white text-black font-bold">
//                       {GameData3?.Time}
//                     </p>
//                     <p className="text-black dark:text-white text-xs md:text-lg text-center">
//                       We Predict:{" "}
//                       <span className="dark:text-white text-black">
//                         {data?.fields?.Gamename3 === "soccer"
//                           ? GameData3?.Prediction
//                           : GameData3[`Team${GameData3?.Prediction}`]}
//                       </span>
//                     </p>
//                     <p className="dark:bg-primary bg-lightPrimary text-[8px] text-center md:text-sm px-2 md:px-3 py-0.5 rounded-full text-black dark:text-white">
//                       Predicted odds: {game3Odds}
//                     </p>
//                   </div>
//                   <div className="flex flex-col items-center gap-2">
//                     <img
//                       className="bg-transparent md:w-10 w-8 h-8 md:h-10  object-contain"
//                       src={GameData3?.T2Logo}
//                       alt=""
//                     />
//                     <p className="font-bold text-xs md:text-[16px] text-center w-28 ">
//                       {GameData3?.AwayTeam || GameData3?.Team2}
//                     </p>
//                   </div>
//                 </div>
//               </CardBody>
//             </Card>
//           ) : null}
//           <div className="card  px-8 py-6 shadow bg-lightPrimary dark:bg-secondary rounded-xl">
//             <h4 className="text-lg lg:text-md text-center text-black dark:text-white">
//               We recommend you to invest{" "}
//               <span className="text-2xl font-bold dark:text-yellow-700 text-yellow-900">
//                 {data?.fields?.Investment_percentage
//                   ? `${data?.fields?.Investment_percentage}%`
//                   : "15%"}{" "}
//               </span>
//               of your wallet
//             </h4>
//           </div>
//         </div>

//         <Card className="dark:bg-secondary bg-lightSecondary my-2">
//           <CardBody className="p-2 md:p-4">
//             <div className="flex items-center justify-between">
//               <p className="text-xs md:text-lg dark:text-white text-black">
//                 Total Odds
//               </p>
//               <p className="text-xs md:text-lg dark:text-white text-black dark:bg-primary px-2 md:px-3 my-0.5 rounded-full">
//                 {Number(totalOdds).toFixed(2)}
//               </p>
//             </div>

//             <div className="flex flex-row items-start md:items-center justify-between my-3">
//               <div className="flex flex-col lg:flex-row gap-2 items-center">
//                 <p className="dark:text-gray-400 text-gray-900 text-xs md:text-base">
//                   Your wallet:
//                 </p>
//                 <div>
//                   <input
//                     type="text"
//                     className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
//                     onChange={(e) => {
//                       setWallet(e?.target?.value);
//                     }}
//                     value={wallet}
//                   />
//                 </div>
//               </div>

//               <div className="flex flex-col lg:flex-row gap-2 items-center">
//                 <p className="dark:text-gray-400 text-gray-900 text-xs md:text-base">
//                   Recommended {investmentPercent}%:
//                 </p>
//                 <div>
//                   <input
//                     type="text"
//                     className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
//                     onChange={(e) => {
//                       setInvestmentPercent(e?.target?.value);
//                     }}
//                     value={calcInvestment ? calcInvestment : null}
//                     readOnly
//                   />
//                 </div>
//               </div>
//               <div className="flex flex-col lg:flex-row gap-2 items-center">
//                 <p className="dark:text-gray-400 text-gray-900 text-xs md:text-base">
//                   Investment:
//                 </p>
//                 <div>
//                   <input
//                     type="text"
//                     className="outline-none border-none w-16 md:w-24 h-6 md:h-6 text-right text-xs md:text-base text-black rounded-md px-0.5 md:px-2"
//                     placeholder={calcInvestment ? calcInvestment : null}
//                     onChange={(e) => {
//                       setInvestment(e?.target?.value);
//                     }}
//                     value={investment}
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="border-b border-gray-900 dark:border-gray-600"></div>
//             <div className="flex justify-between items-start my-3">
//               <span className="bg-green-800 text-white rounded-md px-2 py-0">
//                 Get RETURN
//               </span>
//               <div>
//                 <p className="text-xs md:text-lg text-black dark:text-white text-right">
//                   Possible outcome
//                 </p>
//                 <p className="text-xs text-right md:text-lg text-black dark:text-white">
//                   {possibleOutcome ? Number(possibleOutcome).toFixed(2) : 0}
//                 </p>
//               </div>
//             </div>
//           </CardBody>
//         </Card>
//       </DialogBody>
//       <DialogFooter>
//         <Button
//           className="dark:bg-white dark:text-black bg-black text-white"
//           onClick={handleOpen}
//         >
//           <span>Close</span>
//         </Button>
//       </DialogFooter>
//     </Dialog>
//   );
// };

// export default MultiChainModal;
