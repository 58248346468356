import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";

export const useAllComboData = ({ date, finished, skip = false }) => {
  return useQuery({
    queryKey: ["combo", date, finished],
    queryFn: async () => {
      const response = await fetcher.get("/api/combo/v2", {
        params: {
          date,
          finished: finished ? finished : undefined,
        },
      });
      return response.data;
    },
    enabled: !skip,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};
