import { Button, Card, CardBody } from "@material-tailwind/react";
import Cookies from "js-cookie";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import { fetcher } from "../../utils/authFetch";
import useEventListener from "../../hooks/useEventListener";

const EmailVerify = () => {
  const email = Cookies.get("email") || "";
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Verify api
  const verifyAPI = async (data) => {
    setIsLoading(true);
    try {
      const res = await fetcher.post("/api/auth/verify-email", data);
      return res?.data;
    } catch (error) {
      setError(true);
      return Promise.reject(
        error?.response?.data?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleOtpChange = (index, value) => {
    // Update the state with the entered value
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;

    // Move focus to the next input field if the current input is not the last one
    if (index < inputRefs.length - 1 && value !== "") {
      inputRefs[index + 1].current.focus();
    }

    // Update the state with the newOtpValues array
    setOtpValues(newOtpValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const otp = otpValues.join("");
    toast.promise(
      verifyAPI({ email, otp }),
      {
        loading: "OTP Verifying...",
        success: (res) => {
          if (!res?.data?.fields?.Is_staff_member) {
            navigate(`/pricing`);
          } else {
            navigate(`/`);
          }
          return "OTP Verified Successfully";
        },
        error: (errorMessage) => errorMessage,
      },
      { id: "otp" }
    );
  };
  const isButtonDisabled = otpValues.some((value) => value === "");

  // Handle paste
  const handlePaste = (event) => {
    let data = event.clipboardData.getData("text");

    if (data.length > 6) {
      data = data.substring(0, 6);
    }
    const otpChars = data.split("");
    const newOtpValues = [...otpValues];
    otpChars.forEach((char, index) => {
      newOtpValues[index] = char;
    });
    setOtpValues(newOtpValues);

    event.preventDefault();
  };

  // Track otp paste
  useEventListener("paste", handlePaste, inputRefs[0].current);
  return (
    <div
      className="min-h-screen bg-cover bg-no-repeat bg-center relative overflow-hidden"
      style={{
        backgroundImage:
          "linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)), url(https://images.unsplash.com/photo-1606925797300-0b35e9d1794e?auto=format&fit=crop&q=80&w=2081&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      }}
    >
      <div className="flex justify-center items-center h-screen w-full">
        <Card className=" bg-[#39190d] backdrop-blur-xl bg-opacity-20 text-white">
          <CardBody>
            <div className="container mx-auto text-center">
              <div className="flex items-center justify-center">
                <Link to="/">
                  <img className="w-48" src={logo} alt="logo" />
                </Link>
              </div>

              <div className="flex flex-col items-center justify-center">
                {/* Heading */}
                <h1 className="text-3xl font-semibold  mt-8">
                  Verify your email
                </h1>

                <div className="my-8">
                  <h3>
                    Please enter the One-Time Password to verify your account.
                  </h3>
                  <p>
                    A OTP has been sent to <strong>{email}</strong>
                  </p>
                </div>

                {/* Form */}
                <form onSubmit={handleSubmit} className="">
                  {/* OTP */}
                  <div className="grid grid-cols-6 gap-2 w-80 text-black">
                    {otpValues.map((value, index) => (
                      <input
                        key={index}
                        type="number"
                        value={value}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        ref={inputRefs[index]}
                        maxLength="1"
                        className={`w-10 h-10 text-center  rounded-md arrow-hidden ${
                          error
                            ? "outline-red-500  outline"
                            : "focus:outline-blue-500"
                        }`}
                      />
                    ))}
                  </div>

                  {/* Button */}
                  <Button
                    fullWidth
                    size="md"
                    type="submit"
                    className={`mt-8 bg-blue-900 tracking-widest h-[44px] ${
                      isButtonDisabled ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    disabled={isButtonDisabled || isLoading}
                  >
                    {isButtonDisabled ? "Validate" : "Validate"}
                  </Button>
                </form>

                <p className="block text-center mt-4 text-sm">
                  Didn&apos;t receive the OTP?{" "}
                  <span className="text-white font-bold link-hover hover:underline cursor-pointer">
                    Resend OTP
                  </span>
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default EmailVerify;
