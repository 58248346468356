import React from "react";
import AuthContainer from "../components/Auth/AuthContainer";
import SignUpForm from "../components/Auth/SignUpForm";

const SignUp = () => {
  return (
    <AuthContainer>
      <SignUpForm />
    </AuthContainer>
  );
};

export default SignUp;
