import React from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className="flex items-center justify-center  border-2 border-lightSecondary dark:border-white rounded-xl py-1 px-2 text-black dark:text-white"
      onClick={() => navigate(-1)}
    >
      <BsArrowLeftShort size={24} />
      Back
    </button>
  );
};

export default BackButton;
