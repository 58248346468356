import {
    Button,
    Dialog,
    DialogBody,
    DialogHeader,
    IconButton,
} from "@material-tailwind/react";
import React from "react";

const DeactivateAccountModal = ({
  open,
  handler,
  setIsActivateAccount,
}) => {
  return (
    <Dialog
      open={open}
      handler={handler}
      className="bg-primary max-h-[90vh] overflow-auto"
      size="sm"
    >
      <DialogHeader className="text-white text-center justify-between">
        <h2>Deactivate Account</h2>
        <IconButton
          color="white"
          variant="text"
          onClick={handler}
          className="rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </DialogHeader>
      <DialogBody className="p-6">
        <p className="text-gray-400">
          Are you sure you want to deactivate your account? You can still
          reactivate your account by logging in again.
        </p>
        <div className="flex justify-end gap-4 mt-12">
          <Button
            className="bg-[#3f51b5] tracking-wider px-12"
            size="lg"
            onClick={handler}
          >
            No
          </Button>
          <Button
            className="bg-red-500 tracking-wider px-12"
            size="lg"
            onClick={() => {
              handler();
              setIsActivateAccount(false);
            }}
          >
            Yes
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default DeactivateAccountModal;
