import { Card, CardBody } from "@material-tailwind/react";
import { format } from "date-fns";
import React from "react";
import { FaRegBookmark } from "react-icons/fa";
import { Link } from "react-router-dom";
import fire from "../../assets/images/football-leagues/fire.png";
import { useNewsAllData } from "../../hooks/useNews";
import Loading from "../shared/Loading/Loading";

const TrendingNews = () => {
  const { data: news, isLoading } = useNewsAllData();
  return (
    <div className="text-gray-200">
      <Card className="bg-lightPrimary dark:bg-primary h-[calc(100vh-162px)] py-4">
        {isLoading ? (
          <Loading className="h-40" />
        ) : (
          <CardBody className="p-3 overflow-y-scroll scroll-hidden">
            <div className="flex items-center gap-1">
              <p className="text-black dark:text-white text-sm">
                Trending News
              </p>
              <img className="w-5" src={fire} alt="img" />
            </div>
            <div>
              <div className="">
                <div className="relative my-3 rounded-xl overflow-hidden">
                  <img src={news?.records[0]?.fields?.NewsImage} alt="img" />

                  <div className="absolute bottom-0 px-2 bg-black bg-opacity-50 w-[100%]">
                    <Link
                      to={`/news/${news?.records[0]?.id}`}
                      className="hover:underline"
                    >
                      <h1
                        className="capitalize text-yellow-600 text-md font-bold"
                        title={news?.records[0]?.fields?.Title}
                      >
                        {news?.records[0]?.fields?.Title?.slice(0, 23) + "..."}
                      </h1>
                    </Link>
                  </div>
                </div>

                <div className="">
                  <>
                    {news?.records?.slice(1, 20)?.map((data) => {
                      return (
                        <div key={data?.id}>
                          <Link to={`/news/${data?.id}`}>
                            <div className="flex items-center gap-2 mt-3 rounded-lg overflow-hidden bg-lightSecondary dark:bg-secondary dark:hover:bg-black hover:bg-blue-900 group">
                              <div className="w-16 h-16 overflow-hidden">
                                <img
                                  className="block w-full h-full object-cover text-xs"
                                  src={data?.fields?.NewsImage}
                                  alt="thumbnail"
                                />
                              </div>
                              <div className="group-hover:text-white">
                                <p
                                  className="text-black dark:text-white group-hover:text-white text-[12px] w-32 h-10"
                                  title={data?.fields?.Title}
                                >
                                  {data?.fields?.Title?.length > 30
                                    ? data?.fields?.Title?.slice(0, 30) + "..."
                                    : data?.fields?.Title}
                                </p>
                                <p className="text-[10px] text-gray-500">
                                  {format(
                                    new Date(data?.fields?.Date),
                                    "dd MMM, yyyy"
                                  )}
                                  ,{data?.fields?.Time}
                                </p>
                              </div>
                              <div className="px-2">
                                <FaRegBookmark />
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </>
                </div>
              </div>
            </div>
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default TrendingNews;
