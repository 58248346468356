import {
  Option,
  Select,
  Tab,
  Tabs,
  TabsHeader,
} from "@material-tailwind/react";
import React from "react";
import { soccerCountries, soccerLeagues } from "../../../static/soccerFilters";

const dateFilters = [
  {
    label: "Yesterday",
    value: "2",
  },
  {
    label: "Last 7 Days",
    value: "7",
  },
  {
    label: "Last 15 Days",
    value: "15",
  },
  {
    label: "Last 1 Month",
    value: "30",
  },
  {
    label: "Last 3 Months",
    value: "90",
  },
  {
    label: "Last 6 Months",
    value: "180",
  },
  {
    label: "Last 12 Months",
    value: "365",
  },
];
const allGame = [
  {
    id: 1,
    label: "Soccer",
    value: "soccer",
  },
  {
    id: 2,
    label: "CS:GO",
    value: "csgo",
  },
  {
    id: 3,
    label: "Valorant",
    value: "valorant",
  },
];
const soccerCountriesFilters = ["All", ...soccerCountries];

const soccerLeaguesFilters = ["All", ...soccerLeagues];

const DashboardFilter = ({
  selectedDate,
  setSelectedDate,
  country,
  setCountry,
  league,
  setLeague,
  game,
  setGame,
}) => {
  // const [selectedDate, setSelectedDate] = useState("7");
  // const [country, setCountry] = useState("All");
  // const [league, setLeague] = useState("All");

  // console.log(selectedDate, country, league);
  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2 bg-gray-100 rounded-lg p-4">
        {/* Game */}
        <Tabs
          value={game}
          className="w-full md:w-max scroll-hidden text-black overflow-x-scroll flex flex-col lg:flex-row lg:gap-36 gap-4"
        >
          <TabsHeader className=" overflow-x-scroll scroll-hidden bg-lightSecondary text-black w-full">
            {allGame.map(({ label, value }) => (
              <Tab
                onClick={() => setGame(value)}
                key={value}
                value={value}
                className="text-gray-600 "
              >
                &nbsp;&nbsp;{label}&nbsp;&nbsp;
              </Tab>
            ))}
          </TabsHeader>
        </Tabs>

        {/* Date */}
        <div className="">
          <Select
            label="Select Date"
            value={selectedDate}
            onChange={(value) => setSelectedDate(value)}
          >
            {dateFilters.map((date, i) => (
              <Option key={i} value={date.value}>
                {date?.label}
              </Option>
            ))}
          </Select>
        </div>

        {/* Country */}
        <div className="">
          <Select
            label="Country"
            value={country}
            onChange={(value) => setCountry(value)}
          >
            {soccerCountriesFilters.map((country, i) => (
              <Option key={i} value={country}>
                {country}
              </Option>
            ))}
          </Select>
        </div>

        {/* Leagues */}
        <div className="">
          <Select
            label="League/Tournament"
            value={league}
            onChange={(value) => setLeague(value)}
          >
            {soccerLeaguesFilters.map((league, i) => (
              <Option key={i} value={league}>
                {league}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default DashboardFilter;
