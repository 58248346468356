export const soccerCountries = [
  "England",
  "Denmark",
  "Spain",
  "Italy",
  "France",
  "Germany",
  "Netherlands",
  "Portugal",
  "USA",
  "Belgium",
  "Sweden",
  "Saudi-Arabia",
  "Iran",
  "Mexico",
  "Colombia",
  "Argentina",
  "Brazil",
  "World",
];

export const soccerLeagues = [
  "Euro Championship",
  "UEFA Super Cup",
  "Leagues Cup",
  "Friendlies",
  "EFL Trophy",
  "Superliga",
  "Premier League",
  "Leagues Cup",
  "FA Cup",
  "Primera Division",
  "UEFA Europa Conference League",
  "Serie A",
  "League One",
  "La Liga",
  "Ligue 1",
  "Bundesliga",
  "Eredivisie",
  "Primeira Liga",
  "Major League Soccer",
  "Jupiler Pro League",
  "Allsvenskan",
  "UEFA Champions League",
  "UEFA Europa League",
  "Championship",
  "Pro League",
  "Copa del Rey",
  "League Cup",
  "Persian Gulf Pro League",
  "Liga de Expansión MX",
  "Primera A",
  "Copa de la Liga Profesional",
  "Carioca - 1",
  "Catarinense - 1",
];
