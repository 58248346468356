import Cookies from "js-cookie";

export const isEligible = (item, requiredPlan) => {
  const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;
  const userPlan = user?.fields.Plan_name;

  if (user?.fields?.Is_staff_member || user?.fields?.Role === "admin") {
    return true;
  }
  if (user?.fields?.PaymentRequired) {
    const newUrl = `${window.location.origin}?payment_required=true`;
    window.location.href = newUrl;
    return false;
  }
  if (
    new Date(user?.fields?.Plan_end_date) &&
    new Date(user?.fields?.Plan_end_date) < new Date()
  ) {
    return false;
  }

  if (requiredPlan) {
    if (userPlan === requiredPlan) return true;
    else return false;
  }

  if (item?.fields.Basic) {
    if (userPlan === "Basic" || userPlan === "Pro" || userPlan === "Proplus")
      return true;
    else return false;
  } else if (item?.fields.Pro) {
    if (userPlan === "Pro" || userPlan === "Proplus") return true;
    else return false;
  } else if (userPlan === "Proplus") {
    return true;
  } else {
    return false;
  }
};
