import { useQuery } from "@tanstack/react-query";
import { fetcher } from "../utils/authFetch";

export const useNewsAllData = () => {
  return useQuery({
    queryKey: ["news"],
    queryFn: async () => {
      const response = await fetcher.get("/api/news");
      return response.data?.data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useSingleNews = (id) => {
  return useQuery({
    queryKey: ["news", id],
    queryFn: async () => {
      const response = await fetcher.get("/api/news/" + id);
      return response.data;
    },
    enabled: !!id,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};
