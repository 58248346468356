import {
  Button,
  Card,
  CardBody,
  Chip,
  Input,
  Option,
  Select,
} from "@material-tailwind/react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { ScrollRestoration } from "react-router-dom";
import { fetcher } from "../../utils/authFetch";

const AdminSoccer = () => {
  const [open, setOpen] = useState(false);
  const initialFormData = {
    HomeTeam: "",
    AwayTeam: "",
    Date: "",
    Time: "",
    Venue: "",
    LeagueName: "",
    Prediction: "",
    PredictedOdds: 0,
    // "%Prediction": "15%",
    Results: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "number" ? parseInt(value) : value,
    });
  };
  const handleResultSelectChange = (value) => {
    setFormData({
      ...formData,
      Results: value,
    });
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      setFormData(initialFormData);
      const { data } = await fetcher.post("/api/soccer", { fields: formData });
      toast.success("Game is added...");
    } catch (error) {
      setLoading(false);
      toast.error("Not submitted", error);
    }
  };

  const clearData = () => {
    setFormData(initialFormData);
  };

  return (
    <div className="">
      <Chip
        variant="ghost"
        color="blue"
        size="lg"
        value="Soccer Matches"
        className="max-w-min tracking-widest"
        icon={
          <span className="mx-auto mt-2 block h-2 w-2 rounded-full bg-blue-900 content-['']" />
        }
      />
      <div className="my-6">
        <Card className="bg-[#f0f3f8]">
          <form onSubmit={formSubmitHandler}>
            <CardBody>
              <p>Create Match</p>
              <p className="border-b border-gray-400 my-4"></p>
              <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-8 ">
                <div>
                  <p className="text-black text-sm mb-2">
                    Team 1 <span className="text-red-500">*</span>
                  </p>
                  <Input
                    type="text"
                    variant="static"
                    placeholder="Enter Team 1"
                    name="HomeTeam"
                    value={formData.HomeTeam}
                    onChange={handleInputChange}
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">
                    Team 2 <span className="text-red-500">*</span>
                  </p>
                  <Input
                    type="text"
                    variant="static"
                    name="AwayTeam"
                    placeholder="Enter Team 2"
                    value={formData.AwayTeam}
                    onChange={handleInputChange}
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">
                    Date <span className="text-red-500">*</span>
                  </p>
                  <Input
                    type="date"
                    variant="static"
                    name="Date"
                    value={formData.Date}
                    onChange={handleInputChange}
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">
                    Start Time <span className="text-red-500">*</span>
                  </p>
                  <Input
                    type="time"
                    variant="static"
                    name="Time"
                    value={formData.Time}
                    onChange={handleInputChange}
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">Venue</p>
                  <Input
                    type="text"
                    variant="static"
                    placeholder="Enter The Venue"
                    name="Venue"
                    value={formData.Venue}
                    onChange={handleInputChange}
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">Event</p>
                  <Input
                    type="text"
                    placeholder="Enter League Name"
                    name="LeagueName"
                    value={formData.LeagueName}
                    onChange={handleInputChange}
                    variant="static"
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">We Predict</p>
                  <Input
                    type="text"
                    placeholder="Enter Prediction"
                    variant="static"
                    name="Prediction"
                    value={formData.Prediction}
                    onChange={handleInputChange}
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                {/* <div>
                  <p className="text-black text-sm mb-2">% Prediction</p>
                  <Input
                    type="text"
                    placeholder="15%"
                    readOnly
                    name="PercentPrediction"
                    value={formData.PercentPrediction}
                    onChange={handleInputChange}
                    variant="static"
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div> */}
                <div>
                  <p className="text-black text-sm mb-2">
                    Odds <span className="text-red-500">*</span>
                  </p>
                  <Input
                    type="number"
                    placeholder="Enter Odds"
                    name="PredictedOdds"
                    value={formData.PredictedOdds}
                    onChange={handleInputChange}
                    variant="static"
                    className="font bg-white shadow-2xl border-none rounded-md px-2 pt-[6px]"
                  />
                </div>
                <div>
                  <p className="text-black text-sm mb-2">
                    Result <span className="text-red-500">*</span>
                  </p>
                  <Select
                    label="Select result"
                    animate={{
                      mount: { y: 0 },
                      unmount: { y: 25 },
                    }}
                    name="Results"
                    value={formData.Results}
                    onChange={handleResultSelectChange}
                    className="font bg-white shadow-2xl  rounded-md "
                  >
                    <Option value="Win" className="bg-tansparent">
                      Win
                    </Option>
                    <Option value="Loss" className="bg-tansparent">
                      Loss
                    </Option>
                    <Option value="Draw" className="bg-tansparent">
                      Draw
                    </Option>
                  </Select>
                </div>
              </div>
              <p className="border-t border-gray-400 my-8"></p>
              <Button type="submit" className="capitalize bg-green-500">
                {loading ? "Creating" : " Create"}
              </Button>
              <Button
                onClick={clearData}
                className="capitalize bg-red-500 ml-4"
              >
                Cancel
              </Button>
            </CardBody>
          </form>
        </Card>
      </div>
      <ScrollRestoration />
    </div>
  );
};

export default AdminSoccer;
