import { useQuery } from "@tanstack/react-query";
import { rapidFetch } from "../utils/authFetch";

export const useStatistics = ({ matchId }) => {
  return useQuery({
    queryKey: ["statistics", matchId],
    queryFn: async () => {
      const response = await rapidFetch.get(
        "https://api-football-v1.p.rapidapi.com/v3/fixtures/statistics",
        {
          params: { fixture: matchId },
        }
      );
      return response.data;
    },
    enabled: !!matchId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
};
