import { Button, Card, Spinner } from "@material-tailwind/react";
import { format } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";
import { useSingleNews } from "../../hooks/useNews";
import BackButton from "../shared/BackButton";

const NewsDetails = () => {
  const { id: newsId } = useParams();
  const { data: newsData, isLoading } = useSingleNews(newsId);

  return (
    <div>
      <Card className="dark:bg-primary bg-lightPrimary h-[calc(100vh-162px)] text-white p-4 overflow-y-scroll scroll-hidden">
        <div className="flex justify-start mb-6">
          <BackButton />
        </div>

        {isLoading ? (
          <div className="grid place-items-center h-[20vh]">
            <Spinner />
          </div>
        ) : (
          <div>
            <img
              src={newsData?.fields?.NewsImage}
              alt={newsData?.fields?.Title}
              className="w-full h-[400px] object-cover rounded-lg"
            />

            <div className="flex items-center justify-start gap-2 mt-4">
              <p className="dark:text-gray-400 text-gray-800">Published at: </p>
              <p className="dark:text-white text-black">
                {format(new Date(newsData?.fields?.Date), "PPP")}{" "}
              </p>
            </div>

            <h1 className="text-4xl dark:text-gray-200 text-gray-800 font-medium text-left mt-4">
              {newsData?.fields?.Title}
            </h1>

            <p className="text-gray-900 dark:text-gray-300 mt-6">{newsData?.fields?.SubTitle}</p>
            <p className="text-gray-900 dark:text-gray-300 mt-8">
              {newsData?.fields?.Description}
            </p>

            <div className="grid place-items-center mt-4">
              <a
                href={newsData?.fields?.["Refference URL"]}
                rel="noreferrer"
                target="_blank"
              >
                <Button className="dark:bg-white dark:text-black bg-black text-white">Read More</Button>
              </a>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default NewsDetails;
