import React from "react";
import { Outlet } from "react-router-dom";
import TrendingNews from "../components/news/TrendingNews";
import ValorantTopComponent from "../components/valorant/ValorantTopComponent";

const Valorant = () => {
  return (
    <div className="md:flex gap-2">
      <div className="basis-12/12 md:basis-9/12">
        <ValorantTopComponent />
        <Outlet />
      </div>

      <div className="hidden basis-0 md:block md:basis-3/12">
        <TrendingNews />
      </div>
    </div>
  );
};

export default Valorant;
