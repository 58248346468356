import { Avatar, Typography } from "@material-tailwind/react";
import React, { useMemo, useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { imgUrl } from "../../utils/img";
import Loading from "../shared/Loading/Loading";
import { sliceText } from "../../utils/sliceText";

const TABLE_HEAD = ["Date", "Teams", "Score", "League/Event", "We Predict"];

const ComboAccuracyTable = ({
  isLoading,
  data = [],
  total = 0,
  page = 1,
  limit = 10,
}) => {
  return (
    <table className="w-full min-w-max table-auto text-left">
      <thead>
        <tr>
          {TABLE_HEAD?.map((head) => (
            <th
              key={head}
              className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
            >
              <Typography
                variant="small"
                className="font-normal leading-none text-black"
              >
                {head}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan={5}>
              <Loading className="h-96" />
            </td>
          </tr>
        ) : (
          <>
            {!data?.length ? (
              <tr>
                <td colSpan={5}>
                  <Typography className="text-black dark:text-white text-center">
                    No data found
                  </Typography>
                </td>
              </tr>
            ) : (
              <>
                {data
                  ?.slice((page - 1) * limit, limit * page)
                  ?.map((d, index) => {
                    const isLast = index === total - 1;
                    const classes = isLast ? "p-4" : "p-4";

                    return (
                      <ComboTableRow
                        key={index}
                        d={d}
                        index={index}
                        classes={classes}
                        isLast={isLast}
                      />
                    );
                  })}
              </>
            )}
          </>
        )}
      </tbody>
    </table>
  );
};

export default ComboAccuracyTable;

export const CustomImage = ({ src, alt, className, ...rest }) => {
  return (
    <LazyLoadImage
      width={20}
      height={20}
      src={src}
      alt={alt}
      effect="opacity"
      placeholderSrc={src}
      className={className}
      {...rest}
    />
  );
};

export const ComboTableRow = ({ d, index, classes, isLast }) => {
  const resultExist = d?.every(({ data: gameData }) => {
    const newResultExist =
      gameData?.Results || gameData?.Result || gameData?.MatchResults;
    return newResultExist;
  });
  const isPredictionCorrect = d?.some(({ data: gameData }) => {
    const newIsPredictionCorrect =
      gameData?.Results === "FALSE" ||
      gameData[`True/false`] === "false" ||
      gameData?.TrueFalse === "False";
    return newIsPredictionCorrect;
  })
    ? false
    : true;

  const predictionColor = (gameData) =>
    gameData?.Results === "TRUE" ||
    gameData["True/false"] === "true" ||
    gameData?.TrueFalse === "True"
      ? "text-green-600"
      : "text-red-600";

  return (
    <tr
      key={index + 1}
      className="dark:even:bg-secondary even:bg-lightSecondary"
    >
      {/* 1st Column */}
      <td className={classes}>
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-3">
            {resultExist ? (
              <div
                className={`${
                  isPredictionCorrect ? "bg-green-600" : "bg-red-600"
                } w-[2px] h-24`}
              ></div>
            ) : null}
            {resultExist ? (
              <FaStar
                color={isPredictionCorrect ? "green" : "red"}
                className="text-sm lg:text-md"
              />
            ) : (
              <FaRegStar className="text-black dark:text-white text-sm lg:text-md" />
            )}
          </div>

          <div className="flex flex-col">
            <Typography
              variant="small"
              className="font-normal text-black dark:text-white"
            >
              {d[0]?.data?.Date}
            </Typography>
            {/* <Typography
              variant="small"
              className="font-normal opacity-70 text-black dark:text-white"
            >
              {d?.fields.Time}
            </Typography> */}
          </div>
        </div>
      </td>

      {/* 2nd column */}
      <td className={classes}>
        <div className="flex flex-col gap-4">
          {d?.map(({ name, data: gameData }, i) => (
            <div className="flex items-center gap-1" key={i}>
              <div className="flex items-center -space-x-4">
                <Avatar
                  variant="circular"
                  alt="user 1"
                  className="border object-contain h-8 w-8 bg-white border-gray-300 hover:z-10 focus:z-10"
                  src={imgUrl(gameData?.T1Logo)}
                />
                <Avatar
                  variant="circular"
                  alt="user 2"
                  className="border object-contain h-8 w-8 bg-white border-gray-300 hover:z-10 focus:z-10"
                  src={imgUrl(gameData?.T2Logo)}
                />
              </div>

              <div className="flex flex-col">
                <Typography
                  variant="small"
                  className="font-normal text-black dark:text-white"
                >
                  {`${gameData?.HomeTeam || gameData?.Team1} vs ${
                    gameData?.AwayTeam || gameData?.Team2
                  }`}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </td>

      {/* 3rd Column */}
      <td className={classes}>
        <div className="flex flex-col gap-4">
          {d?.map(({ name, data: gameData }, i) => (
            <Typography className="text-black dark:text-white" key={i}>
              {Number(gameData?.HomeGoals) === 0.0 || gameData?.Team1Score === 0
                ? "0"
                : Number(gameData?.HomeGoals) || gameData?.Team1Score}{" "}
              -{" "}
              {Number(gameData?.AwayGoals) === 0.0 || gameData?.Team2Score === 0
                ? "0"
                : Number(gameData?.AwayGoals) || gameData?.Team2Score}
            </Typography>
          ))}
        </div>
      </td>

      {/* 4th Column */}
      <td className={classes}>
        <div className="flex flex-col gap-4">
          {d?.map(({ name, data: gameData }, i) => (
            <Typography
              title={gameData.LeagueName || gameData.Event}
              className="text-black dark:text-white cursor-pointer"
              key={i}
            >
              {gameData.LeagueName ||
                (gameData?.Event && gameData?.Event.length > 18
                  ? `${gameData?.Event.slice(0, 18)}..`
                  : gameData?.Event)}
            </Typography>
          ))}
        </div>
      </td>

      {/* 5th Column */}
      <td className={classes}>
        <div className="flex flex-col gap-4">
          {d?.map(({ name, data: gameData }, i) => (
            <Typography
              className={`${predictionColor(gameData)}  font-semibold`}
              key={i}
            >
              {sliceText(name === "soccer"
                ? gameData.Prediction
                : gameData[`Team${gameData?.Prediction}`], 25)}
            </Typography>
          ))}
        </div>
      </td>
    </tr>
  );
};

// ----------------------- API V1 ------------------------
// import { Avatar, Typography } from "@material-tailwind/react";
// import React from "react";
// import { FaRegStar, FaStar } from "react-icons/fa";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { imgUrl } from "../../utils/img";
// import Loading from "../shared/Loading/Loading";

// const TABLE_HEAD = ["Date", "Teams", "Score", "League/Event", "We Predict"];

// const ComboAccuracyTable = ({
//   isLoading,
//   data = [],
//   total = 0,
//   page = 1,
//   limit = 10,
// }) => {
//   return (
//     <table className="w-full min-w-max table-auto text-left">
//       <thead>
//         <tr>
//           {TABLE_HEAD?.map((head) => (
//             <th
//               key={head}
//               className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
//             >
//               <Typography
//                 variant="small"
//                 className="font-normal leading-none text-black"
//               >
//                 {head}
//               </Typography>
//             </th>
//           ))}
//         </tr>
//       </thead>
//       <tbody>
//         {isLoading ? (
//           <tr>
//             <td colSpan={5}>
//               <Loading className="h-96" />
//             </td>
//           </tr>
//         ) : (
//           <>
//             {!data?.length ? (
//               <tr>
//                 <td colSpan={5}>
//                   <Typography className="text-black dark:text-white text-center">
//                     No data found
//                   </Typography>
//                 </td>
//               </tr>
//             ) : (
//               <>
//                 {data
//                   ?.slice((page - 1) * limit, limit * page)
//                   ?.map((d, index) => {
//                     const isLast = index === total - 1;
//                     const classes = isLast ? "p-4" : "p-4";
//                     const GameData1 = d?.fields?.GameData1;
//                     const GameData2 = d?.fields?.GameData2;
//                     const GameData3 = d?.fields?.GameData3;
//                     const predictionColor = (gameData) =>
//                       gameData?.Results === "TRUE" ||
//                       gameData["True/false"] === "true" ||
//                       gameData?.TrueFalse === "True"
//                         ? "text-green-600"
//                         : "text-red-600";

//                     const resultExist = () => {
//                       const PredictionResult1 =
//                         GameData1?.MatchResults ||
//                         GameData1?.Results ||
//                         GameData1?.Result;
//                       const PredictionResult2 =
//                         GameData2?.MatchResults ||
//                         GameData2?.Results ||
//                         GameData2?.Result;
//                       const PredictionResult3 =
//                         GameData3?.MatchResults ||
//                         GameData3?.Results ||
//                         GameData3?.Result;

//                       const result =
//                         PredictionResult1 && PredictionResult2 && GameData3
//                           ? PredictionResult3
//                           : true;

//                       return result;
//                     };

//                     const isPredictionCorrect =
//                       GameData1?.Results === "FALSE" ||
//                       GameData1[`True/false`] === "false" ||
//                       GameData1?.TrueFalse === "False" ||
//                       GameData2?.Results === "FALSE" ||
//                       GameData2[`True/false`] === "false" ||
//                       GameData2?.TrueFalse === "False" ||
//                       (GameData3
//                         ? GameData3?.Results === "FALSE" ||
//                           GameData3[`True/false`] === "false" ||
//                           GameData3?.TrueFalse === "False"
//                         : false)
//                         ? false
//                         : true;

//                     return (
//                       <tr
//                         key={index + 1}
//                         className="dark:even:bg-secondary even:bg-lightSecondary"
//                       >
//                         {/* 1st Column */}
//                         <td className={classes}>
//                           <div className="flex items-center gap-3">
//                             <div className="flex items-center gap-3">
//                               {resultExist() ? (
//                                 <div
//                                   className={`${
//                                     isPredictionCorrect
//                                       ? "bg-green-600"
//                                       : "bg-red-600"
//                                   } w-[2px] h-24`}
//                                 ></div>
//                               ) : null}
//                               {resultExist() ? (
//                                 <FaStar
//                                   color={isPredictionCorrect ? "green" : "red"}
//                                   className="text-sm lg:text-md"
//                                 />
//                               ) : (
//                                 <FaRegStar className="text-black dark:text-white text-sm lg:text-md" />
//                               )}
//                             </div>

//                             <div className="flex flex-col">
//                               <Typography
//                                 variant="small"
//                                 className="font-normal text-black dark:text-white"
//                               >
//                                 {d?.fields.Date}
//                               </Typography>
//                               <Typography
//                                 variant="small"
//                                 className="font-normal opacity-70 text-black dark:text-white"
//                               >
//                                 {d?.fields.Time}
//                               </Typography>
//                             </div>
//                           </div>
//                         </td>

//                         {/* 2nd column */}
//                         <td className={classes}>
//                           <div className="flex flex-col gap-4">
//                             {GameData1 && (
//                               <div className="flex items-center gap-1">
//                                 {GameData1?.T1Logo && (
//                                   <div className="flex items-center -space-x-4">
//                                     <Avatar
//                                       variant="circular"
//                                       alt="user 1"
//                                       className="border object-contain h-8 w-8 bg-white border-gray-300 hover:z-10 focus:z-10"
//                                       src={imgUrl(GameData1?.T1Logo)}
//                                     />
//                                     <Avatar
//                                       variant="circular"
//                                       alt="user 2"
//                                       className="border object-contain h-8 w-8 bg-white border-gray-300 hover:z-10 focus:z-10"
//                                       src={imgUrl(GameData1?.T2Logo)}
//                                     />
//                                   </div>
//                                 )}

//                                 <div className="flex flex-col">
//                                   <Typography
//                                     variant="small"
//                                     className="font-normal text-black dark:text-white"
//                                   >
//                                     {`${
//                                       GameData1?.HomeTeam || GameData1?.Team1
//                                     } vs ${
//                                       GameData1?.AwayTeam || GameData1?.Team2
//                                     }`}
//                                   </Typography>
//                                 </div>
//                               </div>
//                             )}

//                             {GameData2 && (
//                               <div className="flex items-center gap-1">
//                                 {GameData2?.T1Logo && (
//                                   <div className="flex items-center -space-x-4">
//                                     <Avatar
//                                       variant="circular"
//                                       alt="user 1"
//                                       className="border object-contain h-8 w-8 bg-white border-gray-300 hover:z-10 focus:z-10"
//                                       src={imgUrl(GameData2?.T1Logo)}
//                                     />
//                                     <Avatar
//                                       variant="circular"
//                                       alt="user 2"
//                                       className="border object-contain h-8 w-8 bg-white border-gray-300 hover:z-10 focus:z-10"
//                                       src={imgUrl(GameData2?.T2Logo)}
//                                     />
//                                   </div>
//                                 )}

//                                 <div className="flex flex-col">
//                                   <Typography
//                                     variant="small"
//                                     className="font-normal text-black dark:text-white"
//                                   >
//                                     {`${
//                                       GameData2?.HomeTeam || GameData2?.Team1
//                                     } vs ${
//                                       GameData2?.AwayTeam || GameData2?.Team2
//                                     }`}
//                                   </Typography>
//                                 </div>
//                               </div>
//                             )}

//                             {GameData3 && (
//                               <div className="flex items-center gap-1">
//                                 {GameData3?.T1Logo && (
//                                   <div className="flex items-center -space-x-4">
//                                     <Avatar
//                                       variant="circular"
//                                       alt="user 1"
//                                       className="border object-contain h-8 w-8 bg-white border-gray-300 hover:z-10 focus:z-10"
//                                       src={imgUrl(GameData3?.T1Logo)}
//                                     />
//                                     <Avatar
//                                       variant="circular"
//                                       alt="user 2"
//                                       className="border object-contain h-8 w-8 bg-white border-gray-300 hover:z-10 focus:z-10"
//                                       src={imgUrl(GameData3?.T2Logo)}
//                                     />
//                                   </div>
//                                 )}

//                                 <div className="flex flex-col">
//                                   <Typography
//                                     variant="small"
//                                     className="font-normal text-black dark:text-white"
//                                   >
//                                     {`${
//                                       GameData3?.HomeTeam || GameData3?.Team1
//                                     } vs ${
//                                       GameData3?.AwayTeam || GameData3?.Team2
//                                     }`}
//                                   </Typography>
//                                 </div>
//                               </div>
//                             )}
//                           </div>
//                         </td>

//                         {/* 3rd Column */}
//                         <td className={classes}>
//                           <div className="flex flex-col gap-4">
//                             {GameData1 && (
//                               <Typography className="text-black dark:text-white">
//                                 {Number(GameData1?.HomeGoals) === 0.0 ||
//                                 GameData1?.Team1Score === 0
//                                   ? "0"
//                                   : Number(GameData1?.HomeGoals) ||
//                                     GameData1?.Team1Score}{" "}
//                                 -{" "}
//                                 {Number(GameData1?.AwayGoals) === 0.0 ||
//                                 GameData1?.Team2Score === 0
//                                   ? "0"
//                                   : Number(GameData1?.AwayGoals) ||
//                                     GameData1?.Team2Score}
//                               </Typography>
//                             )}
//                             {GameData2 && (
//                               <Typography className="text-black dark:text-white">
//                                 {Number(GameData2?.HomeGoals) === 0.0 ||
//                                 GameData2?.Team1Score === 0
//                                   ? "0"
//                                   : Number(GameData2?.HomeGoals) ||
//                                     GameData2?.Team1Score}{" "}
//                                 -{" "}
//                                 {Number(GameData2?.AwayGoals) === 0.0 ||
//                                 GameData2?.Team2Score === 0
//                                   ? "0"
//                                   : Number(GameData2?.AwayGoals) ||
//                                     GameData2?.Team2Score}
//                               </Typography>
//                             )}
//                             {GameData3 && (
//                               <Typography className="text-black dark:text-white">
//                                 {Number(GameData3?.HomeGoals) === 0.0 ||
//                                 GameData3?.Team1Score === 0
//                                   ? "0"
//                                   : Number(GameData3?.HomeGoals) ||
//                                     GameData3?.Team1Score}{" "}
//                                 -{" "}
//                                 {Number(GameData3?.AwayGoals) === 0.0 ||
//                                 GameData3?.Team2Score === 0
//                                   ? "0"
//                                   : Number(GameData3?.AwayGoals) ||
//                                     GameData3?.Team2Score}
//                               </Typography>
//                             )}
//                           </div>
//                         </td>

//                         {/* 4th Column */}
//                         <td className={classes}>
//                           <div className="flex flex-col gap-4">
//                             {GameData1 && (
//                               <Typography
//                                 title={GameData1.LeagueName || GameData1.Event}
//                                 className="text-black dark:text-white cursor-pointer"
//                               >
//                                 {GameData1.LeagueName ||
//                                   (GameData1?.Event &&
//                                   GameData1?.Event.length > 18
//                                     ? `${GameData1?.Event.slice(0, 18)}..`
//                                     : GameData1?.Event)}
//                               </Typography>
//                             )}
//                             {GameData2 && (
//                               <Typography
//                                 title={GameData2.LeagueName || GameData2.Event}
//                                 className="text-black dark:text-white cursor-pointer"
//                               >
//                                 {GameData2.LeagueName ||
//                                   (GameData2?.Event &&
//                                   GameData2?.Event.length > 18
//                                     ? `${GameData2?.Event.slice(0, 18)}..`
//                                     : GameData2?.Event)}
//                               </Typography>
//                             )}
//                             {GameData3 && (
//                               <Typography
//                                 title={GameData3.LeagueName || GameData3.Event}
//                                 className="text-black dark:text-white cursor-pointer"
//                               >
//                                 {GameData3.LeagueName ||
//                                   (GameData3?.Event &&
//                                   GameData3?.Event.length > 18
//                                     ? `${GameData3?.Event.slice(0, 18)}..`
//                                     : GameData3?.Event)}
//                               </Typography>
//                             )}
//                           </div>
//                         </td>

//                         {/* 5th Column */}
//                         <td className={classes}>
//                           <div className="flex flex-col gap-4">
//                             {GameData1 && (
//                               <Typography
//                                 className={`${predictionColor(
//                                   GameData1
//                                 )}  font-semibold`}
//                               >
//                                 {name === "soccer"
//                                   ? GameData1.Prediction
//                                   : GameData1[`Team${GameData1?.Prediction}`]}
//                               </Typography>
//                             )}
//                             {GameData2 && (
//                               <Typography
//                                 className={`${predictionColor(
//                                   GameData2
//                                 )}  font-semibold`}
//                               >
//                                 {name === "soccer"
//                                   ? GameData2.Prediction
//                                   : GameData2[`Team${GameData2?.Prediction}`]}
//                               </Typography>
//                             )}
//                             {GameData3 && (
//                               <Typography
//                                 className={`${predictionColor(
//                                   GameData3
//                                 )}  font-semibold`}
//                               >
//                                 {name === "soccer"
//                                   ? GameData3.Prediction
//                                   : GameData3[`Team${GameData3?.Prediction}`]}
//                               </Typography>
//                             )}
//                           </div>
//                         </td>
//                       </tr>
//                     );
//                   })}
//               </>
//             )}
//           </>
//         )}
//       </tbody>
//     </table>
//   );
// };

// export default ComboAccuracyTable;

// export const CustomImage = ({ src, alt, className, ...rest }) => {
//   return (
//     <LazyLoadImage
//       width={20}
//       height={20}
//       src={src}
//       alt={alt}
//       effect="opacity"
//       placeholderSrc={src}
//       className={className}
//       {...rest}
//     />
//   );
// };
