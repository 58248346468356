import React from 'react'
import AdminDashboard from '../../components/admin/AdminDashboard/AdminDashboard'

const AdminDashboardV2 = () => {
  return (
    <AdminDashboard/>
  )
}

export default AdminDashboardV2
