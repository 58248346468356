import { Card, CardBody } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useValorantAllData } from "../../hooks/useValorant";
import formatDate, { convertTo24HourFormat } from "../../utils/formatter";
import { isEligible } from "../../utils/isEligible";
import { currentDate, currentTime } from "../../utils/timeZone";
import Loading from "../shared/Loading/Loading";
import ValorantCard from "./ValorantCard";

const ValorantBottomAllComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [dateFilter, setDateFilter] = useState(formatDate(new Date()));
  const [filter, setFilter] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const { data, isLoading } = useValorantAllData({
    date: dateFilter,
    time: currentTime,
    // filter: filter === "finished" ? "finished" : undefined,
  });

  // Get filter and date from url
  useEffect(() => {
    const urlFilter = params?.get("filter");
    const urlDate = params?.get("date");
    if (urlFilter) {
      setFilter(urlFilter);
    }
    if (urlDate) {
      setDateFilter(urlDate);
    }
  }, []);

  // Setting Filter
  useEffect(() => {
    if (filter === "all") {
      setFilteredData(data?.data);
    } else if (filter === "live") {
      setFilteredData(
        data?.data?.filter(
          (item) =>
            convertTo24HourFormat(item?.fields?.Time) <= currentTime &&
            item?.fields?.Date === currentDate &&
            !item?.fields?.Result
        )
      );
    } else if (filter === "odds") {
      setFilteredData(data?.data?.filter((item) => isEligible(item)));
    } else if (filter === "finished") {
      setFilteredData(data?.data?.filter((item) => item?.fields?.Result));
    } else if (filter === "schedule") {
      setFilteredData(data?.data?.filter((item) => !item?.fields?.Result));
    }
  }, [filter, data]);

  // Setting Date Filter
  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setDateFilter(newDate);
    params.set("date", newDate);
    const newUrl = `${location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleFilterClick = (filterParam) => {
    setFilter(filterParam);
    params.set("filter", filterParam);
    const newUrl = `${location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const navList = [
    {
      name: "All",
      param: "all",
    },
    {
      name: "Live",
      param: "live",
    },
    {
      name: "ODDS",
      param: "odds",
    },
    {
      name: "Finished",
      param: "finished",
    },
    {
      name: "Schedule",
      param: "schedule",
    },
  ];

  return (
    <>
      <Card className="bg-lightPrimary dark:bg-primary h-[calc(100vh-152px)]  md:h-[calc(100vh-393px)]">
        <CardBody className="overflow-y-scroll scroll-hidden text-justify text-gray-200 p-0">
          <div className="flex z-20 md:flex-row flex-col justify-between items-start gap-4 sticky top-0 bg-lightPrimary dark:bg-primary p-4  md:py-6 md:px-6 rounded-xl">
            <div className="overflow-y-scroll scroll-hidden text-justify text-gray-200">
              <div className="flex justify-between items-center ">
                <div className="flex gap-2">
                  {navList.map((item, i) => (
                    <div
                      key={i}
                      className={`text-[10px]  py-1 px-2.5 rounded-lg dark:text-white text-black cursor-pointer ${filter === item.param
                          ? "bg-blue-400"
                          : "bg-lightSecondary dark:bg-secondary"
                        }`}
                      onClick={() => handleFilterClick(item.param)}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={`${filter === "live" ? "hidden" : "flex"}`}>
              <input
                type="date"
                id="date"
                className="dark:bg-secondary bg-lightSecondary text-black dark:text-white px-2.5 text-[10px] py-[3px] rounded-lg"
                onChange={handleDateChange}
                value={dateFilter}
              />
            </div>
          </div>

          {isLoading ? (
            <Loading className="h-40" />
          ) : (
            <div className="md:px-6 px-1 md:pb-6 pb-4">
              {filteredData?.length > 0 ? (
                <div>
                  {filteredData.map((item, i) => (
                    <ValorantCard
                      item={item}
                      key={i}
                      isOdds={filter === "odds"}
                    />
                  ))}
                </div>
              ) : (
                <p className="dark:text-white text-black">
                  No matches are available.
                </p>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default ValorantBottomAllComponent;
