import React from "react";
import { IoIosArrowUp } from "react-icons/io";
import Loading from "../../shared/Loading/Loading";

const DashboardSummary = ({ data, isLoading }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 rounded-lg my-4">
      {/* Accuracy */}
      <div className="rounded-lg bg-white overflow-hidden border-b-[6px] border-primary">
        <div className="p-4">
          <h3 className="text-lg">Accuracy</h3>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="flex items-center justify-start gap-2 mt-2">
              <IoIosArrowUp size={30} className="text-primary" />
              <h3 className="text-3xl font-bold">
                {data?.winParc === "NaN" ? "" : `${data?.winParc}%`}
              </h3>
            </div>
          )}
        </div>
      </div>

      {/* Average Odds */}
      <div className="rounded-lg bg-white overflow-hidden  border-b-[6px] border-green-500">
        <div className="p-4">
          <h3 className="text-lg">Average Odds</h3>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="flex items-center justify-start gap-2 mt-2">
              <IoIosArrowUp size={30} className="text-green-500" />
              <h3 className="text-3xl font-bold">
                {data?.avgOdds === "NaN" ? "" : `${data?.avgOdds}`}
              </h3>
            </div>
          )}
        </div>
      </div>

      {/* True Prediction */}
      <div className="col-span-2 md:col-span-1 rounded-lg bg-white overflow-hidden  border-b-[6px] border-yellow-700">
        <div className="p-4 flex items-center justify-between gap-4">
          <div>
            <h3 className="text-lg">Total/True Predictions</h3>
            {isLoading ? (
              <Loading />
            ) : (
              <div className="flex items-center justify-start gap-2 mt-2">
                <IoIosArrowUp size={30} className="text-yellow-700" />
                <h3 className="text-3xl font-bold">{data?.winMatch}</h3>
              </div>
            )}
          </div>

          <div className="h-16 w-16 rounded-full border-4 border-yellow-700 grid place-items-center font-bold p-2">
            {data?.total}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSummary;
